import { http } from "./http";
import { downloaderFiles } from "../utility";

const baseUrl = "/integration/api/receptionproject";

export const importCriteriaGroup = async ({ subproject, file, email }) => {
  const config = {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  };
  const formData = new FormData();
  formData.append("file_upload", file);
  formData.append("subproject", subproject);
  formData.append("email", email);

  return http
    .post(`${baseUrl}/inventary_importer/`, formData, config)
    .then((response) => response.data)
    .catch(({ response }) => Promise.reject(response.data));
};
export const getEmails = async () => {
  return http.get(`${baseUrl}/emails/`).then((response) => response.data);
};
export const downloadCriteriaGroupTemplate = async (
  subprojectId,
  subprojectName
) => {
  return http
    .get(`${baseUrl}/${subprojectId}/template_inventory/`, {
      responseType: "blob",
    })
    .then((response) => {
      downloaderFiles(response.data, `${subprojectName}.xlsx`);
      return response;
    })
    .catch((error) => error);
};

const service = {
  importCriteriaGroup,
  getEmails,
  downloadCriteriaGroupTemplate,
};

export default service;
