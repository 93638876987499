import * as actionTypes from './actionTypes';
import { updateObject } from '../utility';

const initialState = {
  loading: false,
  siteAlert: null,
  grouperActivities: null,
  grouperSelected: null,
  formSchema: null,
  formSchemaEdit: null,
  modalData: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ALERT_SITES_START:
      return updateObject(state, {
        siteAlert: null
      });

    case actionTypes.FETCH_ALERT_SITES_SUCCESS:
      return updateObject(state, {
        siteAlert: action.data
      });

    case actionTypes.FETCH_ALERT_SITES_FAIL:
      return updateObject(state, {
        error: action.error
      });

    case actionTypes.FETCH_GROUPER_ACTIVITIES_START:
      return updateObject(state, {
        grouperActivities: null,
        loading: true
      });

    case actionTypes.FETCH_GROUPER_ACTIVITIES_SUCCESS:
      return updateObject(state, {
        grouperActivities: action.data,
        loading: false
      });

    case actionTypes.FETCH_GROUPER_ACTIVITIES_FAIL:
      return updateObject(state, {
        error: action.error,
        loading: false
      });

    case actionTypes.FETCH_FORM_SCHEMA_START:
      return updateObject(state, {
        formSchema: null,
        loading: true
      });

    case actionTypes.FETCH_FORM_SCHEMA_SUCCESS:
      return updateObject(state, {
        formSchema: action.data,
        loading: false
      });

    case actionTypes.FETCH_FORM_SCHEMA_FAIL:
      return updateObject(state, {
        formSchema: action.error,
        loading: false
      });

    case actionTypes.GROUPER_SELECTED:
      return updateObject(state, {
        grouperSelected: action.data
      });

    case actionTypes.FETCH_FORM_SCHEMA_EDIT_START:
      return updateObject(state, {
        formSchemaEdit: null
      });

    case actionTypes.FETCH_FORM_SCHEMA_EDIT_SUCCESS:
      return updateObject(state, {
        formSchemaEdit: action.data
      });

    case actionTypes.RESET_FORM_SCHEMA_EDIT:
      return updateObject(state, { formSchemaEdit: null });

    case actionTypes.FETCH_FORM_SCHEMA_EDIT_FAIL:
      return updateObject(state, {
        formSchemaEdit: action.error
      });

    case actionTypes.FETCH_ACTIVITIES_DOCUMENTS_START:
      return updateObject(state, {
        modalData: null
      });

    case actionTypes.FETCH_ACTIVITIES_DOCUMENTS_SUCCESS:
      return updateObject(state, {
        modalData: action.data
      });

    case actionTypes.FETCH_ACTIVITIES_DOCUMENTS_FAIL:
      return updateObject(state, {
        error: action.error
      });

    default:
      return state;
  }
};

export default reducer;
