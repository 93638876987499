import { http } from "./http";

const baseUrlAPI = "/profile/api/rols";

export const getRole = async (queryParam = {}) => {
  return http
    .get(`${baseUrlAPI}/`, { params: queryParam })
    .then((response) => response.data);
};
export const postRole = async (data) => {
  return http
    .post(`${baseUrlAPI}/`, data)
    .then((response) => response)
    .catch((error) => error);
};
export const deleteRole = async (id) => {
  return http.delete(`${baseUrlAPI}/${id}/`).then((response) => response.data);
};
export const getValuesFiltersRole = async (idRol, values) => {
  return http
    .get(`${baseUrlAPI}/${idRol}/filter_values/?slug=${values}`)
    .then((response) => response && response.data)
    .catch(() => {
      console.log("ha habido un error");
    });
};
export const postSaveFilter = async (idRol, data) => {
  return http
    .post(`${baseUrlAPI}/${idRol}/save_filters/`, data)
    .then((response) => response && response.data);
};
export const postCleanFilter = async (idRol, data) => {
  return http
    .post(`${baseUrlAPI}/${idRol}/clean_filters/`, data)
    .then((response) => response && response.data);
};
export const getFieldsFiltersRol = async (rolId) => {
  return http
    .get(`${baseUrlAPI}/${rolId}/filter_fields/`)
    .then((response) => response && response.data);
};
export const getSpecificRole = async (idRol) => {
  return http
    .get(`${baseUrlAPI}/${idRol}/`)
    .then((response) => response.data)
    .catch((error) => error);
};
export const putSpecificRole = async (data, idRole) => {
  return http
    .put(`${baseUrlAPI}/${idRole}/`, data)
    .then((response) => response.data)
    .catch((error) => error);
};
export const downloadTemplateRoleImporter = async (subprojectId) => {
  return http
    .get(`${baseUrlAPI}/${subprojectId}/import_template/`, {
      responseType: "blob",
    })
    .then((response) => response.data);
};
export const getProfiles = async (subprojectId) => {
  return http
    .get(`${baseUrlAPI}/${subprojectId}/profiles_by_subproject/`)
    .then(({ data }) => data);
};
export const roleImporter = async (values) => {
  const formData = new FormData();
  formData.append("subproject", values.subproject);
  formData.append("profile", values.profile);
  formData.append("file_upload", values.file_upload);

  return http
    .post(`${baseUrlAPI}/importer/`, formData, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then(({ data }) => data);
};
export const getUsers = async (subprojectId) => {
  return http
    .get(`/rollout/api/subproject/${subprojectId}/users/`)
    .then(({ data }) => data);
};
export const getUsersEditRol = async (subprojectId) => {
  return http
    .get(`${baseUrlAPI}/${subprojectId}/list_users/`)
    .then(({ data }) => data);
};
export const getUsersAssigned = async (roleId) => {
  return http
    .get(`${baseUrlAPI}/${roleId}/userselected/`)
    .then(({ data }) => data);
};
export const saveUserAssigned = async (roleId, users) => {
  return http
    .post(`${baseUrlAPI}/${roleId}/users/`, { users })
    .then(({ data }) => data);
};
export const getFieldRoles = async (rolId) => {
  return http.get(`${baseUrlAPI}/${rolId}`).then(({ data }) => data);
};
export const updateRoleFields = async (rolId, values) => {
  return http.put(`${baseUrlAPI}/${rolId}/`, values).then((resp) => resp);
};
export const getUserApproval = async (rolID) => {
  return http
    .get(`${baseUrlAPI}/${rolID}/users_approval/`)
    .then((response) => response && response.data)
    .catch((error) => error);
};
export const postUserApproval = async (rolID, data) => {
  return http
    .post(`${baseUrlAPI}/${rolID}/add_users_approval/`, data)
    .then((res) => res)
    .catch((err) => err);
};
export const getApproveUsersProject = async () => {
  return http
    .get(`/profile/api/approve/users/`)
    .then((resp) => resp)
    .catch((resp) => resp);
};
export const getApproveUsersProjectRol = async () => {
  return http
    .get(`/profile/api/approve/500/rol/`)
    .then((resp) => resp)
    .catch((resp) => resp);
};

export const putApproveUsersProject = async (id) => {
  return http
    .put(`/profile/api/approve/${id}/user/`)
    .then((resp) => resp)
    .catch((err) => err);
};

export const rolPermission = async () => {
  return http
    .get(`${baseUrlAPI}/rol_permission/`)
    .then((resp) => resp)
    .catch((err) => err);
};

export const GetRolProjectPermissions = async (idRol) => {
  return http
    .get(`${baseUrlAPI}/${idRol}/permissions_rol_project/`)
    .then((resp) => resp)
    .catch((err) => err);
};

export const EditRolProjectPermissions = async (idRol, data) => {
  return http
    .patch(`${baseUrlAPI}/${idRol}/permissions_rol_project/`, data)
    .then((resp) => resp)
    .catch((err) => err);
};

const listService = {
  getRole,
  postRole,
  deleteRole,
  getValuesFiltersRole,
  postSaveFilter,
  postCleanFilter,
  getFieldsFiltersRol,
  getSpecificRole,
  putSpecificRole,
  downloadTemplateRoleImporter,
  getProfiles,
  roleImporter,
  getUsers,
  getUsersAssigned,
  saveUserAssigned,
  getFieldRoles,
  updateRoleFields,
  getUserApproval,
  postUserApproval,
  getUsersEditRol,
  getApproveUsersProject,
  getApproveUsersProjectRol,
  putApproveUsersProject,
  rolPermission,
  GetRolProjectPermissions,
  EditRolProjectPermissions,
};
export default listService;
