import { BASE_URL, HTTPClientAuth } from '../../utils/httpClientAuth'
import {
  CustomerTeamDTO,
  ID,
  ProjectDTO,
  RoleDTO,
  UserGetAccessDTO,
} from '../types'

import { SSOClientAuth } from '../../utils/SSOClientAuth'

async function getAccess(values: UserGetAccessDTO) {
  try {
    const { data } = await HTTPClientAuth.post(
      '/profile/dummy/get/access/',
      values
    )
    return data
  } catch ({ response }) {
    return await Promise.reject((response as any)?.data)
  }
}

async function getProjects(): Promise<ProjectDTO[]> {
  const { data } = await HTTPClientAuth.get(
    '/rollout/api/subproject/get_access/'
  )
  return data
}

async function getRolesByProject(id: ID): Promise<RoleDTO[]> {
  const { data } = await HTTPClientAuth.get(`/profile/api/rols/${id}/access_/`)
  return data
}

async function getCustomerTeamsByProject(id: ID): Promise<CustomerTeamDTO[]> {
  const { data } = await HTTPClientAuth.get(
    `/rollout/api/subproject/${id}/customer_team/`
  )
  return data
}

async function refreshToken() {
  const resp = await HTTPClientAuth.post('/api/refresh_token/', {
    refresh_token: localStorage.getItem('_rf_'),
    token: localStorage.getItem('_tk_'),
  })
  return resp.data
}

async function login(username: string, password: string) {
  const resp = await HTTPClientAuth.post('/api/login_token/', {
    username,
    password,
  })
  return resp.data
}

function validateToken() {
  return HTTPClientAuth.post('/token/validate/', {})
}

function getToken() {
  return SSOClientAuth.post('/auth/token2/')
}

function redirectOIDC() {
  window.location.href = `${BASE_URL}/oidc/authenticate/`
}

function redirectOIDCLogout() {
  window.location.href = `${BASE_URL}/auth/logout/`
}

export {
  getAccess,
  getProjects,
  getRolesByProject,
  getCustomerTeamsByProject,
  refreshToken,
  login,
  validateToken,
  getToken,
  redirectOIDC,
  redirectOIDCLogout,
}
