export const FETCH_GAP_ANALYSIS_START =
  '[melius][gap analysis] Get gap analysis start';

export const FETCH_GAP_ANALYSIS_SUCCESS =
  '[melius][gap analysis] Get gap analysis success';

export const FETCH_GAP_ANALYSIS_FAIL =
  '[melius][gap analysis] Get gap analysis fail';

export const GAP_ANALYSIS_DETAILS_START =
  '[melius][gap analysis] Gap analysis detail selected start';

export const GAP_ANALYSIS_DETAILS_FAIL =
  '[melius][gap analysis] Gap analysis detail selected fail';

export const GAP_ANALYSIS_DETAILS_SUCCESS =
  '[melius][gap analysis] Gap analysis detail selected success';

export const UPDATED_CARD_GAP_REASON_INFORMATION =
  '[melius][gap analysis] Update card gap reason';
