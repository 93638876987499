import { http } from "./http";

export const getAll = async (page = 1, filters = {}) => {
  return await new Promise((resolve, reject) => {
    const date_filters = [
      "date_gte",
      "date_lte",
      "creation_date_gte",
      "creation_date_lte",
    ];
    let request_filters = { ...filters };
    Object.keys(request_filters).forEach((element) => {
      if (date_filters.indexOf(element) !== -1) {
        if (request_filters[element]) {
          request_filters[element] = filters[element].format("DD/MM/YYYY");
        }
      }
    });

    http
      .get("/cpo/", { params: { page: page, ...request_filters } })
      .then((response) => {
        resolve({
          list: response.data.results.map((order) => ({
            ...order,
            ...order.extra_fields,
          })),
          pagination: {
            actual: response.data.actual,
            count: response.data.count,
            next: response.data.next,
            pages: response.data.pages,
            previous: response.data.previous,
          },
        });
      })
      .catch((error) => reject(error));
  });
};
export const getItems = async (cpoID) => {
  return await http
    .get(`/cpo/cpo-list/${cpoID}/`)
    .then((response) => response.data.cpo);
};
export const getHistory = async (cpoID) => {
  return await http
    .get(`/cpo/cpo-list/${cpoID}/history/`)
    .then((response) => response.data.cpos);
};
export const sendReport = async (reportData) => {
  return await http
    .post("/cpo/cpo-report/", { query_kwargs: reportData })
    .then((result) => {
      return result;
    });
};
export const getCpoItem = async (cpoID, itemID) => {
  return await http
    .get(`/cpo/cpo-list/${cpoID}/items/${itemID}/`)
    .then((response) => response.data.cpo_item);
};
export const cancelCpoItem = async (cpoID, itemID) => {
  return await http
    .post(`/cpo/cpo-list/${cpoID}/items/${itemID}/cancel/`)
    .then((response) => response.data);
};
export const getCpoItemComments = async (cpoID, itemID) => {
  return await http
    .get(`/cpo/cpo-list/${cpoID}/items/${itemID}/comments/`)
    .then((response) => response.data.comments);
};
export const newCommet = async (cpoID, itemID, username, message) => {
  return await http
    .post(`/cpo/cpo-list/${cpoID}/items/${itemID}/comments/`, {
      username,
      message,
    })
    .then((response) => response.data.comment);
};
export const download = async (cpoID) => {
  return http
    .get(`/cpo/cpo-list/${cpoID}/download/`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
    });
};
export const reParseCpoFile = async (parse_file_ids) => {
  return http.post(`/cpo/re-parse-cpo-file/`, { parse_file_ids });
};
export const cancelCpo = async (cpoID, cpo_number) => {
  return http
    .put(`/india/register/${cpoID}/`, {
      status: "cancelled",
      is_history_log: false,
      cpo_id: cpoID,
      cpo_number,
    })
    .then((response) => response.data.cpo);
};
export const nonOetRelatedCpo = async (cpoID, cpo_number) => {
  return http
    .put(`/india/register/${cpoID}/`, {
      status: "non_oet_related",
      cpo_id: cpoID,
      cpo_number,
    })
    .then((response) => response.data);
};
export const unCancelCpo = async (cpoID) => {
  return http
    .put(`/cpo/cpo-list/${cpoID}/cancel/`)
    .then((response) => response.data.cpo);
};
export const uploadCPOFile = async (file) => {
  const formData = new FormData();
  formData.append("uploaded_file", file);
  formData.append("customer_team", 82); // Fixed for now, will change when user can have more than one CT or We will working other than India
  return http
    .post(`/cpo/cpo-upload/`, formData, {
      headers: { "content-type": "multipart/form-data" },
    })
    .then((response) => response.data);
};
export const updateCPO = async (cpo_id, circle, project) => {
  return http
    .patch(`/india/register/${cpo_id}/`, {
      ...(circle ? { circle } : {}),
      ...(project ? { project } : {}),
    })
    .then((response) => response.data);
};

const service = {
  getAll,
  getItems,
  getHistory,
  sendReport,
  getCpoItem,
  cancelCpoItem,
  getCpoItemComments,
  newCommet,
  download,
  reParseCpoFile,
  cancelCpo,
  nonOetRelatedCpo,
  unCancelCpo,
  uploadCPOFile,
  updateCPO,
};
export default service;
