import * as actionTypes from './actionTypes';
import { updateObject } from '../utility';

const initialState = {
  listUser: null,
  loading: false,
  permissions: null,
  specificUser: null,
  subprojectsAssigned: null,
  rolesAssignedToSubproject: null,
  permissionsBySubproject: null,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_MANAGEMENT_START:
      return updateObject(state, {
        loading: true,
        listUser: null
      });

    case actionTypes.FETCH_USER_MANAGEMENT_SUCCESS:
      return updateObject(state, {
        loading: false,
        listUser: action.data
      });

    case actionTypes.FETCH_USER_MANAGEMENT_FAIL:
      return updateObject(state, {
        loading: false,
        error: action.error
      });

    case actionTypes.FETCH_LIST_PERMISSIONS_START:
      return updateObject(state, {
        permissions: null
      });

    case actionTypes.FETCH_LIST_PERMISSIONS_SUCCESS:
      return updateObject(state, {
        permissions: action.data
      });

    case actionTypes.FETCH_LIST_PERMISSIONS_FAIL:
      return updateObject(state, {
        error: action.error
      });

    case actionTypes.FETCH_SPECIFIC_USER_START:
      return updateObject(state, {
        specificUser: null,
        loading: true
      });

    case actionTypes.FETCH_SPECIFIC_USER_SUCCESS:
      return updateObject(state, {
        specificUser: action.data,
        loading: false
      });

    case actionTypes.FETCH_SPECIFIC_USER_FAIL:
      return updateObject(state, {
        error: action.error,
        loading: false
      });

    // Subproject assigned to user
    case actionTypes.FETCH_SUBPROJECTS_ASSIGN_START:
      return updateObject(state, action.payload);

    case actionTypes.FETCH_SUBPROJECTS_ASSIGN_SUCCESS:
      return updateObject(state, action.payload);

    case actionTypes.FETCH_SUBPROJECTS_ASSIGN_FAIL:
      return updateObject(state, action.payload);

    // Roles assigned to subproject
    case actionTypes.FETCH_ROL_ASSIGNED_TO_PROFILES_USER_START:
      return updateObject(state, action.payload);

    case actionTypes.FETCH_ROL_ASSIGNED_TO_PROFILES_USER_SUCCESS:
      return updateObject(state, action.payload);

    case actionTypes.FETCH_ROL_ASSIGNED_TO_PROFILES_USER_FAIL:
      return updateObject(state, action.payload);

    // Permissions by subproject
    case actionTypes.FETCH_PERMISSIONS_BY_SUBPROJECT_START:
      return updateObject(state, action.payload);

    case actionTypes.FETCH_PERMISSIONS_BY_SUBPROJECT_SUCCESS:
      return updateObject(state, action.payload);

    case actionTypes.FETCH_PERMISSIONS_BY_SUBPROJECT_FAIL:
      return updateObject(state, action.payload);

    default:
      return state;
  }
};

export default reducer;
