import React from 'react';
import { Base } from 'zina-dogi-react';

export default function PowerBI({ baseOptions, url }) {
  return (
    <Base {...baseOptions}>
      <div style={{ height: '100vh' }}>
        <iframe
          id="power-bi"
          title="Power BI Frame"
          height="100%"
          width="100%"
          src={url}
          style={{ border: 'none' }}
        />
      </div>
    </Base>
  );
}
