import { http } from "./http";
import swal from "sweetalert2";

const baseUrlAPI = "/rollout/api/site_form";

export const getAlertSite = async (idSite, subproject) => {
  return http
    .get(`/rollout/api/sitealert/${idSite}/`, { params: { subproject } })
    .then((response) => response.data);
};
export const getGrouperActivities = async (idSubproject) => {
  return http
    .get(`${baseUrlAPI}/${idSubproject}/tabs/`)
    .then((response) => response.data);
};
export const getFormSchema = async (idSubproject) => {
  return http
    .get(`${baseUrlAPI}/${idSubproject}/form_schema/`)
    .then((response) => response.data);
};
export const postCreateSite = async (data) => {
  return http
    .post(`${baseUrlAPI}/`, {
      ...data,
    })
    .then((response) => response.data);
};
export const getLogFields = async (siteId, data) => {
  return http
    .post(`/rollout/api/log_data/${siteId}/slug_detail/`, data)
    .then(({ data }) => data);
};
export const getFormSchemaEdit = async (idSubproject, idSite) => {
  return http
    .get(`${baseUrlAPI}/${idSubproject}/form_schema/?site_id=${idSite}`)
    .then((response) => response.data)
    .catch((error) => error);
};
export const putCreateSite = async (idSite, data) => {
  return http
    .put(`${baseUrlAPI}/${idSite}/`, {
      ...data,
    })
    .then((response) => response.data);
};
export const getActivitiesDocuments = async (idActivity) => {
  return http
    .get(`/rollout/api/activities/${idActivity}/`)
    .then((response) => response.data)
    .catch((error) => error);
};
export const getFileActivitiesDocuments = async (linkDocument) => {
  return http
    .get(`${linkDocument}`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${linkDocument}`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      swal.fire({
        type: "error",
        title: "Oops...",
        text: "File not available.",
      });
    });
};
export const postDocumentSite = async (report_file, document_config, site) => {
  const formData = new FormData();
  formData.append("report_file", report_file);
  formData.append("document_config", document_config);
  formData.append("site", site);
  return await http
    .post(`rollout/api/document_site/`, formData, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((response) => {
      return response.data;
    });
};
export const putDocumentSite = async (
  report_file,
  document_config,
  site,
  id
) => {
  const formData = new FormData();
  formData.append("report_file", report_file);
  formData.append("document_config", document_config);
  formData.append("site", site);
  return await http
    .put(`rollout/api/document_site/${id}/`, formData, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((response) => {
      return response.data;
    });
};

const service = {
  getAlertSite,
  getGrouperActivities,
  getFormSchema,
  postCreateSite,
  getLogFields,
  getFormSchemaEdit,
  putCreateSite,
  getActivitiesDocuments,
  getFileActivitiesDocuments,
  postDocumentSite,
  putDocumentSite,
};
export default service;
