export function BasicInformationPopover() {
  return (
    <>
      <div className='mb-2'>
        Please type you details such as email address, full name and set a
        password.
      </div>
      <div>
        <p>
          <span className='fw-bold'>Important:</span> If your Email is
          @nokia.com, you won't need to set a password since you will use your
          regular Nokia-intra access.
        </p>
      </div>
    </>
  )
}

export function ProjectDefinitionPopover() {
  return (
    <>
      <div className='mb-2'>
        Your Line Manager approval is required to get into ZINA BI as well as
        some details you need to complete:
      </div>
      <div>
        <p>
          <span className='fw-bold'>Line Manager Email:</span> Your LM's email
          address.
        </p>
        <p>
          <span className='fw-bold'>Market Unit:</span> The Market Unit you work
          for.
        </p>
        <p>
          <span className='fw-bold'>Role:</span> Your role.
        </p>
        <p>
          <span className='fw-bold'>Customer Team:</span> The customer team you
          work for.
        </p>
        <p>
          <span className='fw-bold'>Project:</span> The project inside ZINA BI
          you need access.
        </p>
        <p>
          <span className='fw-bold'>Comments:</span> A message to your LM and
          ZINA.
        </p>
      </div>
    </>
  )
}
