import { http } from "./http";
import { downloaderFiles } from "../utility";

const baseUrl = "/integration/api/proyectsync";
export const importInventoryTemplateSyncSMR = async ({ subproject, file }) => {
  const config = {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  };
  const formData = new FormData();
  formData.append("file_upload", file);
  formData.append("subproject", subproject);

  return http
    .post(`${baseUrl}/importer/`, formData, config)
    .then((response) => response.data)
    .catch(({ response }) => Promise.reject(response.data.errors));
};
export const downloadInventoryTemplate = async (
  subprojectId,
  subprojectName
) => {
  return http
    .get(`${baseUrl}/${subprojectId}/exporter/`, {
      responseType: "blob",
    })
    .then((response) => {
      downloaderFiles(response.data, `${subprojectName}.xlsx`);
      return response;
    })
    .catch((error) => error);
};
export const syncSMR = async (values) => {
  return http
    .post(`${baseUrl}/smr/`, values)
    .then((response) => response.data)
    .catch(({ response: { data } }) => Promise.reject(data));
};
const service = {
  importInventoryTemplateSyncSMR,
  downloadInventoryTemplate,
  syncSMR,
};
export default service;
