import { authTypes } from './types';
import service from '../../services/auth.service';
import swal from 'sweetalert';

const login = (user, pass) => {
  const username = user.trim();
  return (dispatch) => {
    service
      .login(username, pass)
      .then((result) => {
        const { token, refresh_token } = result.data;
        dispatch(newToken({ access_token: token, refresh: refresh_token }));
        delete result.data.token;
        delete result.data.refresh_token;
        dispatch(loginSuccess(result.data));
      })
      .catch((err) => dispatch(loginFail(err)));
  };
};

const refresh = () => {
  return (dispatch) => {
    service
      .tokenRefresh()
      .then((result) => dispatch(newToken(result.data)))
      .catch(() => dispatch(logOut()));
  };
};

const token = () => {
  return (dispatch) => {
    dispatch({ type: authTypes.LOGIN });
    return service
      .token()
      .then((result) => {
        if (result && result.data) {
          const { access_token, refresh } = result.data;
          dispatch(newToken({ access_token, refresh }));
          delete result.data.access_token;
          delete result.data.refresh;
          dispatch(loginSuccess(result.data.user));
        }
        return result;
      })
      .catch((error) => {
        return dispatch(loginFail(error));
      });
  };
};

const refresh_token = () => {
  return (dispatch) => {
    service
      .token_refresh()
      .then((result) =>
        dispatch(newToken({ access_token: result.data.access })),
      )
      .catch(() => dispatch(logOut()));
  };
};

const newToken = (payload) => ({
  type: authTypes.NEW_TOKEN,
  payload,
});

const loginSuccess = (response) => ({
  type: authTypes.LOGIN_SUCCESS,
  payload: response,
});

const refreshData = (response) => ({
  type: authTypes.LOGIN_REFRESH_DATA,
  payload: response,
});

const loginFail = (payload) => ({
  type: authTypes.LOGIN_FAIL,
  payload,
});

const logOut = () => ({
  type: authTypes.LOGOUT,
});

const getAccessStart = () => {
  return {
    type: authTypes.GET_ACCESS_START,
  };
};

const getAccessFailed = (error) => {
  return {
    type: authTypes.GET_ACCESS_FAIL,
    error,
  };
};

const getAccessSuccess = (data) => {
  return {
    type: authTypes.GET_ACCESS_SUCCESS,
    data,
  };
};

const getAccess = (data) => {
  return (dispatch) => {
    dispatch(getAccessStart());
    service
      .getAccess(data)
      .then((response) => {
        swal(
          ` Welcome ${response.data.username}!`,
          `Your acount was successfuly create.`,
          'success',
        );
        dispatch(getAccessSuccess(response));
      })
      .catch((error) => dispatch(getAccessFailed(error)));
  };
};

const getProjectsStart = () => {
  return {
    type: authTypes.GET_PROJECT_LIST_OPTIONS_START,
  };
};

const getProjectsFail = (error) => {
  return {
    type: authTypes.GET_PROJECT_LIST_OPTIONS_FAIL,
    error,
  };
};

const getProjectsSuccess = (data) => {
  return {
    type: authTypes.GET_PROJECT_LIST_OPTIONS_SUCCESS,
    data,
  };
};

const fetchProjectListOptions = () => {
  return (dispatch) => {
    dispatch(getProjectsStart());
    service
      .getProjectList()
      .then((response) => dispatch(getProjectsSuccess(response)))
      .catch((error) => dispatch(getProjectsFail(error)));
  };
};

export const authActions = {
  token,
  refresh_token,
  login,
  logOut,
  refresh,
  getAccess,
  fetchProjectListOptions,
  refreshData,
};
