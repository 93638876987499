import Swal from 'sweetalert2'
import axios from 'axios'

export const BASE_URL = process.env.REACT_APP_API_ENDPOINT_URL

export const HTTPClientAuth = axios.create({
  baseURL: BASE_URL,
})

HTTPClientAuth.interceptors.request.use((config) => {
  if (config.url !== '/api/login_token/') {
    const token = localStorage.getItem('_tk_')
    if (token) {
      config.data = { ...config.data, token }
    }
  }
  return config
})

HTTPClientAuth.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status, data } = error.response
    if (status === 500) {
      Swal.fire({
        icon: 'error',
        title: `Oops!`,
        text: data.message,
        showConfirmButton: true,
        timer: 4000,
      })
    }
    return Promise.reject(error)
  }
)
