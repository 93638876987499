import {
  fetchGapAnalysis,
  fetchGapDetail,
  updateCardGadReasons
} from './actions';

export const gapAnalysisActions = {
  fetchGapAnalysis,
  fetchGapDetail,
  updateCardGadReasons
};
