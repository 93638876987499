import { Popover, PopoverBody } from 'reactstrap'
import { ReactChild, ReactChildren, ReactNode, useState } from 'react'

import { FaQuestionCircle } from 'react-icons/fa'

type SectionTitleProps = {
  title: string
  className?: string
  popoverID: string
  renderPopoverBody: ReactChild | ReactChildren | ReactNode
  popoverProps?: { [key: string]: any }
}
export function SectionTitle({
  title,
  popoverID,
  renderPopoverBody,
  popoverProps,
  className = 'pt-3 pb-2',
}: SectionTitleProps) {
  const [popoverOpen, setPopoverOpen] = useState(false)

  const toggle = (val?: boolean) => setPopoverOpen(val || !popoverOpen)

  const onHover = () => toggle(true)

  const onMouseLeave = () => toggle(false)

  return (
    <h5 className={`fw-bold text-dark ${className}`}>
      {title}{' '}
      <span
        id={popoverID}
        role='button'
        onMouseEnter={onHover}
        onMouseLeave={onMouseLeave}
      >
        <FaQuestionCircle className='fs-6 text-primary' />
      </span>
      <Popover
        toggle={toggle}
        isOpen={popoverOpen}
        placement='bottom'
        target={popoverID}
        {...popoverProps}
      >
        <PopoverBody>{renderPopoverBody}</PopoverBody>
      </Popover>
    </h5>
  )
}
