import { Container } from './styles';
import { FaSignInAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
// import { LoginForm } from './LoginForm'
import LoginForm from './LoginForm';
import { LoginOptionProps } from '../../types';
import { useState } from 'react';

function LoginOptions({ onChangeLoginOption }: LoginOptionProps) {
  return (
    <>
      <h3 className='nokia-title mb-3'>Nokia staff:</h3>
      <p className='mb-4'>
        If you have a @nokia.com, @nokia-bell-labs.com or other Nokia business
        email address:
      </p>
      <div className='text-center'>
        <Link to='/login-oidc/' className='btn btn-form fw-bold mb-4'>
          <FaSignInAlt className='login-icon' /> Log in using your Nokia
          credentials
        </Link>
      </div>
      <h3 className='nokia-title pb-2 mb-2'>External users:</h3>
      <h5 className='nokia-subtitle'>
        Suppliers and other external users, sign in here:
      </h5>
      <p className='nokia-text mb-4'>
        <span className='fw-bold'>New accounts:</span> To obtain a login to this
        application, please ask your Nokia contact to request an account for you
        within this site.
      </p>
      <div className='text-center'>
        <button
          type='button'
          className='btn btn-form btn-external-login fw-bold mb-4'
          onClick={onChangeLoginOption}
        >
          <FaSignInAlt className='login-icon' /> External users log in
        </button>
      </div>
    </>
  );
}
export function Login() {
  const [isExternalUser, setIsExternalUser] = useState(false);

  const onToggle = () => setIsExternalUser((s) => !s);

  return (
    <Container className='py-3'>
      {isExternalUser ? (
        <LoginForm onChangeLoginOption={onToggle} />
      ) : (
        <LoginOptions onChangeLoginOption={onToggle} />
      )}
    </Container>
  );
}
