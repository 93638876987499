import * as actionTypes from "./actionTypes";
import { userProfileService } from "../../../shared";

const fetchInfoUserProfileStart = () => ({
  type: actionTypes.FETCH_INFO_USER_PROFILE_START,
  payload: { loading: true },
});

const fetchInfoUserProfileSuccess = (data) => ({
  type: actionTypes.FETCH_INFO_USER_PROFILE_SUCCESS,
  payload: {
    data,
    loading: false,
  },
});

const fetchInfoUserProfileFailed = (error) => ({
  type: actionTypes.FETCH_INFO_USER_PROFILE_FAILED,
  payload: {
    error,
    loading: false,
  },
});

export const fetchInfoUserProfile = (userId) => {
  return (dispatch) => {
    dispatch(fetchInfoUserProfileStart());
    userProfileService
      .getInfoUserProfile(userId)
      .then((resp) => {
        const newDataUser = resp?.user;
        const userLocal = JSON.parse(localStorage.getItem("user"));
        userLocal.email = newDataUser?.email
          ? newDataUser?.email
          : userLocal.email;
        userLocal.first_name = newDataUser?.first_name
          ? newDataUser?.first_name
          : userLocal.first_name;
        userLocal.last_name = newDataUser?.last_name
          ? newDataUser?.last_name
          : userLocal.last_name;
        userLocal.username = newDataUser?.username
          ? newDataUser?.username
          : userLocal.username;
        userLocal.is_active = newDataUser?.is_active
          ? newDataUser?.is_active
          : userLocal.is_active;
        localStorage.setItem("user", JSON.stringify(userLocal));
        
        dispatch(fetchInfoUserProfileSuccess(resp));
      })
      .catch((error) => dispatch(fetchInfoUserProfileFailed(error)));
  };
};
