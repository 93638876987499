import React from "react";
import { Spinner } from "reactstrap";

const BigLoading = () => {
  return (
    <div
      style={{
        backgroundColor: "var(--zina-1)",
        height: "100vh",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        color: "white",
      }}
    >
      <div className="text-center">
        <h1>Loading <Spinner type="grow" color="primary" /></h1>
      </div>
    </div>
  );
};

export default BigLoading;
