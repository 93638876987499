import { http } from "./http";
import { configRequestDefault, configRequestDetails } from "../utility";

const baseUrlAPI = "/api/dashboard";

export const getSubprojects = async () => {
  return http.get(`/rollout/api/subproject/`).then((response) => response && response.data);
};
export const getActivities = async (subprojectId, queryParams = {}) => {
  return http
    .get(`${baseUrlAPI}/${subprojectId}/`, configRequestDefault(queryParams))
    .then((response) => response.data);
};
export const getDetailActivity = async (
  subprojectId,
  grouper,
  queryParams = {}
) => {
  return http
    .get(
      `${baseUrlAPI}/${subprojectId}/`,
      configRequestDetails(grouper, queryParams)
    )
    .then((response) => response.data);
};
export const getProcessWeek = async (subprojectId, queryParams = {}) => {
  return http
    .get(`${baseUrlAPI}/${subprojectId}/process_week_graph/`, {
      params: queryParams,
    })
    .then((response) => response.data);
};
export const getSubProcessWeek = async (subprojectId, queryParams = {}) => {
  return http
    .get(`${baseUrlAPI}/${subprojectId}/subprocess_week_graph/`, {
      params: queryParams,
    })
    .then((response) => response.data);
};
export const getConfigurationProcessWeek = async (subprojectId) => {
  return http
    .get(`${baseUrlAPI}/${subprojectId}/config_process_week/`)
    .then((response) => response.data);
};
export const getFiltersDashboard = async (subprojectId) => {
  return http
    .get(`${baseUrlAPI}/${subprojectId}/process_filters/`)
    .then((response) => response.data);
};
export const getSchemaProjectSite = async (subprojectId, queryParams) => {
  return http
    .get(`${baseUrlAPI}/${subprojectId}/schema_table/`, {
      params: queryParams,
    })
    .then((response) => response.data);
};
export const getDataProjectSite = async (subprojectId, queryParams) => {
  return http
    .get(`${baseUrlAPI}/${subprojectId}/load_data/`, { params: queryParams })
    .then((response) => response.data);
};
export const postSaveProcessFilters = async (subproject_id, filter) => {
  return http
    .post(`${baseUrlAPI}/${subproject_id}/process_filter_save/`, {
      filter,
    })
    .then((response) => response.data);
};
export const getSubProcessFilters = async (id_group) => {
  return http
    .get(`${baseUrlAPI}/${id_group}/subprocess_filters/`)
    .then((response) => response.data)
    .catch((error) => error);
};
export const postSaveSubProcessFilterSave = async (idGrouper, filter) => {
  return http
    .post(`${baseUrlAPI}/${idGrouper}/subprocess_filter_save/`, {
      filter,
    })
    .then((response) => response.data);
};

const service = {
  getSubprojects,
  getActivities,
  getDetailActivity,
  getProcessWeek,
  getSubProcessWeek,
  getConfigurationProcessWeek,
  getFiltersDashboard,
  getSchemaProjectSite,
  getDataProjectSite,
  postSaveProcessFilters,
  getSubProcessFilters,
  postSaveSubProcessFilterSave,
};

export default service;
