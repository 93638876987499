import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_ENDPOINT_URL

export const SSOClientAuth = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
})

SSOClientAuth.interceptors.request.use((config) => {
  if (config.url !== '/api/login_token/') {
    const token = localStorage.getItem('_tk_')
    if (token) {
      config.data = { ...config.data, token }
    }
  }
  return config
})
