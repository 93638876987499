import { FAQData } from './data';
import { FAQItem } from './FAQItem';
import { nanoid } from 'nanoid';

export function FAQ() {
  return (
    <section id='faq' className='faq'>
      <div className='container' data-aos='fade-up'>
        <header className='section-header'>
          <h2>F.A.Q</h2>
          <p>Frequently Asked Questions</p>
        </header>
        <div className='row'>
          {FAQData.map((item) =>
            item.data.map((fqa) => (
              <FAQItem id={item.id} key={nanoid()} {...fqa} />
            )),
          )}
        </div>
      </div>
    </section>
  );
}
