import { http } from './http';


export const getVendors = async (page = 1, filters = {}) => {
  const request_filters = { ...filters, page };
  return http
    .get('/suppliers/company/', { params: request_filters })
    .then(response => ({
      list: response.data.results,
      pagination: {
        actual: response.data.actual,
        count: response.data.count,
        next: response.data.next,
        pages: response.data.pages,
        previous: response.data.previous
      }
    }));
}

const service = {
  getVendors
};

export default service; 
