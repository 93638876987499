import { http } from "./http";

export const getFieldsFilters = async (subproject_id, userId = "") => {
  return http
    .get(
      `/rollout/api/subproject/${subproject_id}/filter_fields/${
        userId ? "?user=" + userId : ""
      }`
    )
    .then((response) => response && response.data);
};
export const getValuesFilters = async (
  subproject_id,
  values,
  label = false,
  user = ""
) => {
  if (!label) {
    return http
      .get(
        `/rollout/api/subproject/${subproject_id}/filter_values/?slug=${values}${
          user ? `&user=${user}` : ""
        }`
      )
      .then((response) => response && response.data);
  } else {
    return http
      .get(
        `/rollout/api/subproject/${subproject_id}/filter_values/?slug=${values}${
          user ? `&user=${user}` : ""
        }`
      )
      .then((response) => {
        return { [values]: response && response.data };
      })
      .catch(() => {
        console.log("ha habido un error");
      });
  }
};
export const postExportValue = async (
  subproject_id,
  filters,
  custom_report,
  export_type,
  _from,
  to,
  format_export = false
) => {
  return http
    .post(
      "/rollout/api/subexport/export/",
      format_export
        ? {
            subproject_id,
            filters,
            custom_report,
            export_type,
            _from,
            to,
            format_export,
          }
        : {
            subproject_id,
            filters,
            custom_report,
            export_type,
            _from,
            to,
          }
    )
    .then((response) => response && response.data);
};
export const getFieldsExportReport = async (subprojectId) => {
  return http
    .get(`/rollout/api/subexport/${subprojectId}/get_fields/`)
    .then((response) => response && response.data);
};
export const getPeriods = async (subproject) => {
  return http
    .get(`/rollout/api/period/`, { params: { subproject } })
    .then((response) => response && response.data);
};
export const getDateOfSourceUpdate = async (subproject, timeZoneS) => {
  return http
    .get(
      `/rollout/api/subproject/${subproject}/sources/${
        timeZoneS ? "?time_zone=" + timeZoneS : ""
      }`
    )
    .then((response) => response && response.data);
};

export const getScheduleTransaction = async (subprojectId) => {
  return http
    .get(
      `/rollout/api/transaction_source_frequency/?subproject=${subprojectId}`
    )
    .then((response) => response)
    .catch((error) => error);
};

const service = {
  getFieldsFilters,
  getValuesFilters,
  postExportValue,
  getFieldsExportReport,
  getPeriods,
  getDateOfSourceUpdate,
  getScheduleTransaction,
};

export default service;
