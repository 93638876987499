import { http } from './http';

const baseUrlAPI = '/profile/api';

export const getActions = async () => {
  return http
    .get(`${baseUrlAPI}/user_report/action_report/`)
    .then(({ data }) => data);
}
export const getUserReports = async values => {
  return http
    .post(`${baseUrlAPI}/user_report/tracking/`, values)
    .then(({ data }) => data);
}
export const generateUserReport = async values => {
  return http
    .post('/profile/api/user_report/export/', values)
    .then(({ data }) => data);
}
 
const service = {
  getActions,
  getUserReports,
  generateUserReport
};

export default service;
