export const FETCH_USER_MANAGEMENT_START =
  '[melius][user management] Get user management start';
export const FETCH_USER_MANAGEMENT_SUCCESS =
  '[melius][user management] Get user management success';
export const FETCH_USER_MANAGEMENT_FAIL =
  '[melius][user management] Get user management fail';

// Subproject assigned
export const FETCH_SUBPROJECTS_ASSIGN_START =
  '[melius][Get subprojects assigned start]';
export const FETCH_SUBPROJECTS_ASSIGN_SUCCESS =
  '[melius][Get subprojects assigned success]';
export const FETCH_SUBPROJECTS_ASSIGN_FAIL =
  '[melius][Get subprojects assigned fail]';

// User roles
export const FETCH_ROL_ASSIGNED_TO_PROFILES_USER_START =
  '[melius][Get roles assigned to user start]';
export const FETCH_ROL_ASSIGNED_TO_PROFILES_USER_SUCCESS =
  '[melius][Get roles assigned to user success]';
export const FETCH_ROL_ASSIGNED_TO_PROFILES_USER_FAIL =
  '[melius][Get roles assigned to user fail]';

// user subprojects
export const FETCH_USER_SUBPROJECTS_START =
  '[melius][Get user subprojects start]';
export const FETCH_USER_SUBPROJECTS_SUCCESS =
  '[melius][Get user subprojects success]';
export const FETCH_USER_SUBPROJECTS_FAIL =
  '[melius][Get user subprojects fail]';

// Permission
export const FETCH_LIST_PERMISSIONS_START =
  '[melius][users management] Get users permissions list start';
export const FETCH_LIST_PERMISSIONS_SUCCESS =
  '[melius][users management] Get users permissions list success';
export const FETCH_LIST_PERMISSIONS_FAIL =
  '[melius][users management] Get users permissions list fail';

// Permissions by subprojects
export const FETCH_PERMISSIONS_BY_SUBPROJECT_START =
  '[melius][Get permissions by subprojects start]';
export const FETCH_PERMISSIONS_BY_SUBPROJECT_SUCCESS =
  '[melius][Get permissions by subprojects success]';
export const FETCH_PERMISSIONS_BY_SUBPROJECT_FAIL =
  '[melius][Get permissions by subprojects fail]';

//Specific user
export const FETCH_SPECIFIC_USER_START =
  '[melius][user management] Get specific user start';
export const FETCH_SPECIFIC_USER_SUCCESS =
  '[melius][user management] Get specific user success';
export const FETCH_SPECIFIC_USER_FAIL =
  '[melius][user management] Get specific user fail';
