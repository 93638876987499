/* eslint-disable import/no-anonymous-default-export */
import { authttp, zero } from "./http";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT_URL;

export const redirect_oidc = () => {
  window.location = `${BASE_URL}/oidc/authenticate/`;
};

export const redirect_oidc_logout = () => {
  window.location = `${BASE_URL}/auth/logout/`;
};

export default {
  redirect_oidc,
  redirect_oidc_logout,
  login: async (username, password) => {
    console.log(
      `%c Login `,
      "background: #ffff00; color: #000; padding: 8px; border-radius: 4px;"
    );
    return authttp.post("/api/login_token/", { username, password });
  },
  getAccess: async (value) => {
    return authttp
      .post("/profile/dummy/get/access/", value)
      .then(({ data }) => data)
      .catch(({ response }) => Promise.reject(response.data));
  },

  getSubProjectGetAccess: async () => {
    return authttp
      .get(`/rollout/api/subproject/get_access/`)
      .then((response) => response.data);
  },
  getRolListGetAccess: async (id) => {
    return authttp
      .get(`/profile/api/rols/${id}/access_/`)
      .then((response) => response.data);
  },
  getCustomerTeamGetAccess: async (id) => {
    return authttp
      .get(`/rollout/api/subproject/${id}/customer_team/`)
      .then((response) => response.data);
  },
  getProjectList: async () => {
    return authttp
      .get("/rollout/api/lading/")
      .then((response) => response.data);
  },
  tokenRefresh: async () => {
    console.log(
      `%c Refresh `,
      "background: #ffff00; color: #000; padding: 8px; border-radius: 4px;"
    );
    return authttp.post("/api/refresh_token/", {
      refresh_token: localStorage.getItem("_rf_"),
      token: localStorage.getItem("_tk_"),
    });
  },
  token: async () => {
    console.log(
      `%c Login `,
      "background: #ffff00; color: #000; padding: 8px; border-radius: 4px;"
    );
    return zero.post("/auth/token2/");
  },
  validateToken: async () => {
    return authttp.post("/token/validate/", {});
  },
};
