import { http } from "./http";
import { downloaderFiles } from "../utility";

const baseUrl = "/rollout/api/period";

export const periodImporter = async ({ subproject, file }) => {
  const config = {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  };
  const formData = new FormData();
  formData.append("file_upload", file);
  formData.append("subproject", subproject);

  return http
    .post(`${baseUrl}/importer/`, formData, config)
    .then((response) => response.data);
};
export const downloadPeriodTemplate = async (subproject, subprojectName) => {
  return http
    .get(`${baseUrl}/${subproject}/exporter/`, {
      responseType: "blob",
    })
    .then((response) => {
      downloaderFiles(response.data, `${subprojectName}.xlsx`);
      return response;
    })
    .catch((error) => error);
};
const service = {
  periodImporter,
  downloadPeriodTemplate,
};
export default service;
