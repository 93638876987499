import * as Yup from 'yup';

import {
  Alert,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import { FaAngleLeft, FaSignInAlt } from 'react-icons/fa';

import { Container } from './styles';
import { Formik } from 'formik';
import { auth } from 'shared/redux/auth';
import { connect } from 'react-redux';

// import { login } from '../../services';

const LoginValidationSchema = Yup.object().shape({
  username: Yup.string().required('This field is required.'),
  password: Yup.string().required('This field is required.'),
});

/* export function LoginForm({ onChangeLoginOption }: LoginOptionProps) {
  async function onSubmit(
    values: UserAuthenticationDTO,
    actions: FormikHelpers<UserAuthenticationDTO>
  ) {
    const { username, password }: UserAuthenticationDTO = values
    await login(username, password)
      .then((resp) => {
        const { token, refresh_token } = resp
        window.localStorage.setItem('__tk__', token)
        window.localStorage.setItem('__ft__', refresh_token)
      })
      .catch((error) => actions.setErrors(error))
    actions.setSubmitting(false)
  }

  return (
    <Container>
      <Formik
        initialValues={{ username: '', password: '' }}
        validationSchema={LoginValidationSchema}
        onSubmit={onSubmit}
      >
        {({ handleChange, handleSubmit, values, errors, isSubmitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <h3 className='nokia-title pb-2'>External users:</h3>
              <h5 className='nokia-subtitle'>
                Suppliers and other external users, sign in here:
              </h5>
              <p className='nokia-text'>
                <span className='fw-bold'>New accounts:</span> To obtain a login
                to this application, please ask your Nokia contact to request an
                account for you within this site.
              </p>
              <FormGroup className='mb-3'>
                <Label className='form-label' for='username'>
                  Username / Email address <span className='required'>*</span>
                </Label>
                <Input
                  id='username'
                  type='text'
                  name='username'
                  invalid={Boolean(errors.username)}
                  placeholder='Insert your username'
                  onChange={handleChange}
                  value={values.username}
                />
                <FormFeedback role='invalid-username'>
                  {errors.username}
                </FormFeedback>
              </FormGroup>
              <FormGroup className='mb-3'>
                <Label className='form-label' for='password'>
                  Password <span className='required'>*</span>
                </Label>
                <Input
                  type='password'
                  name='password'
                  invalid={Boolean(errors.password)}
                  placeholder='Insert your password'
                  onChange={handleChange}
                  value={values.password}
                />
                <FormFeedback role='invalid-password'>
                  {errors.password}
                </FormFeedback>
              </FormGroup>
              <div className='text-center mb-4'>
                <button
                  className='btn btn-form mb-4'
                  disabled={isSubmitting}
                  type='submit'
                >
                  {isSubmitting ? (
                    <Spinner className='align-middle' size='sm' color='light' />
                  ) : (
                    <>
                      <FaSignInAlt /> Log in
                    </>
                  )}
                </button>
              </div>
              <span
                className='nokia-text go-back-login'
                onClick={onChangeLoginOption}
              >
                <FaAngleLeft />
                Back to Nokia Users Login
              </span>
            </Form>
          )
        }}
      </Formik>
    </Container>
  )
} */

function LoginForm({ onChangeLoginOption, onAuthenticate, error }: any) {
  function onSubmit(values: any, actions: any) {
    onAuthenticate(values);
    actions.setSubmitting(false);
  }

  return (
    <Container>
      <Formik
        initialValues={{ username: '', password: '' }}
        validationSchema={LoginValidationSchema}
        onSubmit={onSubmit}
      >
        {({ handleChange, handleSubmit, values, errors, isSubmitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              {error ? (
                <Alert color='danger'>
                  {' '}
                  The username or password is incorrect please input again!{' '}
                </Alert>
              ) : null}
              <h3 className='nokia-title pb-2'>External users:</h3>
              <h5 className='nokia-subtitle'>
                Suppliers and other external users, sign in here:
              </h5>
              <p className='nokia-text'>
                <span className='fw-bold'>New accounts:</span> To obtain a login
                to this application, please ask your Nokia contact to request an
                account for you within this site.
              </p>

              <FormGroup className='mb-3'>
                <Label className='form-label' for='username'>
                  Username / Email address <span className='required'>*</span>
                </Label>
                <Input
                  id='username'
                  type='text'
                  name='username'
                  invalid={Boolean(errors.username)}
                  placeholder='Insert your username'
                  onChange={handleChange}
                  value={values.username}
                />
                <FormFeedback role='invalid-username'>
                  {errors.username}
                </FormFeedback>
              </FormGroup>
              <FormGroup className='mb-3'>
                <Label className='form-label' for='password'>
                  Password <span className='required'>*</span>
                </Label>
                <Input
                  type='password'
                  name='password'
                  invalid={Boolean(errors.password)}
                  placeholder='Insert your password'
                  onChange={handleChange}
                  value={values.password}
                />
                <FormFeedback role='invalid-password'>
                  {errors.password}
                </FormFeedback>
              </FormGroup>
              <div className='text-center mb-4'>
                <button
                  className='btn btn-form mb-4'
                  disabled={isSubmitting}
                  type='submit'
                >
                  {isSubmitting ? (
                    <Spinner className='align-middle' size='sm' color='light' />
                  ) : (
                    <>
                      <FaSignInAlt /> Log in
                    </>
                  )}
                </button>
              </div>
              <span
                className='nokia-text go-back-login'
                onClick={onChangeLoginOption}
              >
                <FaAngleLeft />
                Back to Nokia Users Login
              </span>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
}

const mapStateToProps = (store: any) => ({
  error: store.auth.error,
  loggedIn: store.auth.loggedIn,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    onAuthenticate: ({ username, password }: any) =>
      dispatch(auth.actions.login(username, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
