import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import * as Chart from 'chart.js';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Icon } from 'zina-dogi-react';
import { changeHeightLegend } from '../../../shared/utility';
import './custom_line.scss';
import 'bootstrap-daterangepicker/daterangepicker.css';
import uuid from 'uuid/v4';

class CustomLine extends Component {
  componentDidMount() {
    const { isCustom } = this.props;
    if (isCustom) {
      const chart = this.refs.line.chartInstance;
      changeHeightLegend(Chart, 30);
      const container = document.querySelector('.line-actions');
      container.innerHTML = chart.generateLegend();
      chart.update();
      this.forceUpdate();
    }
  }

  legendCallBackLinear = chart => {
    // const dataset = chart.data.datasets;
    // const labels = dataset.map(
    //   (data, index) =>
    //     `<option value="${data.label}" data-id="${index}">${data.label}</option>`
    // );
    return `
      <div class="form-row grouper line-actions">
        <div class="form-group actions">
            <!-- Actions here. -->
        </div>
        <!--<div class="form-group">
          <span class="btn btn-sm py-sm-1 extra-actions primary">Disable all</span> 
        </div>  -->
      </div>`;
  };

  displayToggleChart = ({ target }) => {
    const iconElement = target.firstChild || target;
    const chart = this.refs.line.chartInstance;
    const datasets = chart.data.datasets;

    if (iconElement.classList.contains('fa-eye-slash')) {
      iconElement.classList.replace('fa-eye-slash', 'fa-eye');
    } else {
      iconElement.classList.replace('fa-eye', 'fa-eye-slash');
    }

    datasets.forEach((item, index) => {
      const state = chart.getDatasetMeta(index).hidden;
      chart.getDatasetMeta(index).hidden = !state;
    });

    chart.update();
  };

  render() {
    const {
      labels,
      data,
      extraOptions,
      width,
      height,
      ranges,
      startDate,
      endDate,
      updateProcessWeekHandler,
      configurationProcessWeek,
      idName
    } = this.props;

    let start = null;
    let end = null;

    if (configurationProcessWeek) {
      start = moment(configurationProcessWeek.all.start, 'MM-DD-YYYY');
      end = moment(configurationProcessWeek.all.end, 'MM-DD-YYYY');
    }

    return (
      <div className="custom-line">
        <div className="process-week d-flex justify-content-center">
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onApply={updateProcessWeekHandler}
            ranges={ranges}
          >
            <button className="btn btn-light">
              <i className="fa fa-calendar" />
              {` ${moment(startDate || start).format('MMMM D, YYYY')} -
               ${moment(endDate || end).format('MMMM D, YYYY')} `}
              <i className="fa fa-caret-down" />
            </button>
          </DateRangePicker>
          <button className="btn btn-primary" onClick={this.displayToggleChart}>
            <Icon icon="eye" />
          </button>
        </div>
        <div className="line-actions" />
        <Line
          ref="line"
          datasetKeyProvider={uuid}
          data={{
            labels,
            datasets: data
          }}
          width={width || 380}
          height={height || 110}
          options={{
            ...extraOptions,
            responsive: true,
            maintainAspectRatio: true,
            tooltips: {
              cornerRadius: 0,
              yAlign: 'bottom',
              xAlign: 'center'
            },
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ],
            scales: {
              xAxes: [
                {
                  barPercentage: 1.0,
                  gridLines: {
                    display: false,
                    drawBorder: false
                  }
                }
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true
                  },
                  gridLines: {
                    display: false,
                    drawBorder: false
                  }
                }
              ]
            },
            legendCallback: this.legendCallBackLinear,
            animation: {
              onComplete: function(animation) {
                if (
                  idName !== undefined ||
                  document.querySelector(`#${idName}`) !== null
                ) {
                  document
                    .querySelector(`#${idName}`)
                    .setAttribute('href', this.toBase64Image('image/jpeg'));
                }
              }
            }
          }}
        />
      </div>
    );
  }
}

export default CustomLine;
