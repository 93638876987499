import React from 'react';
import './card_kpi.scss';

const card_kpi = ({ type, title, median, max, min }) => {
  return (
    <div className="kpi-card">
      <div className="kpi-header">
        <div className="signal">
          <i className="fas fa-signal text-white" />
        </div>
      </div>

      <div className="kpi-body">
        <span className="d-block text-muted">Type: {type}</span>
        <h5 className="kpi-title" title={title} style={{ marginBottom: 0 }}>
          {title}
        </h5>
        <span className="d-block text-muted">
          Median:
          <span className="text-success text-capitalize">
            {' '}
            {Math.round(+median)} Days
          </span>
        </span>
        <div className="cant">
          <span className="max text-warning">Max: {max} days</span>
          <span className="min text-warning">Min: {min} days</span>
        </div>
      </div>
    </div>
  );
};

export default card_kpi;
