import { hashGenerate } from '../../utility';
import * as actionType from './actionTypes';
import { userManagementService } from '../../../shared';

//userManagement
const fetchUserManagementStart = () => ({
  type: actionType.FETCH_USER_MANAGEMENT_START
});

const fetchUserManagementSuccess = data => ({
  type: actionType.FETCH_USER_MANAGEMENT_SUCCESS,
  data
});

const fetchUserManagementFail = error => ({
  type: actionType.FETCH_USER_MANAGEMENT_FAIL,
  error
});

export const fetchUserManagement = queryParams => {
  return dispatch => {
    dispatch(fetchUserManagementStart());
    return userManagementService
      .getUserManagement(queryParams)
      .then(response => dispatch(fetchUserManagementSuccess(response)))
      .catch(error => dispatch(fetchUserManagementFail(error)));
  };
};

//list permissions
const fetchUsersListPermissionsStart = () => ({
  type: actionType.FETCH_LIST_PERMISSIONS_START
});

const fetchUserListPermissionsSuccess = data => ({
  type: actionType.FETCH_LIST_PERMISSIONS_SUCCESS,
  data
});

const fetchUserListPermissionsFail = error => ({
  type: actionType.FETCH_LIST_PERMISSIONS_FAIL,
  error
});

export const fetchUserListPermissions = () => {
  return dispatch => {
    dispatch(fetchUsersListPermissionsStart());
    return userManagementService
      .getListPermissions()
      .then(response => dispatch(fetchUserListPermissionsSuccess(response)))
      .catch(error => dispatch(fetchUserListPermissionsFail(error)));
  };
};

// specific user
const fetchSpecificUserStart = () => ({
  type: actionType.FETCH_SPECIFIC_USER_START
});

const fetchSpecificUserSuccess = data => ({
  type: actionType.FETCH_SPECIFIC_USER_SUCCESS,
  data
});

const fetchSpecificUserFail = error => ({
  type: actionType.FETCH_SPECIFIC_USER_FAIL,
  error
});

export const fetchSpecificUser = idUser => {
  return dispatch => {
    dispatch(fetchSpecificUserStart());
    return userManagementService
      .getSpecificUser(idUser)
      .then(response => dispatch(fetchSpecificUserSuccess(response)))
      .catch(error => dispatch(fetchSpecificUserFail(error)));
  };
};

// Roles assigned to profile user
const fetchRolesAssignedToSubprojectStart = () => ({
  type: actionType.FETCH_ROL_ASSIGNED_TO_PROFILES_USER_START,
  payload: {
    loading: true
  }
});

const fetchRolesAssignedToSubprojectSuccess = data => ({
  type: actionType.FETCH_ROL_ASSIGNED_TO_PROFILES_USER_SUCCESS,
  payload: {
    loading: false,
    rolesAssignedToSubproject: data
  }
});

const fetchRolesAssignedToSubprojectFailed = error => ({
  type: actionType.FETCH_ROL_ASSIGNED_TO_PROFILES_USER_FAIL,
  payload: {
    loading: false,
    error
  }
});

export const fetchRolesAssignedToSubproject = subprojectId => {
  return dispatch => {
    dispatch(fetchRolesAssignedToSubprojectStart());
    userManagementService
      .getRolesSubproject(subprojectId)
      .then(response =>
        dispatch(fetchRolesAssignedToSubprojectSuccess(response))
      )
      .catch(error => dispatch(fetchRolesAssignedToSubprojectFailed(error)));
  };
};

// Subproject assigned to user
const fetchSubprojectAssignedStart = () => ({
  type: actionType.FETCH_SUBPROJECTS_ASSIGN_START,
  payload: {
    loading: true
  }
});

const fetchSubprojectAssignedSuccess = data => ({
  type: actionType.FETCH_SUBPROJECTS_ASSIGN_SUCCESS,
  payload: {
    loading: false,
    subprojectsAssigned: data
  }
});

const fetchSubprojectAssignedFailed = error => ({
  type: actionType.FETCH_SUBPROJECTS_ASSIGN_FAIL,
  payload: {
    error,
    loading: false
  }
});

export const fetchSubprojectAssigned = userId => {
  return dispatch => {
    fetchSubprojectAssignedStart();
    userManagementService
      .getSubprojectAssignToUser(userId)
      .then(response => dispatch(fetchSubprojectAssignedSuccess(response)))
      .catch(error => dispatch(fetchSubprojectAssignedFailed(error)));
  };
};

// Permissions by subprojects
const fetchPermissionsBySubprojectStart = () => ({
  type: actionType.FETCH_PERMISSIONS_BY_SUBPROJECT_START,
  payload: {
    loading: true
  }
});

const fetchPermissionsBySubprojectSuccess = data => ({
  type: actionType.FETCH_PERMISSIONS_BY_SUBPROJECT_SUCCESS,
  payload: {
    loading: false,
    permissionsBySubproject: data
  }
});

const fetchPermissionsBySubprojectFailed = error => ({
  type: actionType.FETCH_PERMISSIONS_BY_SUBPROJECT_FAIL,
  payload: {
    error,
    loading: false
  }
});

export const fetchPermissionsBySubproject = (userId, subprojectId) => {
  return dispatch => {
    dispatch(fetchPermissionsBySubprojectStart());
    userManagementService
      .getPermissionsBySubproject(userId, subprojectId)
      .then(response => {
        const {
          one_delete_data,
          allow_delete_data,
          recovery_delete_line,
          others,
          activities,
          id
        } = response;

        const data = {
          actions: {
            one_delete_data,
            allow_delete_data,
            recovery_delete_line
          },
          userProject: id,
          subprojectFields: [
            ...activities,
            { id: hashGenerate(), name: 'OTHERS', fields_: others }
          ]
        };

        dispatch(fetchPermissionsBySubprojectSuccess(data));
      })
      .catch(error => dispatch(fetchPermissionsBySubprojectFailed(error)));
  };
};
