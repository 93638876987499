import { http } from "./http";

const baseUrlAPI = "/integration/api";

export const getReport = async (page = "", search = null) => {
  return http
    .get(
      `${baseUrlAPI}/subproject_report/${page ? "?page=" + page + "&" : "?"}${
        search ? "search=" + search : ""
      }`
    )
    .then((response) => response.data);
};

export const saveReport = async (data) => {
  return http
    .post(`${baseUrlAPI}/subproject_report/`, data)
    .then((res) => res)
    .catch((error) => error);
};

export const getSpecificReport = async (id) => {
  return http
    .get(`${baseUrlAPI}/subproject_report/${id}/`)
    .then((res) => res)
    .catch((error) => error);
};

export const editReport = async (data, id) => {
  return http
    .put(`${baseUrlAPI}/subproject_report/${id}/`, data)
    .then((res) => res)
    .catch((error) => error);
};

export const deleteSpecificReport = async (id) => {
  return http
    .delete(`${baseUrlAPI}/subproject_report/${id}/`)
    .then((res) => res)
    .catch((error) => error);
};

const listService = {
  getReport,
  saveReport,
  editReport,
  getSpecificReport,
  deleteSpecificReport,
};
export default listService;
