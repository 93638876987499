import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import { ButtonStyled } from './styles';
import { Collapse } from 'reactstrap';
import { useState } from 'react';

interface FAQProps {
  id: string;
  title: string;
  description: string | ((props?: any) => any);
}

export function FAQItem({ id, title, description }: FAQProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const collapsedClass = !isOpen ? 'collapsed' : '';

  function handleChange() {
    setIsOpen(!isOpen);
  }

  return (
    <div className='col-lg-6'>
      <div className='accordion accordion-flush'>
        <div
          className='accordion-item'
          key={title}
          style={{ borderBottom: '1px solid rgba(0, 0, 0, .125)' }}
        >
          <h2 className='accordion-header'>
            <ButtonStyled
              className={`accordion-button ${collapsedClass}`}
              color='light'
              id={id}
              onClick={handleChange}
            >
              {title} {isOpen ? <FaChevronUp /> : <FaChevronDown />}
            </ButtonStyled>
          </h2>
          <Collapse isOpen={isOpen} toggler={`#${id}`}>
            <div className='accordion-body'>
              {typeof description === 'function' ? description() : description}
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
}
