import { http } from "./http";
const baseUrl = "/api/notifications";

export const getNotifications = async (page) => {
  return http.get(`${baseUrl}/?page=${page}`).then((response) => response.data);
};
export const getDocument = async (uri) => {
  const BASE_URL = process.env.REACT_APP_API_ENDPOINT_URL;
  const link = document.createElement("a");
  link.href = `${BASE_URL}${uri}`;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
};
export const markAllAsRead = async () => {
  return http
    .post(`${baseUrl}/mark_all_as_read/`)
    .then((response) => response.data);
};
export const markAsUnread = async (idNotification) => {
  return http
    .put(`${baseUrl}/${idNotification}/mark_as_unread/`, {
      idNotification,
    })
    .then((response) => response.data);
};
export const markAsRead = async (idNotification) => {
  return http
    .put(`${baseUrl}/${idNotification}/mark_as_read/`, {
      idNotification,
    })
    .then((response) => response.data);
};
export const deleteNotification = async (idNotification) => {
  return http
    .delete(`${baseUrl}/${idNotification}`)
    .then((response) => response.data);
};
export const getNotificationUnread = async () => {
  return http.get(`${baseUrl}/unread_live/`).then((response) => response.data);
};

const service = {
  getNotifications,
  getDocument,
  markAllAsRead,
  markAsUnread,
  markAsRead,
  deleteNotification,
  getNotificationUnread,
};
export default service;
