import * as actionTypes from './actionTypes';
import { updateObject } from '../utility';

const initialState = {
  kpiItems: [],
  loading: false,
  error: null
};

const fetchKPIStart = (state, action) => {
  return updateObject(state, { loading: true, error: null });
};

const fetchKPIFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    kpiItems: []
  });
};

const fetchKPISuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    kpiItems: action.data
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_KPI_START:
      return fetchKPIStart(state, action);

    case actionTypes.FETCH_KPI_SUCCESS:
      return fetchKPISuccess(state, action);

    case actionTypes.FETCH_KPI_FAIL:
      return fetchKPIFail(state, action);

    default:
      return state;
  }
};

export default reducer;
