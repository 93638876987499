import defaultAvatar from '../../../assets/img/testimonials/default.jpg'
import johnAvatar from '../../../assets/img/testimonials/john_altamiranda.jpg'
import kelaAvatar from '../../../assets/img/testimonials/kela_davis.jpg'
import lilianaAvatar from '../../../assets/img/testimonials/liliana_salas_barrios.jpg'
import { nanoid } from 'nanoid'

export const testimonialsData = [
  {
    key: nanoid(),
    username: 'Lilian Menendez',
    role: 'Logistics Coordinator',
    avatar: defaultAvatar,
    comment: `The experience I've had in ZINA BI to upload reports has been mostly good. Its performance is very satisfactory, and the import and update times are fast.`,
  },
  {
    key: nanoid(),
    username: 'John Altamiranda',
    avatar: johnAvatar,
    role: 'PMO Manager',
    comment:
      'ZINA BI is an excellent tool, effectively helping us to manage configured projects, allowing data flexibility and portability. ZINA BI is a good alternative for data automation and project reporting.',
  },
  {
    key: nanoid(),
    username: 'Liliana Salas Barrios',
    role: 'PMO, Buisness Operations',
    avatar: lilianaAvatar,
    comment:
      'ZINA BI is a vital tool for PMO, the perfect partner. We save a lot of time processing different files from multiple sources, and summarize data within the scope of a report. Thank you, ZINA, for making our lives easier.',
  },
  {
    key: nanoid(),
    username: 'Kela Davis',
    avatar: kelaAvatar,
    role: 'Lead MSO Customer Delivery Manager',
    comment:
      ' Site-Based Dashboard is a great, user-friendly consolidated source for quickly referencing key site-specific data at a glance to help confirm the status and isolate opportunities for action to ensure the site achieves desired/expected financial outcome.',
  },
]
