import React from "react";
import Select from "react-select";

const SearchSelect = ({
  listItems,
  itemSelected,
  loading = false,
  onChangeValue,
}) => {
  return (
    <div data-testid="my-searchSelect-component">
      {listItems && listItems.length > 0 ? (
        <Select
          className="form-control searchSelect basic-single"
          name="projects"
          classNamePrefix="select"
          defaultValue={
            listItems.find((v) => v.value === itemSelected) ||
            listItems[0] || {
              value: "loading",
              label: "loading",
            }
          }
          isLoading={loading}
          isRtl={false}
          isSearchable={true}
          onChange={(v) => {
            onChangeValue(v && v.value);
          }}
          options={listItems}
        />
      ) : (
        <div />
      )}
    </div>
  );
};

export default SearchSelect;
