import * as actionTypes from './actionTypes';
import { updateObject } from '../utility';

const initialState = {
  loading: false,
  error: null,
  gapGrouperSelected: null,
  gapAnalysisItems: {},
  gapDetails: []
};

const fetchGapAnalysisStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    gapDetails: null,
    gapGrouperSelected: null
  });
};

const fetchGapAnalysisSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    gapAnalysisItems: action.data
  });
};

const fetchGapAnalysisFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    gapAnalysisItems: {}
  });
};

// gap detail

const fetchGapAnalysisDetailStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    gapDetails: null
  });
};

const fetchGapAnalysisDetailFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  });
};

const fetchGapAnalysisDetailSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    gapGrouperSelected: action.grouper,
    gapDetails: action.data
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_GAP_ANALYSIS_START:
      return fetchGapAnalysisStart(state, action);

    case actionTypes.FETCH_GAP_ANALYSIS_SUCCESS:
      return fetchGapAnalysisSuccess(state, action);

    case actionTypes.FETCH_GAP_ANALYSIS_FAIL:
      return fetchGapAnalysisFailed(state, action);

    // gap details

    case actionTypes.GAP_ANALYSIS_DETAILS_START:
      return fetchGapAnalysisDetailStart(state, action);

    case actionTypes.GAP_ANALYSIS_DETAILS_FAIL:
      return fetchGapAnalysisDetailFailed(state, action);

    case actionTypes.GAP_ANALYSIS_DETAILS_SUCCESS:
      return fetchGapAnalysisDetailSuccess(state, action);

    default:
      return state;
  }
};

export default reducer;
