import { http } from "./http";

export const sendEmailChangePassword = async () => {
  return http.post("/profile/api/profile/change_password_profile/").then((resp) => resp.data);
};
export const passwordReset = async (values) => {
  return http.post(`/profile/api/users/reset_password/`, values).then((resp) => resp.data);
};
export const getInfoUserProfile = async (userId) => {
  return http.get(`/profile/api/profile/${userId}/`).then((resp) => resp.data);
};
export const updateUserProfile = async (userId, values) => {
  return http.put(`/profile/api/profile/${userId}/`, values).then((resp) => resp.data);
};
export const getSubprojectFiltersSaved = async (userId, subprojectId) => {
  return http
    .get(`/profile/api/profile/${userId}/`, {
      params: { subproject_id: subprojectId },
    })
    .then((resp) => resp.data);
};
export const saveSubprojectFiltersApplied = async (values) => {
  return http.post("/profile/api/userprojects/save_filters/", values).then((resp) => resp.data);
};
export const cleanSubprojectFiltersApplied = async (values) => {
  return http.post("/profile/api/userprojects/clean_filters/", values).then((resp) => resp.data);
};

const service = {
  sendEmailChangePassword,
  passwordReset,
  getInfoUserProfile,
  updateUserProfile,
  getSubprojectFiltersSaved,
  saveSubprojectFiltersApplied,
  cleanSubprojectFiltersApplied,
};
export default service;
