import { SiMicrosoftsharepoint, SiYammer } from 'react-icons/si';

/* eslint-disable jsx-a11y/anchor-is-valid */
import logo from '../../assets/img/footer-logo.svg';

export function Footer() {
  return (
    <footer id='footer' className='footer' aria-label='footer'>
      <div className='footer-top'>
        <div className='container'>
          <div className='row gy-4'>
            <div className='col-lg-5 col-md-12 footer-info'>
              <img
                className='logo d-flex align-items-center'
                src={logo}
                alt='logo Nokia'
                width='160px'
                height='auto'
              />
              <p className='fs-6'>
                We are <span className='fw-bolder'>Doers</span> working together
              </p>
              <cite>
                "The best way to predict the future is to create it"
                <div> - Abraham Lincoln</div>
              </cite>
              <div className='social-links mt-3'>
                <a
                  href='https://nokia.sharepoint.com/sites/zina_central'
                  className='linkedin'
                  target='_blank'
                  rel='noreferrer'
                >
                  <SiMicrosoftsharepoint />
                </a>
                <a
                  href='https://www.instagram.com/zinateam/'
                  className='instagram'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='bi bi-instagram bx bxl-instagram'></i>
                </a>
                <a
                  href='https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI3NDAwNzYxIn0/all'
                  className='linkedin'
                  target='_blank'
                  rel='noreferrer'
                >
                  <SiYammer />
                </a>
              </div>
            </div>

            <div className='col-lg-4 col-6 footer-links'>
              <h4>Navigation</h4>
              <ul>
                <li>
                  <i className='bi bi-chevron-right'></i>{' '}
                  <a href='#about'>About</a>
                </li>
                <li>
                  <i className='bi bi-chevron-right'></i>{' '}
                  <a href='#'>Get access</a>
                </li>
                <li>
                  <i className='bi bi-chevron-right'></i> <a href='#'>Login</a>
                </li>
              </ul>
            </div>

            <div className='col-lg-3 col-md-12 footer-contact text-center text-md-start'>
              <h4 className='need-help'>Need help?</h4>
              <p>
                Please, don't hesitate to reach us at support.zina@nokia.com
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='copyright-landing'>
          &copy; <span>Nokia 2018 - {new Date().getFullYear()}</span>
        </div>
      </div>
    </footer>
  );
}
