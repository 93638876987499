import activeUserImg from '../../assets/img/active-users.svg'
import dailyTransactionsImg from '../../assets/img/transactions.svg'
import projectsImg from '../../assets/img/projects.svg'
import styled from 'styled-components'

const Image = styled.img`
  width: 100px;

  &.projects {
    margin-right: 0.8em;
  }
`
export function Count() {
  return (
    <section id='counts' className='counts'>
      <div className='container' data-aos='fade-up'>
        <div className='row gy-4'>
          <div className='col-lg-4 col-md-6'>
            <div className='count-box'>
              <Image src={activeUserImg} alt='Active users' />
              <div>
                <span className='counter-number'>+400</span>
                <p>Active users</p>
              </div>
            </div>
          </div>

          <div className='col-lg-4 col-md-6'>
            <div className='count-box'>
              <Image className='projects' src={projectsImg} alt='Projects' />
              <div>
                <span className='counter-number'>+170</span>
                <p>Projects</p>
              </div>
            </div>
          </div>

          <div className='col-lg-4 col-md-6'>
            <div className='count-box'>
              <Image src={dailyTransactionsImg} alt='Daily transactions' />
              <div>
                <span className='counter-number'>~58</span>
                <p>Daily transactions</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
