import { downloaderFiles } from "../utility";
import { http } from "./http";

const baseUrl = "rollout/api/subimport";

export const getActions = async (subprojectId) => {
  return http.get(`${baseUrl}/${subprojectId}/actions/`).then((response) => {
    return response.data;
  });
};
export const importDocument = async ({ file, project, action, config }) => {
  const formData = new FormData();
  formData.append("file_upload", file);
  formData.append("subproject", project);
  formData.append("action", action);
  return await http
    .post(`${baseUrl}/load/`, formData, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
      ...config,
    })
    .then((response) => {
      return response.data;
    });
};

export const downloadTemplate = async (
  idSubproject,
  subprojectSelectedName
) => {
  return http
    .get(`${baseUrl}/${idSubproject}/download_template/`, {
      responseType: "blob",
    })
    .then((response) => {
      downloaderFiles(response.data, `${subprojectSelectedName}.xlsx`);
      return response;
    })
    .catch((error) => error);
};

const service = {
  getActions,
  importDocument,
  downloadTemplate,
};
export default service;
