import styled from 'styled-components';

export const ButtonStyled = styled.button`
  font-weight: 600;
  font-size: 1.2rem;
  color: #444;
  background: transparent;
  text-align: left;
  width: 100%;
  padding-left: 0;
  display: flex;
  justify-content: space-between;

  &:focus {
    outline: none;
  }
`;
