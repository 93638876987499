import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import reduxReset from 'redux-reset';
import authReducer from './auth/reducer';
import subprojectsReducer from './dashboard/reducer';
import gapAnalysisReducer from './gap_analysis/reducer';
import kpiReducer from './kpi/reducer';
import projectListReducer from './project_list/reducer';
import siteReducer from './sites/reducer';
import notificationReducer from './notifications/reducer';
import userProfile from './user_profile/reducer';
import userManagementReducer from './user_management/reducer';
import rolReducer from './role/reducer';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'subprojectSelected',
    'subprojectSelectedName',
    'subprojectItems',
    'generalConfiguration'
  ]
};

const subprojectPersisted = persistReducer(persistConfig, subprojectsReducer);

const store = createStore(
  combineReducers({
    userProfile: userProfile,
    auth: authReducer,
    subproject: subprojectPersisted,
    gapAnalysis: gapAnalysisReducer,
    kpis: kpiReducer,
    projectList: projectListReducer,
    site: siteReducer,
    notification: notificationReducer,
    userManagement: userManagementReducer,
    rol: rolReducer
  }),
  composeEnhancer(applyMiddleware(thunk), reduxReset())
);

const persistor = persistStore(store);

export { store, persistor };
