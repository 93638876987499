import logo from '../../assets/img/zina-bi-logo-positive.svg';
import { useRef } from 'react';

type HeaderProps = {
  headerRef: any;
  onToggle(option: string): void;
  scrollTo(): void;
};

export function Header({ headerRef, onToggle, scrollTo }: HeaderProps) {
  const navbarRef = useRef<HTMLElement>(null);
  const navMobileRef = useRef<HTMLElement>(null);

  const handleMobileNavToggle = ({ target }: any) => {
    navbarRef?.current?.classList.toggle('navbar-mobile');
    target.classList.toggle('bi-list');
    target.classList.toggle('bi-x');
  };

  const handleScrollSection = () => {
    const nav = navbarRef?.current;
    if (nav?.classList.contains('navbar-mobile')) {
      nav.classList.remove('navbar-mobile');
      navMobileRef?.current?.classList.toggle('bi-list');
      navMobileRef?.current?.classList.toggle('bi-x');
    }
    setTimeout(() => scrollTo(), 0);
  };

  return (
    <header id='header' className='header fixed-top' ref={headerRef}>
      <div className='container-fluid container-xl d-flex align-items-center justify-content-between'>
        <a href='#hero' className='logo d-flex align-items-center'>
          <img src={logo} height='50' alt='logo ZINA BI' />
        </a>

        <nav id='navbar' className='navbar' ref={navbarRef}>
          <ul>
            <li>
              <span className='get-access'>
                <a
                  data-link='about'
                  className='nav-link scrollto'
                  href='#about'
                  onClick={handleScrollSection}
                >
                  About
                </a>
              </span>
            </li>
            {/*
            <li>
              <span
                className='get-access'
                onClick={() => {
                  handleScrollSection();
                  onToggle('get-access');
                }}
              >
                Get access
              </span>
            </li>
              */}
            <li>
              <span
                className='login bg-green-light'
                onClick={() => {
                  handleScrollSection();
                  onToggle('login');
                }}
              >
                Login
              </span>
            </li>
          </ul>
          <i
            ref={navMobileRef}
            className='bi bi-list mobile-nav-toggle'
            onClick={handleMobileNavToggle}
          ></i>
        </nav>
        {/* <!-- .navbar --> */}
      </div>
    </header>
  );
}
