import {
  fetchAlertSite,
  fetchGrouperActivities,
  fetchFormSchema,
  gropuerSelected,
  creteNewSite,
  formSchemaEdit,
  resetFormSchemaEdit,
  fetchActivitiesDocuments
} from './actions';

export const siteActions = {
  fetchAlertSite,
  fetchGrouperActivities,
  fetchFormSchema,
  gropuerSelected,
  creteNewSite,
  formSchemaEdit,
  resetFormSchemaEdit,
  fetchActivitiesDocuments
};
