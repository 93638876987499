import MultiSelect from '@kenshooui/react-multi-select';
import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Skeleton from 'react-loading-skeleton';
import {
  Alert,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import swal from 'sweetalert';
import logo from '../../../assets/imgs/logo-melius.svg';
import { projectListService } from '../../../shared';

const styles = {
  background: 'var(--melius-primary)',
  color: '#fff',
};

const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

export default function ModalUpdateDateRows({
  context,
  project,
  isOpen,
  onToggle,
}) {
  const [date, setDate] = useState(() => moment(new Date()));
  const [selectedItems, setSelectedItems] = useState([]);
  const [dateFields, setDateFields] = useState(null);

  useState(() => {
    if (!project) {
      return;
    }
    projectListService.getDateTypeFieldsUserUpdate(project).then(setDateFields);
  }, [project]);

  const handleUpdateDateRows = () => {
    const rows = context.gridApi.getSelectedNodes();
    if (rows.length === 0 || selectedItems.length === 0) {
      swal(
        'Oops!',
        'You must have selected at least one row of the table and one option from the list of fields of the project.',
        'warning',
      );
      return;
    }

    const data = {
      fields: selectedItems.map((s) => s.id),
      lines: rows.map((r) => r.data?.extraOptions?.id),
      time_zone: TIMEZONE,
      date: date.format(DEFAULT_DATE_FORMAT),
    };

    projectListService
      .updateDateRows(project, data)
      .then((resp) => {
        context.gridApi.deselectAll();
        onToggle();
        swal('Good Job!', resp.message, 'success');
        setSelectedItems([]);
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.detail;
        swal('Oops!', errorMessage, 'error');
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={onToggle} centered size='lg'>
      <ModalHeader
        style={styles}
        toggle={onToggle}
        close={
          <button className='close text-white' onClick={onToggle}>
            &times;
          </button>
        }
      >
        <img src={logo} alt='logo zina bi' width={110} />
      </ModalHeader>
      <ModalBody>
        <h4 className='text-center mb-4'>Update extra fields date</h4>
        {!dateFields ? (
          <Skeleton height={40} />
        ) : dateFields.length > 0 ? (
          <>
            <h6 className='font-weight-bold mb-2'>Fields project</h6>
            <Row className='mb-4'>
              <Col sm={7}>
                <MultiSelect
                  items={dateFields}
                  selectedItems={selectedItems}
                  onChange={setSelectedItems}
                  showSelectedItems={false}
                />
              </Col>
              <Col sm={5}>
                <DatePicker
                  dateFormat={DEFAULT_DATE_FORMAT}
                  selected={date}
                  onChange={(date) => setDate(date)}
                  inline
                />
              </Col>
            </Row>
            <div className='d-flex justify-content-end'>
              <Button color='light' className='mr-2' onClick={onToggle}>
                Cancel
              </Button>
              <Button style={styles} onClick={handleUpdateDateRows}>
                Update
              </Button>
            </div>
          </>
        ) : (
          <Alert color='info'>
            The user does not have date type fields assigned.!
          </Alert>
        )}
      </ModalBody>
    </Modal>
  );
}
