import * as Yup from 'yup';

import { BasicInformationPopover, ProjectDefinitionPopover } from './Popover';
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import { ChangeEvent, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';

import { SectionTitle } from './SectionTitle';
import Swal from 'sweetalert2';
import { getAccess } from '../../services';
import { isNokiaEmail } from '../../../utils/validations';
import { useGetAccess } from '../../hooks/useGetAccess';

const GeAccessValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('This is a invalid email')
    .required('This field is required.'),
  password: Yup.string().when('email', {
    is: (value: string) => !isNokiaEmail(value),
    then: Yup.string().required('This field is required.'),
    otherwise: Yup.string(),
  }),
  password2: Yup.string().when('email', {
    is: (value: string) => !isNokiaEmail(value),
    then: Yup.string()
      .oneOf([Yup.ref('password'), null], "Passwords don't match!")
      .required('This field is required.'),
    otherwise: Yup.string(),
  }),
});

function renderOptions<T extends { id: string | number; name: string }>(
  options: T[] = [],
) {
  return (
    <>
      <option>Select an option...</option>
      {options?.map(({ id, name }: T) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </>
  );
}

function ProjectDefinition({ values, errors, handleChange }: any) {
  const [projectSelected, setProjectSelected] = useState<string | number>('');
  const { projects, roles, customerTeams } = useGetAccess(projectSelected);

  return (
    <div data-testid='project-definition-fields'>
      <Row>
        <Col lg={4} sm={12}>
          <FormGroup className='mb-3'>
            <Label className='form-label' for='email_manager'>
              Line Manager Email
            </Label>
            <Input
              id='email_manager'
              type='email'
              name='email_manager'
              placeholder='email_manager@nokia.com'
              invalid={Boolean(errors.email_manager)}
              value={values.email_manager}
              onChange={handleChange}
            />
            <FormFeedback>{errors.email_manager}</FormFeedback>
          </FormGroup>
        </Col>
        <Col lg={4} sm={12}>
          <FormGroup className='mb-3'>
            <Label className='form-label' for='market'>
              Market Unit
            </Label>
            <Input
              className='form-select'
              type='select'
              name='market'
              id='market'
              invalid={Boolean(errors.market)}
              value={values.market}
              onChange={handleChange}
            >
              <option value=''>Select an option...</option>
              <option value='NAM'>NAM</option>
              <option value='LAT'>LAT</option>
            </Input>
            <FormFeedback>{errors.market}</FormFeedback>
          </FormGroup>
        </Col>
        <Col lg={4} sm={12}>
          <FormGroup className='mb-3'>
            <Label className='form-label' for='role'>
              Role
            </Label>
            {roles.isLoading ? (
              <Spinner
                className='align-middle mx-1'
                size='sm'
                color='primary'
              />
            ) : (
              <Input
                className='form-select'
                type='select'
                name='rol'
                id='role'
                invalid={Boolean(errors.rol)}
                value={values.rol}
                onChange={handleChange}
              >
                {renderOptions(roles.data)}
              </Input>
            )}
            <FormFeedback>{errors.rol}</FormFeedback>
          </FormGroup>
        </Col>
        <Col lg={4} sm={12}>
          <FormGroup className='mb-3'>
            <Label className='form-label' for='customer-teams'>
              Customer Team
            </Label>
            {customerTeams.isLoading ? (
              <Spinner
                className='align-middle mx-1'
                size='sm'
                color='primary'
              />
            ) : (
              <Input
                className='form-select'
                type='select'
                name='customer_teams'
                id='customer-teams'
                invalid={Boolean(errors.customer_teams)}
                value={values.customer_teams}
                onChange={handleChange}
              >
                {renderOptions(customerTeams.data)}
              </Input>
            )}
            <FormFeedback>{errors.customer_teams}</FormFeedback>
          </FormGroup>
        </Col>
        <Col lg={4} sm={12}>
          <FormGroup className='mb-3'>
            <Label className='form-label' for='project'>
              Project
            </Label>
            {projects.isLoading ? (
              <Spinner
                className='align-middle mx-1'
                size='sm'
                color='primary'
              />
            ) : (
              <Input
                className='form-select'
                type='select'
                name='subproject'
                id='project'
                invalid={Boolean(errors.project)}
                value={values.subproject}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  handleChange(e);
                  setProjectSelected(value);
                }}
              >
                {renderOptions(projects.data)}
              </Input>
            )}
            <FormFeedback>{errors.project}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}

export function GetAccess() {
  const [projectDefinitionCheck, setProjectDefinitionCheck] = useState(false);

  const handleProjectDefinition = () => setProjectDefinitionCheck((s) => !s);

  async function onSubmit(values: any, actions: FormikHelpers<any>) {
    await getAccess(values)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: `Welcome to ${values.email}`,
          text: 'Your request has been sent successfully. Please wait for the support team to contact you.',
          showConfirmButton: true,
          timer: 4000,
        });
      })
      .catch((err) => {
        if (err) {
          actions.setErrors(err);
        }
      });
    actions.setSubmitting(false);
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        password2: '',
        comments: '',
        first_name: '',
        last_name: '',
        email_manager: '',
        market: '',
        project: '',
        rol: '',
        customer_teams: '',
      }}
      validationSchema={GeAccessValidationSchema}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        isSubmitting,
        isValid,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <SectionTitle
              className='pt-4 pb-2'
              title='Basic Information'
              popoverID='basic'
              renderPopoverBody={<BasicInformationPopover />}
            />

            <Row>
              <Col lg={4} sm={12}>
                <FormGroup className='mb-3'>
                  <Label className='form-label' for='email'>
                    Email
                  </Label>
                  <Input
                    id='email'
                    role='email'
                    type='text'
                    name='email'
                    placeholder='Your email'
                    onChange={handleChange}
                    invalid={Boolean(errors.email)}
                    value={values.email}
                  />
                  <FormFeedback>{errors?.email}</FormFeedback>
                </FormGroup>
              </Col>
              <Col lg={4} sm={12}>
                <FormGroup className='mb-3'>
                  <Label className='form-label' for='first-name'>
                    First name
                  </Label>
                  <Input
                    id='first-name'
                    aria-label='first_name'
                    type='text'
                    name='first_name'
                    invalid={Boolean(errors.first_name)}
                    placeholder='Your first name'
                    onChange={handleChange}
                    value={values.first_name}
                  />
                  <FormFeedback>{errors.first_name}</FormFeedback>
                </FormGroup>
              </Col>
              <Col lg={4} sm={12}>
                <FormGroup className='mb-3'>
                  <Label className='form-label' for='last-name'>
                    Last name
                  </Label>
                  <Input
                    id='last-name'
                    aria-label='last_name'
                    type='text'
                    name='last_name'
                    invalid={Boolean(errors.last_name)}
                    placeholder='Your last name'
                    onChange={handleChange}
                    value={values.last_name}
                  />
                  <FormFeedback>{errors.last_name}</FormFeedback>
                </FormGroup>
              </Col>

              {!isNokiaEmail(values.email) ? (
                <>
                  <Col lg={4} sm={12}>
                    <FormGroup className='mb-3'>
                      <Label className='form-label' for='password'>
                        Set a password
                      </Label>
                      <Input
                        id='password'
                        role='password'
                        type='password'
                        name='password'
                        invalid={Boolean(errors.password)}
                        placeholder='***********'
                        onChange={handleChange}
                        value={values.password}
                      />
                      <FormFeedback>{errors.password}</FormFeedback>
                    </FormGroup>
                  </Col>

                  <Col lg={4} sm={12}>
                    <FormGroup className='mb-3'>
                      <Label className='form-label' for='password-confirm'>
                        Confirm your password
                      </Label>
                      <Input
                        id='password-confirm'
                        role='password-confirm'
                        type='password'
                        name='password2'
                        invalid={Boolean(errors.password2)}
                        placeholder='***********'
                        onChange={handleChange}
                        value={values.password2}
                      />
                      <FormFeedback>{errors.password2}</FormFeedback>
                    </FormGroup>
                  </Col>
                </>
              ) : null}

              <Col className='d-flex align-items-center' lg={4} sm={12}>
                <FormGroup check className='mb-3'>
                  <Label check className='form-label'>
                    <Input
                      checked={projectDefinitionCheck}
                      onChange={handleProjectDefinition}
                      role='checkbox'
                      type='checkbox'
                    />{' '}
                    Project Definition
                  </Label>
                </FormGroup>
              </Col>
            </Row>

            <SectionTitle
              title='Project Definition'
              popoverID='definition'
              renderPopoverBody={<ProjectDefinitionPopover />}
            />

            {projectDefinitionCheck ? (
              <ProjectDefinition {...{ errors, values, handleChange }} />
            ) : null}
            <FormGroup className='mb-3'>
              <Label className='form-label' for='market'>
                Comments
              </Label>
              <Col lg={12} className='px-0'>
                <FormGroup className='mb-3'>
                  <Input
                    data-testid='comments'
                    type='textarea'
                    name='comments'
                    placeholder='You can send a message a the user responsible.'
                    onChange={handleChange}
                    value={values.comments}
                  />
                </FormGroup>
              </Col>
            </FormGroup>

            <Col className='row justify-content-end mx-0 mt-4 col-lg-offset-6'>
              <Button
                className='mb-2 col-lg-3 col-sm-12 btn-form'
                disabled={isSubmitting || !isValid}
                type='submit'
              >
                {isSubmitting ? (
                  <Spinner size='sm' color='light' />
                ) : (
                  'Get access'
                )}
              </Button>
            </Col>
          </Form>
        );
      }}
    </Formik>
  );
}
