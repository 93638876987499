import React, { Component } from "react";

import { UncontrolledDropdown } from "reactstrap";
import { Icon } from "zina-dogi-react";
import "./wrapper.scss";

export class CustomDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMe: false,
      displayT: "none",
      boyHide: "none",
      bodyWidth: "0px",
      bodyHeight: "0px",
      bodyZindex: "1",
      bodyBackground: "",
      width: document.body.clientWidth * 1000,
      height: document.body.clientHeight,
    };
  }
  operation() {
    this.setState({
      width: document.body.clientWidth * 1000,
      height: document.body.clientHeight,
      showMe: !this.state.showMe,
      displayT: this.state.displayT === "block" ? "none" : "block",
      boyHide: this.state.displayT === "block" ? "none" : "block",
      bodyWidth: this.state.displayT === "block" ? "0px" : "100vw",
      bodyHeight: this.state.displayT === "block" ? "0px" : "100vh",
      bodyZindex: this.state.displayT === "block" ? "1" : "1000",
      bodyBackground:
        this.state.displayT === "block" ? "" : "rgba(255, 255,255,0)",
    });
  }
  render() {
    return (
      <div>
        {
          <div
            className="CustomDropdownWrapper"
            style={{
              display: this.state.displayT,
            }}
          >
            <li
              style={{ cursor: "pointer" }}
              onClick={this.props.togglecollapse}
            >
              {!this.props.iscollapse && (
                <span onClick={() => this.operation()}>
                  <Icon icon="minus-square" /> Minimize
                </span>
              )}
              {this.props.iscollapse && (
                <span onClick={() => this.operation()}>
                  <Icon icon="plus-square" /> Maximize
                </span>
              )}
            </li>
            {this.props.data.idName !== undefined && (
              <a
                href
                onClick={() => this.operation()}
                style={{ textDecoration: "none", color: "#212529" }}
                id={this.props.data.idName}
                download={`${this.props.data.subprojectSelectedName}_${this.props.data.idName}.jpeg`}
              >
                <li>
                  <Icon icon="download" /> Download
                </li>
              </a>
            )}
          </div>
        }
        <Icon
          className="iconWrapper"
          onClick={() => this.operation()}
          icon="ellipsis-v text-muted"
        />
        <div
          style={{
            position: "fixed",
            right: "0",
            top: "0",
            display: this.state.boyHide,
            width: this.state.bodyWidth,
            height: this.state.bodyHeight,
            zIndex: this.state.bodyZindex,
            backgroundColor: this.state.bodyBackground,
          }}
          onClick={() => this.operation()}
        />
      </div>
    );
  }
}

const wrapper = (props) => props.children;

wrapper.header = function ({ togglecollapse, iscollapse }, otherProps) {
  //otherProps = otherProps.children.props;
  return (
    <nav className="navbar">
      <div className="navbar-brand title-header">
        <h4>{otherProps.titleHeader}</h4>
        <span className="d-block text-dark subtitle-header">
          {otherProps.subtitleHeader}
        </span>
      </div>

      <div>
        <UncontrolledDropdown>
          <CustomDropdown
            togglecollapse={togglecollapse}
            iscollapse={iscollapse}
            data={otherProps}
          />
        </UncontrolledDropdown>
      </div>
    </nav>
  );
};

export default wrapper;
