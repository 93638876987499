export const FETCH_ALERT_SITES_START = `[melius][sites] Get alert sites start`;
export const FETCH_ALERT_SITES_SUCCESS =
  `[melius][sites] Get alert sites success`;
export const FETCH_ALERT_SITES_FAIL = `[melius][sites] Get alert sites fail`;

export const FETCH_GROUPER_ACTIVITIES_START =
  `[melius][sites] Get grouper activities start`;
export const FETCH_GROUPER_ACTIVITIES_SUCCESS =
  `[melius][sites] Get grouper activities success`;
export const FETCH_GROUPER_ACTIVITIES_FAIL =
  `[melius][sites] Get grouper activities fail`;

export const FETCH_FORM_SCHEMA_START = `[melius][sites] Get form schema start`;
export const FETCH_FORM_SCHEMA_SUCCESS =
  `[melius][sites] Get form schema success`;
export const FETCH_FORM_SCHEMA_FAIL = `[melius][sites] Get form schema fail`;

export const GROUPER_SELECTED = `[melius][sites] Set value of Grouper selected`;

export const CREATE_NEW_SITE = `[melius][sites] Post new site`;

export const FETCH_FORM_SCHEMA_EDIT_START =
  `[melius][sites] Get form edit schema start`;
export const FETCH_FORM_SCHEMA_EDIT_SUCCESS =
  `[melius][sites] Get form edit schema success`;
export const FETCH_FORM_SCHEMA_EDIT_FAIL =
  `[melius][sites] Get form edit schema fail`;
export const RESET_FORM_SCHEMA_EDIT = `[melius][sites] Reset form edit schema`;

export const FETCH_ACTIVITIES_DOCUMENTS_START =
  `[melius][sites] Get activities documents start`;
export const FETCH_ACTIVITIES_DOCUMENTS_SUCCESS =
  `[melius][sites] Get activities documents success`;
export const FETCH_ACTIVITIES_DOCUMENTS_FAIL =
  `[melius][sites] Get activities documents fail`;
