import {
  fetchDataProjectList,
  fetchSchemaProjectList,
  dataFilterValueProjectList,
  filterByLogicSearch,
  advanceLogicFilters,
  advanceLogicFiltersSearchSSet
} from './actions';

export const projectListActions = {
  fetchDataProjectList,
  fetchSchemaProjectList,
  dataFilterValueProjectList,
  filterByLogicSearch,
  advanceLogicFilters,
  advanceLogicFiltersSearchSSet
};
