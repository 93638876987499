import { http } from "./http";
import { downloaderFiles } from "../utility";

const baseUrl = "/integration/api/customreport";

export const getReports = async (subproject) => {
  return http
    .get(`${baseUrl}/${subproject}/reports_subproject/`)
    .then(({ data }) => data);
};

export const getSubProjectsCustomReport = async () => {
  return http
    .get(`${baseUrl}/subprojects/`)
    .then(({ data }) => data)
    .catch((err) => err);
};
export const importCustomReport = async ({ file, subproject, report }) => {
  const formData = new FormData();
  formData.append("file_upload", file);
  formData.append("subproject", subproject);
  formData.append("report", report);

  return http
    .post(`${baseUrl}/importer/`, formData, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((response) => response.data)
    .catch(({ response }) => Promise.reject(response.data.errors));
};
export const downloadTemplate = async (reportId, subprojectSelectedName) => {
  return http
    .get(`${baseUrl}/${reportId}/exporter/`, {
      responseType: "blob",
    })
    .then((response) => {
      downloaderFiles(response.data, `${subprojectSelectedName}.xlsx`);
      return response;
    })
    .catch((error) => error);
};

const service = {
  getReports,
  importCustomReport,
  downloadTemplate,
  getSubProjectsCustomReport,
};
export default service;
