import moment from 'moment';
import swal from 'sweetalert';

export const configRequestDefault = (queryParams = {}) => {
  return {
    params: {
      source: 'general',
      ...queryParams
    }
  };
};

export const configRequestDetails = (grouper, queryParams = {}) => {
  return {
    params: {
      source: 'group',
      group: grouper,
      ...queryParams
    }
  };
};

export const hashGenerate = () => {
  return `m=${Math.random()
    .toString(36)
    .substr(2, 16)}`;
};

export const sanitizer = value => {
  return value.replace(new RegExp('[0-9-.]', 'g'), '');
};

export const generateDataset = (data, colors, extraPropsDataset) => {
  return data.map((item, index) => {
    const color = colors ? colors[index] : 'transparent';
    return {
      ...item,
      ...extraPropsDataset,
      backgroundColor: color,
      borderColor: color,
      borderWidth: 1,
      hoverBorderColor: color,
      lineTension: 0
    };
  });
};

export const generateDataDashboardOverall = data => {
  const overall = { actual: [], planned: [], labels: [] };
  if (data) {
    data.forEach(item => {
      const { result_actual, result_planed, name } = item;
      overall.actual.push(result_actual);
      overall.planned.push(result_planed);

      function deleteArray(arrayI, position) {
        return arrayI.splice(position, 1);
      }

      function arrayFormat(arrayPrincipal, otra) {
        let nuevoArray = [];
        for (let i = arrayPrincipal.length; i > 0; i--) {
          if (i > 0) {
            if (arrayPrincipal[i - 1].length <= 10) {
              if (arrayPrincipal.length > 1) {
                nuevoArray.push(
                  `${arrayPrincipal[i - 2]} ${arrayPrincipal[i - 1]}`
                );
                deleteArray(
                  arrayPrincipal,
                  arrayPrincipal.indexOf(arrayPrincipal[i - 1])
                );
                deleteArray(
                  arrayPrincipal,
                  arrayPrincipal.indexOf(arrayPrincipal[i - 2])
                );
              } else if (arrayPrincipal.length === 1) {
                nuevoArray.push(arrayPrincipal[i - 1]);
                deleteArray(
                  arrayPrincipal,
                  arrayPrincipal.indexOf(arrayPrincipal[i - 1])
                );
              }

              i = arrayPrincipal.length + 1;

              if (arrayPrincipal.length === 0) {
                break;
              }
            } else if (arrayPrincipal[i - 1].length > 10) {
              nuevoArray.push(arrayPrincipal[i - 1]);
              deleteArray(
                arrayPrincipal,
                arrayPrincipal.indexOf(arrayPrincipal[i - 1])
              );
            }
          }
        }

        return nuevoArray;
      }

      if (name) {
        let arrayInicial = name.split(' ');
        overall.labels.push(arrayFormat(arrayInicial, 1).reverse());
      }
    });
  }
  return overall;
};

export const generateDateRangesProcessWeek = configuration => {
  const {
    all,
    this_month: thisMonth,
    last_month: lastMonth,
    this_quarter: thisQuarter,
    last_quarter: lastQuarter,
    this_year: thisYear,
    last_year: lastYear
  } = configuration;

  return {
    All: [moment(all.start, 'MM-DD-YYYY'), moment(all.end, 'MM-DD-YYYY')],
    'This Month': [
      moment(thisMonth.start, 'MM-DD-YYYY'),
      moment(thisMonth.end, 'MM-DD-YYYY')
    ],
    'Last Month': [
      moment(lastMonth.start, 'MM-DD-YYYY'),
      moment(lastMonth.end, 'MM-DD-YYYY')
    ],
    'This Quarter': [
      moment(thisQuarter.start, 'MM-DD-YYYY'),
      moment(thisQuarter.end, 'MM-DD-YYYY')
    ],
    'Last Quarter': [
      moment(lastQuarter.start, 'MM-DD-YYYY'),
      moment(lastQuarter.end, 'MM-DD-YYYY')
    ],
    'This Year': [
      moment(thisYear.start, 'MM-DD-YYYY'),
      moment(thisYear.end, 'MM-DD-YYYY')
    ],
    'Last Year': [
      moment(lastYear.start, 'MM-DD-YYYY'),
      moment(lastYear.end, 'MM-DD-YYYY')
    ]
  };
};

export const changeHeightLegend = (chart, value = 50) => {
  chart.Legend.prototype.afterFit = function() {
    this.height += value;
  };
};

export const defaultGraphicOptions = () => {
  return {
    responsive: true,
    layout: {
      padding: {
        left: 10,
        right: 10
      }
    },
    legend: {
      display: true,
      labels: {
        usePointStyle: true,
        fontSize: 10,
        padding: 40
      }
    },
    tooltips: {
      cornerRadius: 0,
      yAlign: 'bottom',
      xAlign: 'center'
    },
    scales: {
      xAxes: [
        {
          barPercentage: 1.0,
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ]
    },
    maintainAspectRatio: false
  };
};

export const sliderConfiguration = extraOptions => {
  return {
    className: 'center',
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          className: 'center',
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    ...extraOptions
  };
};

export const generateRowsProjectSite = (
  subproject,
  callbackState,
  callbackRequest,
  extraParams
) => {
  const flattenChildrenSitesRecursively = ({ children, ...rest }) => {
    // if there are children, recursively visit them, otherwise return object
    if (rest.hasOwnProperty('is_rendered') && !rest.is_rendered) {
      return children;
    } else {
      return children
        ? [rest].concat(children.flatMap(flattenChildrenSitesRecursively))
        : [rest];
    }
  };

  subproject &&
    callbackRequest(subproject, extraParams).then(({ data }) => {
      if (!data) {
        swal('Opsssss!', `There are no results for the search`, 'warning');
      }

      callbackState(() => {
        try {
          return data.sites
            .flatMap(flattenChildrenSitesRecursively)
            .map(item => {
              const { columns, ...extraOptions } = item;
              return item.columns.map(child => ({ ...child, extraOptions }));
            })
            .map(content => {
              return content.reduce((instance, row) => {
                const { content, extraOptions, forescat_alert, slug } = row;

                instance[slug] = content;
                instance['extraOptions'] =
                  instance.extraOptions || extraOptions;
                instance.extraOptions['forescats'] =
                  instance.extraOptions['forescats'] || [];

                if (forescat_alert) {
                  instance.extraOptions['forescats'].push({
                    slug,
                    value: content
                  });
                }
                return instance;
              }, {});
            });
        } catch (error) {
          callbackState([]);
        }
      });
    });
};

export const downloaderFiles = (file, filename) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};
