import React from "react";
import { Spinner } from "reactstrap";
const SmallLoading = () => {
  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        color: "var(--zina-1)",
        margin: "7px",
      }}
    >
      <div className="text-center">
        <h1>
          Loading <Spinner type="grow" color="primary" />
        </h1>
      </div>
    </div>
  );
};

export default SmallLoading;
