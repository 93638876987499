import { http } from "./http";

const baseUrlAPI = "/profile/api/advance_logic_filter";

export const getListLogicalFilter = async (id, page = null, search = null) => {
  return http
    .get(
      `${baseUrlAPI}/values/${
        page ? "?page=" + page + "&" : "?"
      }subproject=${id}${search ? "&search=" + search : ""}`
    )
    .then((response) => response)
    .catch((error) => error);
};

export const postSaveLogicalFilter = async (data) => {
  return http
    .post(`${baseUrlAPI}/new/`, data)
    .then((response) => response)
    .catch((error) => error);
};

export const putEditLogicalFilter = async (data) => {
  return http
    .put(`${baseUrlAPI}/${data?.id}/change/`, data)
    .then((response) => response)
    .catch((error) => error);
};

export const deleteLogicalFilter = async (idLogical) => {
  return http
    .delete(`${baseUrlAPI}/${idLogical}`)
    .then((response) => response)
    .catch((error) => error);
};

const listService = {
  getListLogicalFilter,
  postSaveLogicalFilter,
  putEditLogicalFilter,
  deleteLogicalFilter,
};
export default listService;
