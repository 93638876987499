import { updateObject } from "../utility";
import * as actionTypes from "./actionTypes";
const initialState = {
  profile: {
    picture: "",
    gender: "",
    language: "",
  },
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_INFO_USER_PROFILE_START:
      return updateObject(state, action.payload);
    case actionTypes.FETCH_INFO_USER_PROFILE_SUCCESS:
      const {
        loading,
        data: {
          user_profile: { id, picture, gender, prefix, language },
        },
      } = action.payload;
      return updateObject(state, {
        loading,
        profile: { id, picture, gender, prefix, language },
      });
    case actionTypes.FETCH_INFO_USER_PROFILE_FAILED:
      return updateObject(state, action.payload);
    default:
      return state;
  }
};

export default reducer;
