import { useLayoutEffect } from "react"
import { Pagination, Swiper } from "swiper"

Swiper.use([Pagination])

export default function useSwiper() {
  useLayoutEffect(() => {
    new Swiper(".testimonials-slider", {
      speed: 600,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 40,
        },

        1200: {
          slidesPerView: 3,
        },
      },
    })
  }, [])
}
