import { http } from "./http";
import { configRequestDefault, configRequestDetails } from "../utility";

const baseUrlAPI = "/api";

export const getGapAnalysis = async (subprojectId) => {
  return http
    .get(`${baseUrlAPI}/dashboard/${subprojectId}/gap/`, configRequestDefault())
    .then((response) => response.data);
};
export const getGapAnalysisDetail = async (subprojectId, grouper) => {
  return http
    .get(
      `${baseUrlAPI}/dashboard/${subprojectId}/gap/`,
      configRequestDetails(grouper)
    )
    .then((response) => response.data.gaps);
};
export const getGapReasons = async (subprojectId, owner, reason, option) => {
  return http
    .get(`${baseUrlAPI}/dashboard/${subprojectId}/reason_retrieve/`, {
      params: {
        owner,
        reason,
        ...option,
      },
    })
    .then((response) => response.data);
};
export const saveGapReasons = async (values) => {
  return http
    .post("/api/dashboard/gap_reason_update/", values)
    .then((response) => response);
};

const service = {
  getGapAnalysis,
  getGapAnalysisDetail,
  getGapReasons,
  saveGapReasons,
};
export default service;
