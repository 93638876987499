export const FAQData = [
  {
    id: 'faq-content',
    data: [
      {
        title: 'What is ZINA?',
        description:
          'ZINA is a set of tools, best software development practices, and outstanding professionals, namely Doers, aiming to support Nokia to be more productive, efficient and creative by leveraging the potential of all "Nokians" through innovative tools and technologies, giving them time to think and focus on more value added activities.',
      },
      {
        title: 'What is the purpose of ZINA BI?',
        description:
          'ZINA BI works as a central data repository, importing information from several sources such as Sharepoint, SAP, Email, and custom web portals, being able to process it, and deliver summarial reporting, as well as meaningful visual dashboards, either native or connected to MS Power BI.',
      },
    ],
  },
  {
    id: 'faq2-content',
    data: [
      {
        title: 'How does ZINA develop software?',
        description:
          'We create and maintain our software using Agile and Dev-Ops methodologies, for constantly delivering value. We raise requirements through a strong communication to our clients, key and final users, and we analyse them to provide robust and reliable solutions.',
      },
      {
        title: 'I want to know more about how it works',
        description: () => (
          <>
            We have prepared a set of videos that let you go deeper into the
            application, as well as they serve as user documentation in a
            friendly tutorial format. You can watch them{' '}
            <a
              href='https://nokia.sharepoint.com/sites/zina_central/SitePages/ZINA-BI.aspx'
              target='_blank'
              rel='noreferrer'
            >
              {' '}
              by clicking here.
            </a>
          </>
        ),
      },
    ],
  },
];
