import { http } from './http';

const baseUrlAPI = '/rollout/api/projectsite';

export const getSchemaProjectList = async (subprojectId) => {
  return http
    .get(`${baseUrlAPI}/${subprojectId}/schema_table/`)
    .then((response) => response.data);
};
export const getDataProjectList = async (subprojectId, queryParams = {}) => {
  return http
    .post(`${baseUrlAPI}/${subprojectId}/load_data/`, queryParams)
    .then((response) => response.data);
};
export const getAdvanceLogicFilters = async (subprojectId) => {
  return http
    .get(`profile/api/advance_logic_filter/details/?subproject=${subprojectId}`)
    .then((response) => response.data);
};
export const postExportAdvanceLogicFilters = async (idExport) => {
  return http
    .post(`profile/api/advance_logic_filter/${idExport}/export/`)
    .then((response) => response.data);
};
export const deleteLines = async (subprojectId, values) => {
  return http
    .delete(`${baseUrlAPI}/${subprojectId}/delete_row/`, {
      data: { rows: values },
    })
    .then((resp) => resp.data);
};

export const disableLines = async (subprojectId, values) => {
  return http
    .post(`${baseUrlAPI}/${subprojectId}/disable_row/`, { rows: values })
    .then((resp) => resp.data);
};

const updateDateRows = async (subprojectId, data) => {
  return http
    .put(`${baseUrlAPI}/${subprojectId}/bulk_update_date_field/`, data)
    .then((resp) => resp.data);
};

const getDateTypeFieldsUserUpdate = async (subprojectId) => {
  return http
    .get(`${baseUrlAPI}/${subprojectId}/bulk_update_date_field/`)
    .then((resp) => resp?.data);
};

export const deleteAllLines = async (subprojectId) => {
  return http
    .delete(`${baseUrlAPI}/${subprojectId}/delete_data/`)
    .then((resp) => resp.data);
};
export const recoveryLines = async (subprojectId, values) => {
  return http
    .post(`${baseUrlAPI}/${subprojectId}/recovery_row/`, {
      data: { rows: values },
    })
    .then((resp) => resp.data);
};
export const editInline = async (siteId, values) => {
  return http
    .post(`${baseUrlAPI}/${siteId}/edit_inline/`, values)
    .then((resp) => resp.data);
};

export const deleteFilteredDataFromAdvancedSearch = async (
  subprojectId,
  filter,
) => {
  return http
    .delete(`${baseUrlAPI}/${subprojectId}/delete_filtered_data/`, {
      data: filter,
    })
    .then((resp) => resp.data);
};

const service = {
  getSchemaProjectList,
  getDataProjectList,
  getAdvanceLogicFilters,
  postExportAdvanceLogicFilters,
  disableLines,
  deleteLines,
  deleteAllLines,
  recoveryLines,
  editInline,
  deleteFilteredDataFromAdvancedSearch,
  updateDateRows,
  getDateTypeFieldsUserUpdate,
};

export default service;
