import * as actionType from './actionTypes';
import { rolService } from '../../../shared';

//role list

const fetchRolesStart = () => ({
  type: actionType.FETCH_ROLES_START
});

const fetchRolesSuccess = data => ({
  type: actionType.FETCH_ROLES_SUCCESS,
  data
});

const fetchRolesFail = error => ({
  type: actionType.FETCH_ROLES_FAIL,
  error
});

export const fetchRoles = queryParams => {
  return dispatch => {
    dispatch(fetchRolesStart());
    return rolService
      .getRole(queryParams)
      .then(response => dispatch(fetchRolesSuccess(response)))
      .catch(error => dispatch(fetchRolesFail(error)));
  };
};

const fetchSpecificRoleStart = () => ({
  type: actionType.FETCH_SPECIFIC_ROLE_START
});

const fetchSpecificRoleSuccess = data => ({
  type: actionType.FETCH_SPECIFIC_ROLE_SUCCESS,
  data
});

const fetchSpecificRoleFail = error => ({
  type: actionType.FETCH_SPECIFIC_ROLE_FAIL,
  error
});

export const fetchSpecificRole = queryParams => {
  return dispatch => {
    dispatch(fetchSpecificRoleStart());
    return rolService
      .getSpecificRole(queryParams)
      .then(response => dispatch(fetchSpecificRoleSuccess(response)))
      .catch(error => dispatch(fetchSpecificRoleFail(error)));
  };
};
