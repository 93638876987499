import * as actionTypes from './actionTypes';
import { notificationService } from '../../../shared';

const fetchNotificationsStart = () => ({
  type: actionTypes.FETCH_NOTIFICATIONS_START
});

const fetchNotificationsSuccess = data => ({
  type: actionTypes.FETCH_NOTIFICATIONS_SUCCESS,
  data
});

const fetchNotificationsFail = error => ({
  type: actionTypes.FETCH_NOTIFICATIONS_FAIL,
  error
});

export const fetchNotifications = page => {
  return dispatch => {
    dispatch(fetchNotificationsStart());
    notificationService
      .getNotifications(page)
      .then(response => dispatch(fetchNotificationsSuccess(response)))
      .catch(error => dispatch(fetchNotificationsFail(error)));
  };
};

const fetchNotificationsUnreadStart = () => ({
  type: actionTypes.FETCH_NOTIFICATIONS_UNREAD_START
});

const fetchNotificationsUnreadSuccess = data => {
  return {
    type: actionTypes.FETCH_NOTIFICATIONS_UNREAD_SUCCESS,
    payload: {
      notificationsUnread: data
    }
  };
};

const fetchNotificationsUnreadFail = error => ({
  type: actionTypes.FETCH_NOTIFICATIONS_UNREAD_FAIL,
  payload: { error }
});

export const fetchNotificationsUnread = () => dispatch => {
  dispatch(fetchNotificationsUnreadStart());
  notificationService
    .getNotificationUnread()
    .then(response =>
      dispatch(fetchNotificationsUnreadSuccess({count:response.count, results:response.results}))
    )
    .catch(error => dispatch(fetchNotificationsUnreadFail(error)));
};
