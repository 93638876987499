import axios from 'axios';

const baseUrl = process.env.REACT_APP_TMO_API_ENDPOINT_URL;

export async function getDataTMOProject(siteId, smpId) {
  return axios
    .get(`${baseUrl}/all-reports/?site_id=${siteId}&smp_id=${smpId}`)
    .then((response) => response.data);
}

export async function getSiteIDs() {
  return axios.get(`${baseUrl}/search-id/?site_id=`).then((response) => {
    const { data } = response.data;
    const siteIDOptions = data.map(({ site_id }) => ({
      label: site_id,
      value: site_id,
    }));
    return siteIDOptions;
  });
}

export async function getSMPIDs(siteId) {
  return axios
    .get(`${baseUrl}/search-id/?site_id=${siteId}`)
    .then((response) => {
      const { data } = response.data;
      const SMP_ID_DOptions = data.map(({ smp_id }) => ({
        label: smp_id,
        value: smp_id,
      }));
      return SMP_ID_DOptions;
    });
}

export async function downloadSiteIdReport(
  site_id,
  smp_id,
  onPercentCompleted,
) {
  const axiosConfig = {
    params: { site_id, smp_id },
    onDownloadProgress: function (progressEvent) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      onPercentCompleted(percentCompleted);
    },
  };

  return axios
    .get(`${baseUrl}/site_id/download/`, axiosConfig)
    .then(() => {
      const link = document.createElement('a');
      link.href = `${baseUrl}/site_id/download/?site_id=${site_id}&smp_id=${smp_id}`;
      link.click();
    })
    .finally(() => onPercentCompleted(0));
}
