import { http } from "./http";
import { downloaderFiles } from "../utility";

const baseUrl = "/integration/api/update_by_email";

export const getListEmails = async () => {
  return http
    .get(`${baseUrl}/emails/`)
    .then((res) => res.data)
    .catch((err) => err);
};

export const getInitialData = async (subProjectId) => {
  return http
    .get(`${baseUrl}/${subProjectId}/`)
    .then((res) => res.data)
    .catch((err) => err);
};

export const postUpdateByEmailData = async (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((e) => formData.append(e, data[e]));
  return await http
    .post(`${baseUrl}/`, formData, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((res) => res.data)
    .catch((err) => err);
};

export const downloadTemplate = async (
  idSubproject,
  subprojectSelectedName
) => {
  return http
    .get(`${baseUrl}/${idSubproject}/download_template/`, {
      responseType: "blob",
    })
    .then((response) => {
      downloaderFiles(
        response.data,
        `Configuration_Email_${subprojectSelectedName}.xlsx`
      );
      return response;
    })
    .catch((error) => error);
};

const service = {
  getListEmails,
  getInitialData,
  downloadTemplate,
  postUpdateByEmailData,
};

export default service;
