import { projectListService } from '../../../shared';
import * as actionTypes from './actionTypes';

// schema project list
const fetchSchemaProjectListStart = () => ({
  type: actionTypes.FETCH_SCHEMA_PROJECT_LIST_START,
});

const fetchSchemaProjectListSuccess = (data) => ({
  type: actionTypes.FETCH_SCHEMA_PROJECT_LIST_SUCCESS,
  data,
});

const fetchSchemaProjectListFailed = (error) => ({
  type: actionTypes.FETCH_SCHEMA_PROJECT_LIST_FAIL,
  error,
});

export const fetchSchemaProjectList = (projectID) => {
  return async (dispatch) => {
    dispatch(fetchSchemaProjectListStart());
    try {
      const response = await projectListService.getSchemaProjectList(projectID);
      return dispatch(fetchSchemaProjectListSuccess(response));
    } catch (error) {
      return dispatch(fetchSchemaProjectListFailed(error));
    }
  };
};

// data project list
const fetchDataProjectListStart = () => ({
  type: actionTypes.FETCH_DATA_PROJECT_LIST_START,
});

const fetchDataProjectListSuccess = (data) => ({
  type: actionTypes.FETCH_DATA_PROJECT_LIST_SUCCESS,
  data,
});

const fetchDataProjectListFailed = (error) => ({
  type: actionTypes.FETCH_DATA_PROJECT_LIST_FAIL,
  error,
});

export const fetchDataProjectList = (projectID, queryParams) => {
  const key = 'totalRowsDisplayedInProjectList';
  window.localStorage.removeItem(key);
  return async (dispatch) => {
    dispatch(fetchDataProjectListStart());
    try {
      const response = await projectListService.getDataProjectList(
        projectID,
        queryParams,
      );

      window.localStorage.setItem(
        key,
        JSON.stringify({
          isLastPage: response.is_last_page,
          total: response.total.toLocaleString(),
          showing: response.limit * response.page,
        }),
      );

      return dispatch(fetchDataProjectListSuccess(response));
    } catch (error) {
      return dispatch(fetchDataProjectListFailed(error));
    }
  };
};

//Data filter project list
export const dataFilterValueProjectList = (filters) => {
  return {
    type: actionTypes.DATA_FILTER_PROJECT_LIST,
    filters,
  };
};

export const filterByLogicSearch = (filters) => {
  return {
    type: actionTypes.FILTER_LOGIC_SEARCH_PROJECT_LIST,
    filters,
  };
};

const advanceLogicFiltersSearchStart = () => ({
  type: actionTypes.ADVANCE_LOGIC_FILTERS_SAVE_SEARCH_START,
});
const advanceLogicFiltersSearchSuccess = (data) => ({
  type: actionTypes.ADVANCE_LOGIC_FILTERS_SAVE_SEARCH_SUCCESS,
  data,
});
const advanceLogicFiltersSearchSFail = (error) => ({
  type: actionTypes.ADVANCE_LOGIC_FILTERS_SAVE_SEARCH_FAIL,
  error,
});
export const advanceLogicFilters = (subprojectId) => {
  return (dispatch) => {
    dispatch(advanceLogicFiltersSearchStart());
    projectListService
      .getAdvanceLogicFilters(subprojectId)
      .then((response) => {
        dispatch(advanceLogicFiltersSearchSuccess(response));
      })
      .catch((error) => dispatch(advanceLogicFiltersSearchSFail(error)));
  };
};

export const advanceLogicFiltersSearchSSet = (data) => {
  return {
    type: actionTypes.ADVANCE_LOGIC_FILTERS_SAVE_SEARCH_SET,
    data,
  };
};
