import { lazy } from "react";
import NotificationAlert from './notification/notificationAlert.container';
const Login = lazy(() => import("./login/login.container"));
const OIDCLogin = lazy(() => import("./login/oidc_login"));
const Validate = lazy(() => import("./login/validate"));

const Root2 = lazy(() => import("./Root.container"));

const Dashboard = lazy(() => import("./dashboard/dashboard.container"));
const DashboardDetails = lazy(()=> import("./dashboard/dashboard_details"));
const GapAnalysis = lazy(() => import("./gap_analysis/gap_analysis.container"));
const GapAnalysisDetails = lazy(()=> import("./gap_analysis/gap_analysis_details.container"));
const kpisInformation = lazy(()=> import("./kpi/kpi.container"));
const Importer = lazy(()=> import("./importer/importer.container"));
const Exporter = lazy(()=> import("./exporter/exporter.container"));
const NewSite = lazy(()=> import("./project_list/new_site.container"));
const EditSite = lazy(()=> import("./project_list/edit_site.container"));
const ProjectList = lazy(()=> import("./project_list/project_list.container"));
const Site = lazy(()=> import("./project_list/new_site.container"));
const Notification = lazy(()=> import("./notification/notification.container"));
const Profile = lazy(()=> import("./profile/profile.container"));
const UserManagement = lazy(()=> import("./user_management/user_management.container"));
const EditUserManagement = lazy(()=> import("./user_management/edit_user.container"));
const ImportUsers = lazy(()=> import("./user_management/importer_user.container"));
const UserReport = lazy(()=> import("./user_management/users_reports.container"));
const UserActivityResponsible = lazy(()=> import("./user_management/user_activity_responsible.container"));
const Role = lazy(()=> import("./role/role.container"));
const ApprovalUserProjectUser = lazy(()=> import("./user_management/approvalUser_projectUser.container"));
const ApprovalUserMail = lazy(()=> import("./user_management/approval_user_mail.container"));
const ImporterRole = lazy(()=> import("./role/importer_role.container"));
const EditRole = lazy(()=> import("./role/edit_role.container"));
const ResetPassword = lazy(()=> import("./reset_password/reset_password"));
const projectConfiguration = lazy(()=> import("./project_configuration/project_configuration.container"));
const ImporterConfiguration = lazy(()=> import("./project_configuration/importer_configuration.container"));
const GroupsColorSetting = lazy(()=> import("./project_configuration/group_color_settings.container"))
const ConfigurationRules = lazy(()=> import("./configuration_rules/configuration_rules"));
const ImporterRules = lazy(()=> import("./configuration_rules/importer_rules"));
const ClientError = lazy(()=> import("./cliente_error/client_error"));
const ClientMaintenance = lazy(()=> import("./cliente_maintenance/client_maintenance"));
const Landing = lazy(()=> import("./landing/landing"));
const ImportGroupEmails = lazy(()=> import("./importer/import_group_emails.container"));
const ImporterCriteriaGroup = lazy(()=> import("./importer/import_criteria_group.container"));
const ImporterTemplateSyncSMR = lazy(()=> import("./importer/import_template_sync_SMR.container"));
const ImportShortcutKeys = lazy(()=> import("./importer/import_shortcut_keys.container"))
const CustomReport = lazy(()=> import("./importer/custom_report"));
const ImportPeriod = lazy(()=> import("./importer/period_importer.container"));
const LogicalFilterConfig = lazy(()=> import("./logical_filter_config/logical_filter_config.container"));
const NeoImporter = lazy(()=> import("../containers/neoimporter/NeoImporter"));
const NeoImporterResults = lazy(()=> import("../containers/neoimporter/NeoImporterResults"));

const LogsReportContainer = lazy(()=> import("./report/logs_report/logs_report.container"));
const ListReport = lazy(()=> import("./report/configuration_report/list_report.container"));
const WebtoolSynch = lazy(()=> import("./report/webtool_synch/webtool.synch.container"));
const ActivityReport = lazy(() => import("./report/activity_report/activity_report.container"));

const ImporterIntegration = lazy(()=> import("./integrations/importer_integration.container"));
const DispatchLogs = lazy(()=> import("./integrations/dispatch_logs.container"));
const SyncZina = lazy(()=> import("./integrations/sync_zina.container"));
const SyncWebClaro = lazy(()=> import("./integrations/sync_web_claro.container"));

const KpiConfiguration = lazy(()=> import("./kpi/kpi-configuration.container"));

// Assign Team Leader
const AssignTeamLeader = lazy(() => import("./assign_team_leader/assignTeamLeader.container"));

// List Projects
const ListProject = lazy(() => import("./list_project/list_project.container"));

//TMO 
const TmoSiteBased = lazy(()=> import("./tmo/tmo_site_based"))

//Dataset
const Dataset = lazy(()=> import("./dataset/dataset.container"));

export {
  Landing,
  Login,
  OIDCLogin,
  Validate,
  Root2,
  Dashboard,
  DashboardDetails,
  GapAnalysis,
  kpisInformation,
  GapAnalysisDetails,
  Importer,
  NewSite,
  EditSite,
  NeoImporter,
  Exporter,
  ProjectList,
  Site,
  Notification,
  NotificationAlert,
  Profile,
  UserManagement,
  EditUserManagement,
  ImportUsers,
  UserReport,
  UserActivityResponsible,
  Role,
  ImporterRole,
  projectConfiguration,
  ImporterConfiguration,
  GroupsColorSetting,
  ImporterIntegration,
  ConfigurationRules,
  ImporterRules,
  EditRole,
  ResetPassword,
  LogicalFilterConfig,
  ClientError,
  ClientMaintenance,
  ImportGroupEmails,
  ImporterCriteriaGroup,
  ImporterTemplateSyncSMR,
  ImportShortcutKeys,
  ImportPeriod,
  CustomReport,
  NeoImporterResults,
  LogsReportContainer,
  ListReport,
  WebtoolSynch,
  ActivityReport,
  DispatchLogs,
  SyncZina,
  SyncWebClaro,
  KpiConfiguration,
  AssignTeamLeader,
  ListProject,
  TmoSiteBased,
  ApprovalUserProjectUser,
  ApprovalUserMail,
  Dataset
};
