import { http } from "./http";

const baseUrlAPI = "/api";

export const getKPIs = async (subprojectId) => {
  return http
    .get(`${baseUrlAPI}/dashboard/${subprojectId}/kpi/`)
    .then((response) => response.data);
};
const service = {
  getKPIs,
};
export default service;
