import { http } from "./http";

const baseUrlAPI = "/rollout/api";

export const getWorkConfiguration = async (projectId) => {
  return http
    .get(`${baseUrlAPI}/subproject/${projectId}/work_configuration/`)
    .then((response) => response.data);
};

export const putWorkConfiguration = async (projectId, data) => {
  return http
    .put(`${baseUrlAPI}/subproject/${projectId}/work_configuration/`, data)
    .then((response) => response.data);
};

export const getSiteDescription = async (projectId) => {
  return http
    .get(`${baseUrlAPI}/subproject/${projectId}/site_description/`)
    .then((response) => response.data);
};

export const putSiteDescription = async (projectId, data) => {
  return http
    .put(`${baseUrlAPI}/subproject/${projectId}/site_description/`, data)
    .then((response) => response.data);
};

export const getDetailProject = async (id) => {
  return http.get(`${baseUrlAPI}/subproject/${id}/`).then(({ data }) => data);
};

export const updateProjectConfiguration = async (id, value) => {
  return http
    .put(`${baseUrlAPI}/subproject/${id}/`, value)
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data));
};

export const getCountries = async () => {
  return http.get(`${baseUrlAPI}/country/`).then(({ data }) => data);
};

export const getTimezones = async () => {
  return http.get(`${baseUrlAPI}/timezone/`).then(({ data }) => data);
};

export const getKeyUsers = async () => {
  return http.get("/profile/api/users/get_all/").then(({ data }) => data);
};
export const getDateFormat = async () => {
  return http
    .get(`${baseUrlAPI}/subproject/date_format/`)
    .then(({ data }) => data);
};

export const getTimeFormat = async () => {
  return http
    .get(`${baseUrlAPI}/subproject/time_format/`)
    .then(({ data }) => data);
};

export const getType = async () => {
  return http
    .get(`${baseUrlAPI}/subproject/type_of_fields/`)
    .then(({ data }) => data);
};

export const getSiteConfiguration = async (subprojectId) => {
  return http
    .get(`${baseUrlAPI}/subproject/${subprojectId}/site_configuration/`)
    .then((response) => response.data);
};

export const putSiteConfiguration = async (subprojectId, data) => {
  return http
    .put(`${baseUrlAPI}/subproject/${subprojectId}/site_configuration/`, data)
    .then((response) => response.data);
};

export const getFieldList = async (subprojectId, filter = "") => {
  return http
    .get(
      `${baseUrlAPI}/subproject/${subprojectId}/fields_subproject/${
        filter ? `?type=${filter}` : ""
      }`
    )
    .then((response) => response.data);
};

export const getListColorSettings = async (subprojectId) => {
  return http
    .get(`${baseUrlAPI}/get_color/${subprojectId}`)
    .then((response) => response)
    .catch((error) => error);
};

export const editColorSetting = async (codeColor, data) => {
  return http
    .put(`${baseUrlAPI}/update_color/${codeColor}/`, data)
    .then((response) => response)
    .catch((error) => error);
};

export const createDataset = async (data) => {
  return http
    .post(`${baseUrlAPI}/subproject/`, data)
    .then((response) => response)
    .catch((error) => error);
};

export const getExistDataset = async (subprojectId) => {
  return http
    .get(
      `${baseUrlAPI}/subproject/dataset_information/?subproject=${subprojectId}`
    )
    .then((response) => response)
    .catch((error) => error);
};

export const createRolWizard = async (data) => {
  return http
    .post(`/profile/api/rols_wizard/`, data)
    .then((response) => response)
    .catch((error) => error);
};

const service = {
  getWorkConfiguration,
  getSiteDescription,
  getSiteConfiguration,
  putWorkConfiguration,
  putSiteDescription,
  putSiteConfiguration,
  getDetailProject,
  getCountries,
  getTimezones,
  updateProjectConfiguration,
  getKeyUsers,
  getFieldList,
  getListColorSettings,
  editColorSetting,
  getTimeFormat,
  getDateFormat,
  getType,
  createDataset,
  getExistDataset,
  createRolWizard,
};

export default service;
