import React, { useEffect, useRef, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import { usePrevious } from "../../hooks/usePrevious";
import { connect } from "react-redux";
import { notificationsSelectors } from "../../shared/redux/notifications/selectors";
import { notificationsActions } from "../../shared";
import { authSelectors } from "../../shared/redux/auth/selectors";
import { notificationService } from "../../shared/";
import "./notificationAlert.scss";

const NotificationAlert = (props) => {
  const { notifications, fetchNotificationsUnread, user } = props;
  const notificationUnreadRef = useRef();
  const prevNotificationCount = usePrevious(notifications?.count);

  const NotificationMarkRead = (id) => {
    notificationService?.markAsRead(id);
  };

  useEffect(() => {
    fetchNotificationsUnread();
    const TIME_IN_SECONDS = 60000;
    setInterval(() => {
      if (user) {
        fetchNotificationsUnread();
      }
    }, TIME_IN_SECONDS);
  }, [fetchNotificationsUnread, user]);

  const notificationToast = useCallback(() => {
    const toastConfigurations = {
      className: "mt-5",
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    };
    if (notifications.count > prevNotificationCount) {
      if (notificationUnreadRef.current) {
        notificationUnreadRef.current = true;
        toast.info(
          <div>
            You have {notifications.count} unread notifications. Please click
            the <i className="fa fa-bell" />
            {" to see them."}
          </div>,
          toastConfigurations
        );
      } else {
        const [notificationItem = {}] = notifications.results;
        const type = {
          success: "check-circle",
          info: "bell",
          warning: "exclamation-triangle",
          error: "times-circle",
        };
        toast(
          <div
            className="custom-alert-notification"
            onClick={(e) => NotificationMarkRead(notificationItem?.id)}
          >
            <i className={`fa fa-${type[notificationItem.level]}`} />
            {` ${
              notificationItem?.description
                ? notificationItem?.description
                : notificationItem?.verb
            }`}
          </div>,
          {
            ...toastConfigurations,
            type: notificationItem.level,
          }
        );
      }
    }
  }, [notifications, prevNotificationCount]);

  useEffect(() => {
    notificationToast();
  }, [notificationToast]);

  return (
    <div className="notication--alert--container">
      <ToastContainer />
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    notifications: notificationsSelectors.getNotificationsUnread(store),
    user: authSelectors.getUser(store),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchNotificationsUnread: () =>
    dispatch(notificationsActions.fetchNotificationsUnread()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationAlert);
