import * as actionTypes from './actionTypes';
import { gapService } from '../../../shared';

export const fetchGapAnalysisStart = () => {
  return { type: actionTypes.FETCH_GAP_ANALYSIS_START };
};

export const fetchGapAnalysisFailed = error => {
  return {
    type: actionTypes.FETCH_GAP_ANALYSIS_FAIL,
    error
  };
};

export const fetchGapAnalysisSuccess = data => {
  return {
    type: actionTypes.FETCH_GAP_ANALYSIS_SUCCESS,
    data
  };
};

export const fetchGapAnalysis = subprojectId => {
  return dispatch => {
    dispatch(fetchGapAnalysisStart());
    gapService
      .getGapAnalysis(subprojectId)
      .then(response => dispatch(fetchGapAnalysisSuccess(response)))
      .catch(error => dispatch(fetchGapAnalysisFailed(error)));
  };
};

// actions gap details

export const fetchGapAnalysisDetailStart = () => {
  return { type: actionTypes.GAP_ANALYSIS_DETAILS_START };
};

export const fetchGapAnalysisDetailFailed = error => {
  return { type: actionTypes.GAP_ANALYSIS_DETAILS_FAIL, error };
};

export const fetchGapAnalysisDetailSuccess = (grouper, data) => {
  return {
    type: actionTypes.GAP_ANALYSIS_DETAILS_SUCCESS,
    grouper,
    data
  };
};

export const fetchGapDetail = (subprojectId, grouper) => {
  return dispatch => {
    dispatch(fetchGapAnalysisDetailStart());
    gapService
      .getGapAnalysisDetail(subprojectId, grouper)
      .then(response =>
        dispatch(fetchGapAnalysisDetailSuccess(grouper, response))
      )
      .catch(error => dispatch(fetchGapAnalysisDetailFailed(error)));
  };
};

export const updateCardGadReasons = data => ({
  type: actionTypes.UPDATED_CARD_GAP_REASON_INFORMATION,
  payload: { data }
});
