import automatizationImg from '../../assets/img/automatization.svg'
import businessIntelligence from '../../assets/img/business-intelligence.svg'
import dataCentralizationImg from '../../assets/img/data-centralization.svg'
import reportDownloadingImg from '../../assets/img/report-downloading.svg'
import smartOperationImg from '../../assets/img/smart-operation.svg'
import styled from 'styled-components'

const ImageStyle = styled.img`
  @media screen and (max-width: 990px) {
    width: 75%;
  }
`

export function Values() {
  return (
    <section id='about' className='values'>
      <div className='container' data-aos='fade-up'>
        <header className='section-header'>
          <h2>Our Values</h2>
          <p>What ZINA BI can do</p>
        </header>

        <div className='row d-flex justify-content-between'>
          <div className='col-lg-2'>
            <div className='box' data-aos='fade-up' data-aos-delay='200'>
              <ImageStyle
                src={dataCentralizationImg}
                className='img-fluid'
                alt='Data centralization'
              />
              <h3>Data centralization</h3>
            </div>
          </div>

          <div className='col-lg-2 mt-4 mt-lg-0'>
            <div className='box' data-aos='fade-up' data-aos-delay='400'>
              <ImageStyle
                src={automatizationImg}
                className='img-fluid'
                alt='Automatization'
              />
              <h3>Automatization</h3>
            </div>
          </div>

          <div className='col-lg-2 mt-4 mt-lg-0'>
            <div className='box' data-aos='fade-up' data-aos-delay='600'>
              <ImageStyle
                src={smartOperationImg}
                className='img-fluid'
                alt='Smart operations and filtering'
              />
              <h3>Smart operations and filtering</h3>
            </div>
          </div>

          <div className='col-lg-2 mt-4 mt-lg-0'>
            <div className='box' data-aos='fade-up' data-aos-delay='800'>
              <ImageStyle
                src={reportDownloadingImg}
                className='img-fluid'
                alt='Reports downloading'
              />
              <h3>Reports downloading</h3>
            </div>
          </div>

          <div className='col-lg-2 mt-4 mt-lg-0'>
            <div className='box' data-aos='fade-up' data-aos-delay='1000'>
              <ImageStyle
                src={businessIntelligence}
                className='img-fluid'
                alt='Business Intelligence'
              />
              <h3>Business Intelligence</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
