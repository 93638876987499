import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal as RSModal,
} from 'reactstrap';

import logo from '../../../assets/img/zina-bi-logo-positive.svg';

function Modal({ toggle, isOpen, children, ...props }: any) {
  return (
    <RSModal className='login-modal' isOpen={isOpen} toggle={toggle} {...props}>
      <ModalHeader toggle={toggle}>
        <img width='150' height='50' src={logo} alt='logo zina bi' />
      </ModalHeader>
      {children}
    </RSModal>
  );
}

export { Modal, ModalHeader, ModalBody, ModalFooter };
