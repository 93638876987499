/* eslint-disable no-console */
import { QueryClient, QueryClientProvider } from 'react-query'

import { ReactQueryDevtools } from 'react-query/devtools'

const STALE_TIME = 30 * 1000

function createQueryClient() {
  const retry = process.env.NODE_ENV === 'production' ? 3 : false

  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: STALE_TIME,
        retry,
      },
    },
  })
}

const queryClient = createQueryClient()

interface QueryClientProviderProps {
  children: React.ReactNode | React.ReactFragment
}

function WrapperQueryClientProvider({ children }: QueryClientProviderProps) {
  const client = queryClient
  return (
    <QueryClientProvider client={client}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export { WrapperQueryClientProvider, queryClient }
