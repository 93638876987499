import { authTypes } from './types';

const user = JSON.parse(localStorage.getItem('user'));
const token = localStorage.getItem('_tk_');

const emplyInitialState = {
  projectList: [],
  loading: false,
  loggedIn: false,
  isLogout: false,
  user: null,
  dataSaved: null,
  error: null
};

const initialState = token
  ? user
    ? { loggedIn: true, user, error: null }
    : emplyInitialState
  : emplyInitialState;

if (!token) {
  localStorage.removeItem('user');
  localStorage.removeItem('persist:root');
}

const reducerToExport = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.LOGIN: {
      return {
        ...state,
        loggedIn: false
      };
    }

    case authTypes.LOGIN_SUCCESS: {
      const user = action.payload;
      localStorage.setItem('user', JSON.stringify(user));
      return {
        ...state,
        loggedIn: true,
        user
      };
    }
    case authTypes.LOGIN_REFRESH_DATA: {
      const user = action.payload;
      return {
        ...state,
        loggedIn: true,
        user
      };
    }

    case authTypes.LOGIN_FAIL: {
      return {
        ...state,
        loggedIn: false,
        user: null,
        error: action.payload
      };
    }

    case authTypes.LOGOUT: {
      localStorage.removeItem('user');
      localStorage.removeItem('_tk_');
      localStorage.removeItem('_rf_');
      localStorage.removeItem('persist:root');
      return {
        ...state,
        loggedIn: false,
        isLogout: true,
        user: null,
        error: null
      };
    }

    case authTypes.EXPIRED_TOKEN: {
      localStorage.removeItem('user');
      localStorage.removeItem('_tk_');
      return {
        ...state,
        loggedIn: false,
        user: null,
        error: null
      };
    }

    case authTypes.NEW_TOKEN: {
      const { access_token, refresh } = action.payload;
      if (access_token) {
        localStorage.setItem('_tk_', access_token);
      }
      if (refresh) {
        localStorage.setItem('_rf_', refresh);
      }
      return state;
    }

    case authTypes.GET_PROJECT_LIST_OPTIONS_START:
      return {
        ...state,
        loading: true
      };

    case authTypes.GET_PROJECT_LIST_OPTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case authTypes.GET_PROJECT_LIST_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        projectList: action.data
      };

    case authTypes.GET_ACCESS_START:
      return {
        loading: true,
        ...state
      };

    case authTypes.GET_ACCESS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case authTypes.GET_ACCESS_SUCCESS:
      return {
        ...state,
        dataSaved: action.data,
        loading: false
      };

    default: {
      return state;
    }
  }
};

export default reducerToExport;