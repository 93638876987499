import {
  fetchUserManagement,
  fetchUserListPermissions,
  fetchSubprojectAssigned,
  fetchRolesAssignedToSubproject,
  fetchSpecificUser,
  fetchPermissionsBySubproject
} from './action';

export const userManagementActions = {
  fetchUserManagement,
  fetchUserListPermissions,
  fetchSubprojectAssigned,
  fetchRolesAssignedToSubproject,
  fetchSpecificUser,
  fetchPermissionsBySubproject
};
