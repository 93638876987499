// actions dashboard
export const FETCH_SUBPROJECTS_START =
  '[melius][dashboard] Get subprojects start';
export const FETCH_SUBPROJECTS_SUCCESS =
  '[melius][dashboard] Get subprojects success';
export const FETCH_SUBPROJECTS_FAIL =
  '[melius][dashboard] Get subprojects failed';
export const CHANGE_SUBPROJECT = ['[melius][dashboard] Change subproject'];

// actions activities
export const FETCH_ACTIVITIES_START =
  '[melius][dashboard] Get activities start';
export const FETCH_ACTIVITIES_SUCCESS =
  '[melius][dashboard] Get activities success';
export const FETCH_ACTIVITIES_FAIL =
  '[melius][dashboard] Get activities failed';

// actions detail activities
export const FETCH_DETAIL_ACTIVITIES_START =
  '[melius][dashboard] Get detail activities start';
export const FETCH_DETAIL_ACTIVITIES_SUCCESS =
  '[melius][dashboard] Get detail activities success';
export const FETCH_DETAIL_ACTIVITIES_FAIL =
  '[melius][dashboard] Get detail activities failed';

// actions process week
export const FETCH_PROCESS_WEEK_START =
  '[melius][dashboard] Get process week start';
export const FETCH_PROCESS_WEEK_SUCCESS =
  '[melius][dashboard] Get process week success';
export const FETCH_PROCESS_WEEK_FAIL =
  '[melius][dashboard] Get process week fail';
export const FETCH_CONFIGURATION_PROCESS_WEEK_START =
  '[melius][dashboard] Get configuration process week start';
export const FETCH_CONFIGURATION_PROCESS_WEEK_SUCCESS =
  '[melius][dashboard] Get configuration process week success';
export const FETCH_CONFIGURATION_PROCESS_WEEK_FAIL =
  '[melius][dashboard] Get configuration process week fail';

// actions subProcess week
export const FETCH_SUBPROCESS_WEEK_START =
  '[melius][dashboard] Get subProcess week start';
export const FETCH_SUBPROCESS_WEEK_SUCCESS =
  '[melius][dashboard] Get subProcess week success';
export const FETCH_SUBPROCESS_WEEK_FAIL =
  '[melius][dashboard] Get Subprocess week fail';

// action filters
export const FETCH_FILTERS_DASHBOARD_START =
  '[melius][dashboard] Get filters dashboard start';
export const FETCH_FILTERS_DASHBOARD_SUCCESS =
  '[melius][dashboard] Get filters dashboard success';
export const FETCH_FILTERS_DASHBOARD_FAIL =
  '[melius][dashboard] Get filters dashboard failed';
export const SET_FILTERS_DASHBOARD =
  '[melius][dashboard] Set filters dashboard failed';
export const FILTER_BY_YEAR = '[melius][dashboard] Filtering for year';

// project's schema site in dashboard detail
export const FETCH_SCHEMA_PROJECT_SITE_START =
  '[melius][dashboard detail] Get schema project site start';
export const FETCH_SCHEMA_PROJECT_SITE_SUCCESS =
  '[melius][dashboard detail] Get schema project site success';
export const FETCH_SCHEMA_PROJECT_SITE_FAIL =
  '[melius][dashboard detail] Get schema project site failed';

// project's data site in dashboard detail
export const FETCH_DATA_PROJECT_SITE_START =
  '[melius][dashboard detail] Get data project site start';
export const FETCH_DATA_PROJECT_SITE_SUCCESS =
  '[melius][dashboard detail] Get data project site success';
export const FETCH_DATA_PROJECT_SITE_FAIL =
  '[melius][dashboard detail] Get data project site failed';

//SubProcess Filter in dashboard detail
export const FETCH_SUBPROCESS_FILTER_START =
  '[melius][dashboard detail] Get subProcess filter start';
export const FETCH_SUBPROCESS_FILTER_SUCCESS =
  '[melius][dashboard detail] Get subProcess filter success';
export const FETCH_SUBPROCESS_FILTER_FAIL =
  '[melius][dashboard detail] Get subProcess filter fail';
export const SET_SUBPROCESS_FILTER =
  '[melius][dashboard] Set filters dashboard detail failed';
