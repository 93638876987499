import { createSelector } from 'reselect';

const getList = state => state.list;
const getSelectedCpo = state => state.selectedCpo;
const getError = state => state.error;
const getLoading = state => state.loading;
const getLoaded = state => state.loaded;
const getPagination = state => state.pagination;
const getFilters = state => state.filters;
const getReportMessage = state => state.report_message;
const getReportLastResponse = state => state.report_last_response;

export const cpoSelectors = {
  getList,
  getSelectedCpo,
  getError,
  getLoading,
  getLoaded,
  getPagination,
  getFilters,
  getReportMessage,
  getReportLastResponse,
  pages: createSelector(
    getPagination,
    pg => pg.pages
  ),
  actualPage: createSelector(
    getPagination,
    pg => pg.actualPage
  ),
  previousPage: createSelector(
    getPagination,
    pg => pg.previous
  ),
  nextPage: createSelector(
    getPagination,
    pg => pg.next
  )
};
