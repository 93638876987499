import { createSelector } from 'reselect';

const getAllNotifications = createSelector(
  state => state.notification.data,
  notifications => notifications
);

const getNotificationsUnread = createSelector(
  state => state.notification.notificationsUnread,
  value => value
);

export const notificationsSelectors = {
  getAllNotifications,
  getNotificationsUnread
};
