import * as actionTypes from './actionTypes';
import { updateObject } from '../utility';

const initialState = {
  listRol: null,
  specificRole: null,
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ROLES_START:
      return updateObject(state, {
        loading: true,
        listRol: null
      });

    case actionTypes.FETCH_ROLES_SUCCESS:
      return updateObject(state, {
        loading: false,
        listRol: action.data
      });

    case actionTypes.FETCH_ROLES_FAIL:
      return updateObject(state, {
        error: action.error
      });

    case actionTypes.FETCH_SPECIFIC_ROLE_START:
      return updateObject(state, {
        loading: true,
        specificRole: null
      });

    case actionTypes.FETCH_SPECIFIC_ROLE_SUCCESS:
      return updateObject(state, {
        loading: false,
        specificRole: action.data
      });

    case actionTypes.FETCH_SPECIFIC_ROLE_FAIL:
      return updateObject(state, {
        loading: true,
        specificRole: action.error
      });

    default:
      return state;
  }
};

export default reducer;
