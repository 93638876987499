import React, { Component, Fragment } from 'react';
import { Bar } from 'react-chartjs-2';

import './custom_bar.scss';
import {
  changeHeightLegend,
  generateDataset,
  defaultGraphicOptions
} from '../../../shared/utility';

import * as Chart from 'chart.js';

class CustomBar extends Component {
  state = {
    options: defaultGraphicOptions()
  };

  static url = '';

  componentWillMount() {
    Chart.pluginService.register({
      beforeDraw: function(chart, easing) {
        const ctx = chart.chart.ctx;
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, chart.chart.width, chart.chart.height);
      }
    });
  }

  componentDidMount() {
    const { isCustom, extraOptions } = this.props;
    this.setState(state => ({
      ...state,
      scales: extraOptions
    }));
    if (isCustom) {
      const chart = this.refs.chart.chartInstance;
      changeHeightLegend(Chart);
      const container = document.querySelector('.custom-actions');
      container.innerHTML = chart.generateLegend();
      const legendTags = document.querySelectorAll('.custom-actions span');
      legendTags.forEach(legend => this.toggleActions(legend, chart));
      this.forceUpdate();
    }
  }

  toggleActions(legend, chart) {
    legend.addEventListener('click', event => {
      if (legend.classList.contains('extra-actions')) return;

      const current = event.target;
      const actives = document.getElementsByClassName('clicked');
      actives[0].className = actives[0].className.replace('clicked', '');
      current.classList.add('clicked');

      if (current.dataset.id !== '-1') {
        chart.data.datasets.forEach((item, index) => {
          if (current.dataset.id !== index)
            chart.getDatasetMeta(index).hidden = true;
        });
        const chartElement = chart.getDatasetMeta(current.dataset.id);
        chartElement.hidden = !chartElement.hidden;
      } else {
        chart.data.datasets.forEach((item, index) => {
          chart.getDatasetMeta(index).hidden = false;
        });
      }
      chart.update();
    });
  }

  legendCallbackBar = chart => {
    const dataset = chart.data.datasets;
    // const btnExport = `
    //     <span class="btn extra-actions success">Export Chart</span>
    // `;

    const labels = dataset.map(
      (data, index) =>
        `<span class="btn btn-default action" data-id="${index}">
        ${data.label}
       </span>`
    );

    return `
      <div class="grouper col-12">
        <div class="btn-group actions-group" role="group">
          <span class="btn btn-default action clicked" data-id="-1">All</span>
              ${labels.join('')} 
          </div>
      </div>`;
  };

  render() {
    const {
      labels,
      data,
      colors,
      extraPropsDataset,
      extraOptions,
      width,
      height,
      idName
    } = this.props;
    const datasets = generateDataset(data, colors, extraPropsDataset);

    return (
      <Fragment>
        <div className="custom-bar">
          <div className="custom-actions" />
          <Bar
            ref="chart"
            data={{
              labels,
              datasets
            }}
            width={width || 400}
            height={height || 400}
            options={{
              ...this.state.options,
              ...extraOptions,
              legendCallback: this.legendCallbackBar,
              animation: {
                onComplete: function(animation) {
                  if (
                    idName !== undefined ||
                    document.querySelector(`#${idName}`) !== null
                  ) {
                    document
                      .querySelector(`#${idName}`)
                      .setAttribute('href', this.toBase64Image('image/jpeg'));
                  }
                }
              }
            }}
          />
        </div>
      </Fragment>
    );
  }
}

export default CustomBar;
