import 'swiper/swiper-bundle.css';

import SwiperCore, { Navigation, Pagination } from 'swiper/core';

import { testimonialsData } from './data';
import useSwiper from '../../hooks/useSwiper';

SwiperCore.use([Navigation, Pagination]);

interface TestimonialItemProps {
  avatar: string;
  username: string;
  role: string;
  comment: string;
}

function TestimonialItem({
  avatar,
  username,
  comment,
  role,
}: TestimonialItemProps) {
  return (
    <div className='swiper-slide'>
      <div className='testimonial-item'>
        <p>{comment}</p>
        <div className='profile mt-auto'>
          <img
            width='90px'
            src={avatar}
            className='testimonial-img'
            alt={username}
          />
          <h3>{username}</h3>
          <h4>{role}</h4>
        </div>
      </div>
    </div>
  );
}

export function Testimonials() {
  useSwiper();

  return (
    <section
      id='testimonials'
      className='testimonials'
      aria-label='testimonials'
    >
      <div className='container' data-aos='fade-up'>
        <header className='section-header'>
          <h2>Testimonials</h2>
          <p>What they are saying about us</p>
        </header>

        <div
          className='testimonials-slider swiper-container'
          data-aos='fade-up'
          data-aos-delay='200'
        >
          <div className='swiper-wrapper'>
            {testimonialsData.map((testimonial) => (
              <TestimonialItem {...testimonial} />
            ))}
          </div>
          <div className='swiper-pagination'></div>
        </div>
      </div>
    </section>
  );
}
