import AOS from "aos"
import { useLayoutEffect } from "react"

export function useAOS() {
  useLayoutEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    })
  }, [])
}
