import { updateObject } from '../utility';
import * as actionTypes from './actionTypes';

const initialState = {
  data: null,
  loading: false,
  notificationsUnread: {
    count: 0,
    results: [],
  },
  notificationsUnreadError: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NOTIFICATIONS_START:
      return updateObject(state, {
        data: null,
        loading: true,
      });

    case actionTypes.FETCH_NOTIFICATIONS_SUCCESS:
      return updateObject(state, {
        data: action.data,
        loading: false,
      });

    case actionTypes.FETCH_NOTIFICATIONS_FAIL:
      return updateObject(state, {
        error: action.error,
        loading: false,
      });

    case actionTypes.FETCH_NOTIFICATIONS_UNREAD_START:
    case actionTypes.FETCH_NOTIFICATIONS_UNREAD_SUCCESS:
      return updateObject(state, { ...action.payload });

    case actionTypes.FETCH_NOTIFICATIONS_UNREAD_FAIL:
      return updateObject(state, { ...action.payload });

    default:
      return state;
  }
};

export default reducer;
