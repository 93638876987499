import React from 'react';
import Slider from 'react-slick/lib';
import { KPICard } from '../../components';
import { sliderConfiguration } from '../../shared/utility';

const settings = sliderConfiguration({
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        className: 'center',
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 840,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
});

const kpi_list = ({ kpisItems, error }) => {
  const messageError = error && error.response.data.detail;
  return (
    <div className="col-12">
      <Slider {...settings}>
        {kpisItems &&
          kpisItems.map(
            ({ id, type, name, result_avg, result_max, result_min }) => {
              return (
                <div className="col-12" key={id}>
                  <KPICard
                    type={type || 'Activity'}
                    median={result_avg}
                    title={name}
                    max={result_max}
                    min={result_min}
                  />
                </div>
              );
            }
          )}
      </Slider>
      {<span className="text-center text-info d-block">{messageError}</span>}
    </div>
  );
};

export default kpi_list;
