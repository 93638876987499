import * as actionTypes from "./actionTypes";
import { updateObject } from "../utility";

const initialState = {
  schema: null,
  data: null,
  loading: false,
  filterAdvanceSearch: {
    isFilter: false,
    dataFilterValue: [],
    columns: [{ label: "Item" }],
    columnsHeader: ["Item"],
    dataGrid: [
      [{ value: "" }],
      [{ value: "" }],
      [{ value: "" }],
      [{ value: "" }],
      [{ value: "" }],
    ],
  },
  filterLogicSearch: {
    isFilter: false,
    filter: null,
    dataFilterValue: null,
  },
  advanceFilter: {
    data: null,
    selected: "",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SCHEMA_PROJECT_LIST_START:
    case actionTypes.FETCH_DATA_PROJECT_LIST_START:
      return updateObject(state, {
        loading: true,
        data: null,
      });

    case actionTypes.FETCH_SCHEMA_PROJECT_LIST_SUCCESS:
      return updateObject(state, {
        loading: false,
        schema: action.data,
      });

    case actionTypes.FETCH_SCHEMA_PROJECT_LIST_FAIL:
      return updateObject(state, { loading: false, error: action.error });

    // data project list
    case actionTypes.FETCH_DATA_PROJECT_LIST_SUCCESS:
      return updateObject(state, {
        loading: false,
        data: action.data,
      });

    case actionTypes.FETCH_DATA_PROJECT_LIST_FAIL:
      return updateObject(state, {
        loading: false,
        error: action.error,
      });

    case actionTypes.DATA_FILTER_PROJECT_LIST:
      return updateObject(state, {
        filterAdvanceSearch: {
          ...action.filters,
        },
      });

    case actionTypes.FILTER_LOGIC_SEARCH_PROJECT_LIST:
      return updateObject(state, {
        filterLogicSearch: {
          ...action.filters,
        },
      });

    case actionTypes.ADVANCE_LOGIC_FILTERS_SAVE_SEARCH_START:
      return updateObject(state, {
        advanceFilter: null,
      });
    case actionTypes.ADVANCE_LOGIC_FILTERS_SAVE_SEARCH_SUCCESS:
      return updateObject(state, {
        advanceFilter: {
          data: action.data,
          selected: "",
        },
      });
    case actionTypes.ADVANCE_LOGIC_FILTERS_SAVE_SEARCH_FAIL:
      return updateObject(state, {
        advanceFilter: action.error,
      });
    case actionTypes.ADVANCE_LOGIC_FILTERS_SAVE_SEARCH_SET:
      return updateObject(state, {
        advanceFilter: action.data,
      });
    default:
      return state;
  }
};

export default reducer;
