export const FETCH_NOTIFICATIONS_START =
  '[melius][notifications] Get notifications start';
export const FETCH_NOTIFICATIONS_SUCCESS =
  '[melius][notifications] Get notifications success';
export const FETCH_NOTIFICATIONS_FAIL =
  '[melius][notifications] Get notifications fail';

export const FETCH_NOTIFICATIONS_UNREAD_START =
  '[melius][notifications] Get notifications unread start';
export const FETCH_NOTIFICATIONS_UNREAD_SUCCESS =
  '[melius][notifications] Get notifications unread success';
export const FETCH_NOTIFICATIONS_UNREAD_FAIL =
  '[melius][notifications] Get notifications unread failed';
