import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './index.css';

import { Landing } from './landing/pages';
import { Redirect } from 'react-router-dom';
import { WrapperQueryClientProvider } from './shared/components/';
import { connect } from 'react-redux';

function LandingApp({ location, loggedIn }: any) {
  if (loggedIn) {
    if (location.state) {
      return <Redirect to={location.state.from} />;
    }
    return <Redirect to='/' />;
  }

  return (
    <WrapperQueryClientProvider>
      <Landing />
    </WrapperQueryClientProvider>
  );
}

const mapStateToProps = (store: { auth: { loggedIn: any } }) => ({
  loggedIn: store.auth.loggedIn,
});

export default connect(mapStateToProps)(LandingApp);
