import * as actionTypes from './actionTypes';
import { kpiService } from '../../index';

const fetchKPIStart = () => {
  return {
    type: actionTypes.FETCH_KPI_START
  };
};

const fetchKPISuccess = data => {
  return {
    type: actionTypes.FETCH_KPI_SUCCESS,
    data
  };
};

const fetchKPIFail = error => {
  return {
    type: actionTypes.FETCH_KPI_FAIL,
    error
  };
};

const fetchKPI = subprojectId => {
  return dispatch => {
    dispatch(fetchKPIStart());
    kpiService
      .getKPIs(subprojectId)
      .then(response => dispatch(fetchKPISuccess(response)))
      .catch(error => dispatch(fetchKPIFail(error)));
  };
};

export { fetchKPI };
