export const FETCH_SCHEMA_PROJECT_LIST_START = `[melius][project list] Get schema project list start`;
export const FETCH_SCHEMA_PROJECT_LIST_SUCCESS = `[melius][project list] Get schema project list success`;
export const FETCH_SCHEMA_PROJECT_LIST_FAIL = `[melius][project list] Get schema project list failed`;

export const FETCH_DATA_PROJECT_LIST_START = `[melius][project list] Get data project list start`;
export const FETCH_DATA_PROJECT_LIST_SUCCESS = `[melius][project list] Get data project list success`;
export const FETCH_DATA_PROJECT_LIST_FAIL = `[melius][project list] Get data project list failed`;

export const DATA_FILTER_PROJECT_LIST = `[melius][project list] Filter Search by Criteria in project list`;

export const FILTER_LOGIC_SEARCH_PROJECT_LIST = `[melius][project list] Filter Logic Search in project list`;

export const ADVANCE_LOGIC_FILTERS_SAVE_SEARCH_START = `[melius][project list] Get advance logic filters for save search start`;
export const ADVANCE_LOGIC_FILTERS_SAVE_SEARCH_SUCCESS = `[melius][project list] Get advance logic filters for save search success`;
export const ADVANCE_LOGIC_FILTERS_SAVE_SEARCH_FAIL = `[melius][project list] Get advance logic filters for save search fail`;
export const ADVANCE_LOGIC_FILTERS_SAVE_SEARCH_SET =
  `[melius][project list] Set advance logic filters for save search`;
