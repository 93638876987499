import "aos/dist/aos.css";

import {
  BackToTop,
  Count,
  FAQ,
  Footer,
  GetAccess,
  Header,
  Hero,
  Login,
  Testimonials,
  Values,
} from "../components";
import { Modal, ModalBody } from "../../shared/components/Modal";
import { useCallback, useLayoutEffect, useRef, useState } from "react";

import { LandingStyles } from "./styles";
import { useAOS } from "../hooks/useAOS";
import { useDomTitle } from "hooks/useDomTitle";

export function Landing() {
  const [modal, setModal] = useState({ status: false, type: "" });
  const headerRef = useRef<HTMLElement>(null);
  useAOS();
  useDomTitle();

  const toggle = () => {
    setModal({ ...modal, status: !modal.status });
  };

  const handleOptionModal = (type: string) => {
    setModal({ type, status: !modal.status });
  };

  const handleNavbarLinkActive = useCallback(() => {
    const navbarLinks = selectElements("#navbar .scrollto", true);
    const position = window.scrollY + 200;
    navbarLinks.forEach((navbarLink: any) => {
      if (!navbarLink.hash) return;
      const section = selectElements(navbarLink.hash);
      if (!section) return;
      if (
        position >= section.offsetTop &&
        position <= section.offsetTop + section.offsetHeight
      ) {
        navbarLink.classList.add("active");
      } else {
        navbarLink.classList.remove("active");
      }
    });
  }, []);

  const handleToggleBackToTop = useCallback(() => {
    const backToTop = selectElements(".back-to-top");
    if (!backToTop) return;

    if (window.scrollY > 100) {
      backToTop.classList.add("active");
    } else {
      backToTop.classList.remove("active");
    }
  }, []);

  const handleHeaderScrolled = useCallback(() => {
    if (headerRef.current) {
      if (window.scrollY > 100) {
        headerRef.current.classList.add("header-scrolled");
      } else {
        headerRef.current.classList.remove("header-scrolled");
      }
    }
  }, []);

  const handleToDoScroll = () => {
    scrollTo(headerRef?.current, window.location.hash);
  };

  useLayoutEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      if (selectElements(hash)) {
        scrollTo(headerRef?.current, hash);
      }
    }
  }, []);

  useLayoutEffect(() => {
    function eventListeners() {
      handleHeaderScrolled();
      handleToggleBackToTop();
      handleNavbarLinkActive();
    }
    window.addEventListener("load", eventListeners);
    document.addEventListener("scroll", eventListeners);

    return () => {
      window.removeEventListener("load", eventListeners);
      window.removeEventListener("scroll", eventListeners);
    };
  }, [handleHeaderScrolled, handleNavbarLinkActive, handleToggleBackToTop]);

  return (
    <>
      <LandingStyles />
      <Header
        scrollTo={handleToDoScroll}
        onToggle={handleOptionModal}
        headerRef={headerRef}
      />
      <Hero />
      <main id="main">
        <Modal
          size={modal.type === "login" ? "md" : "xl"}
          className="login-modal"
          isOpen={modal.status}
          toggle={toggle}
        >
          <ModalBody>
            {modal.type === "login" ? <Login /> : <GetAccess />}
          </ModalBody>
        </Modal>

        <Values />
        <Count />
        <Testimonials />
        <FAQ />

        <Footer />
        <BackToTop />
      </main>
    </>
  );
}

function selectElements(el: any, all = false) {
  if (!el) return;
  el = el.trim();
  return all
    ? [...(document.querySelectorAll(el) as any)]
    : document.querySelector(el);
}

function scrollTo(header: any, element: string) {
  let offset = header?.offsetHeight ?? 0;
  if (!header?.classList.contains("header-scrolled")) {
    offset -= 10;
  }
  const elementPos = selectElements(element)?.offsetTop ?? 0;
  window.scrollTo({
    top: elementPos - offset,
    behavior: "smooth",
  });
}
