import 'react-toastify/dist/ReactToastify.css';

import {
  ActivityReport,
  ApprovalUserMail,
  ApprovalUserProjectUser,
  AssignTeamLeader,
  ClientError,
  ClientMaintenance,
  ConfigurationRules,
  CustomReport,
  Dashboard,
  DashboardDetails,
  DispatchLogs,
  EditRole,
  EditSite,
  EditUserManagement,
  Exporter,
  GapAnalysis,
  GapAnalysisDetails,
  GroupsColorSetting,
  ImportGroupEmails,
  ImportPeriod,
  ImportShortcutKeys,
  ImportUsers,
  Importer,
  ImporterConfiguration,
  ImporterCriteriaGroup,
  ImporterIntegration,
  ImporterRole,
  ImporterRules,
  ImporterTemplateSyncSMR,
  KpiConfiguration,
  ListProject,
  ListReport,
  LogicalFilterConfig,
  LogsReportContainer,
  NewSite,
  Notification,
  NotificationAlert,
  OIDCLogin,
  Profile,
  ProjectList,
  ResetPassword,
  Role,
  SyncWebClaro,
  SyncZina,
  TmoSiteBased,
  UserActivityResponsible,
  UserManagement,
  UserReport,
  Validate,
  WebtoolSynch,
  kpisInformation,
  projectConfiguration,
  Dataset
} from './';
import { BigLoading, ExtraMenu, NotificationMenuItem } from '../components';
import { BrowserRouter, Link, Redirect, Route, Switch } from 'react-router-dom';
import { Component, Suspense } from 'react';
import { auth, projectListActions, siteActions } from '../shared';
import {
  faBars,
  faDownload,
  faFileDownload,
  faFileImport,
  faFileInvoiceDollar,
  faFilter,
  faHome,
  faPaperPlane,
  faStroopwafel,
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';

import Landing from '../apps';
import { PrivateRoute } from '../components/private_route/private_route';
import { UnsuporterPage } from 'zina-dogi-react';
import { authSelectors } from '../shared/redux/auth/selectors';
import { connect } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import logo from '../assets/imgs/logo-melius.svg';
import { notificationsSelectors } from '../shared/redux/notifications/selectors';
import { subprojectSelectors } from '../shared/redux/dashboard/selectors';

library.add(
  faStroopwafel,
  faFilter,
  faSyncAlt,
  faPaperPlane,
  faHome,
  faDownload,
  faBars,
  faFileInvoiceDollar,
  faFileImport,
  faFileDownload,
);

const minBrowserVersions = {
  chrome: '65',
  firefox: '60',
  edge: '15',
  'edge-chromium': '85',
};

const { detect } = require('detect-browser');
const cmp = require('semver-compare');

class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {
      browser: null,
      supported: false,
    };
  }

  componentDidMount() {
    this.onCheck(detect());
  }

  cleanConfigurationAdvancedSearch(prevProps) {
    const {
      subprojectId,
      FilterValueProjectList,
      FilterLogicSearchValueProjectList,
      setAdvanceFilter,
      setGrouperSelected,
      fetchNotificationsUnread,
    } = this.props;

    if (subprojectId !== prevProps.subprojectId) {
      FilterValueProjectList({
        filter: [],
        isFilter: false,
        columns: [{ label: 'Item' }],
        columnsHeader: ['Item'],
        dataGrid: [[{ value: '' }]],
      });
      FilterLogicSearchValueProjectList({
        isFilter: false,
        filter: null,
        dataFilterValue: null,
      });
      setAdvanceFilter({ data: [], selected: '' });
      setGrouperSelected(null);
      fetchNotificationsUnread();
    }
  }

  render() {
    const { browser, supported } = this.state;
    const year = new Date().getFullYear();
    const { notifications, ...props } = this.props;

    const extraProps = {
      ...props,
      copyright: `© 2018 - ${year} Nokia | Powered by ZINA`,
      extraMenu: <ExtraMenu />,
      notification: {
        page: '/rollout/notifications/',
        size: notifications.count,
        list: notifications.results,
        component: (props) => <NotificationMenuItem {...props} />,
        onClick: () => {},
      },
      userMenuItems: (on_logout) => {
        return (
          <>
            <Link
              to='/rollout/profile/'
              style={{ color: '#212529', fontWeight: '400' }}
              className='dropdown-item btn '
              onClick={() => console.log('profile')}
            >
              <i className='fas fa-user' /> Profile
            </Link>

            <button
              className='dropdown-item btn'
              onClick={() => props.logout()}
            >
              <i className='fas fas fa-power-off' /> Logout
            </button>
          </>
        );
      },
    };

    return (
      <div className='h-100'>
        {!supported && browser && <UnsuporterPage browser={browser} />}
        {supported && (
          <div>
            <NotificationAlert />
            <BrowserRouter>
              <Suspense fallback={<BigLoading />}>
                <Switch>
                  <Route exact path='/login/' component={Landing} />
                  <Route exact path='/landing/' component={Landing} />
                  <Route
                    exact
                    path='/approval_user_project_lineManager/:token'
                    component={ApprovalUserMail}
                  />
                  <Route
                    key='oidc_login'
                    exact
                    path='/login-oidc/'
                    component={(props) => <OIDCLogin {...props} />}
                  />
                  <Route
                    key='oidc_validate'
                    exact
                    path='/login/validate/'
                    component={(props) => <Validate {...props} />}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/rollout/configuration/logical-filter/'
                    component={LogicalFilterConfig}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/rollout/import-emails-by-status/'
                    component={ImportGroupEmails}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/rollout/import-period/'
                    component={ImportPeriod}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/rollout/importer/shortcut/'
                    component={ImportShortcutKeys}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/rollout/import-criteria-group/'
                    component={ImporterCriteriaGroup}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/rollout/import-template-sync-smr/'
                    component={ImporterTemplateSyncSMR}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/integration/import-email-configurations/'
                    component={ImporterIntegration}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/integration/sync-dispatch-logs/'
                    component={DispatchLogs}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/integration/sync-zina/'
                    component={SyncZina}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/integration/claro-sync/'
                    component={SyncWebClaro}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/rollout/custom-report/'
                    component={CustomReport}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/rollout/logs_general/'
                    component={LogsReportContainer}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/rollout/list_report/'
                    component={ListReport}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/rollout/monitor_webtool_synch/'
                    component={WebtoolSynch}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/rollout/activity/report/'
                    component={ActivityReport}
                  />
                  <Route
                    path='/profile/reset/password/:uidb64/:token/'
                    component={ResetPassword}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions=''
                    path='/'
                    component={Dashboard}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions=''
                    path='/dashboard/'
                    component={Dashboard}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions=''
                    path={`/dashboard/details/:grouper/:nameActivity`}
                    component={DashboardDetails}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions=''
                    path='/dashboard/gap/'
                    component={GapAnalysis}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions=''
                    path='/dashboard/gap/:grouper/:title'
                    component={GapAnalysisDetails}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions=''
                    path='/kpi/'
                    component={kpisInformation}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/rollout/configuration/kpi-configuration/'
                    component={KpiConfiguration}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions=''
                    path='/rollout/importer/'
                    component={Importer}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions=''
                    path='/rollout/export/'
                    component={Exporter}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions=''
                    path='/rollout/project-list/new-site/'
                    component={NewSite}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions=''
                    path='/rollout/project-list/edit-site/:siteId'
                    component={EditSite}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions=''
                    path='/rollout/project-list/'
                    component={ProjectList}
                  />
                  {props?.generalConfiguration?.dashboard_consolidated ? (
                    <PrivateRoute
                      extraProps={extraProps}
                      exact
                      keyPermissions=''
                      path='/rollout/tmo/'
                      component={TmoSiteBased}
                    />
                  ) : (
                    ''
                  )}
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions=''
                    path='/rollout/notifications/'
                    component={Notification}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions=''
                    path='/rollout/dataset/'
                    component={Dataset}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions=''
                    path='/rollout/profile/'
                    component={Profile}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/profile/user-management/edit-user/:userId/'
                    component={EditUserManagement}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/profile/user-management/'
                    component={UserManagement}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/profile/user-management/imports'
                    component={ImportUsers}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/profile/user-management/reports'
                    component={UserReport}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/profile/user-management/responsible'
                    component={UserActivityResponsible}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/profile/role'
                    component={Role}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/profile/role/importer'
                    component={ImporterRole}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/profile/role/edit/:rolId'
                    component={EditRole}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/profile/user-management/user_project_approval'
                    component={ApprovalUserProjectUser}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/rollout/project/configuration/'
                    component={projectConfiguration}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/rollout/project/color-setting/'
                    component={GroupsColorSetting}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/rollout/importer/backbone/'
                    component={ImporterConfiguration}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/rollout/project/list/'
                    component={ListProject}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/rollout/configuration/rules/'
                    component={ConfigurationRules}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/rollout/configuration/rules_import/'
                    component={ImporterRules}
                  />
                  <PrivateRoute
                    extraProps={extraProps}
                    exact
                    keyPermissions='admin_views'
                    path='/rollout/assign-team-leader/'
                    component={AssignTeamLeader}
                  />
                  <Route
                    extraProps={extraProps}
                    exact
                    path='/not-found/:typeError/'
                    component={ClientError}
                  />
                  <Route
                    extraProps={extraProps}
                    exact
                    path='/maintenance/'
                    component={ClientMaintenance}
                  />
                  <Redirect to='/' />
                </Switch>
              </Suspense>
            </BrowserRouter>
          </div>
        )}
      </div>
    );
  }

  onCheck = (browser) => {
    let supported = false;
    if (browser) {
      if (minBrowserVersions[browser.name]) {
        const version = minBrowserVersions[browser.name];
        const diff = cmp(browser.version, version);
        if (diff >= 0) {
          supported = true;
        }
      }
    }
    this.setState({ browser, supported });
  };
}

const mapStateToProps = (store) => {
  return {
    user: authSelectors.getUser(store),
    subprojectId: subprojectSelectors.getSubprojectSelected(store),
    notifications: notificationsSelectors.getNotificationsUnread(store),
    logo,
    generalConfiguration: subprojectSelectors.getGeneralConfiguration(store),
    config: subprojectSelectors.getOptionsMenuAllowed(store),
    userHasProjectAssigned: subprojectSelectors.userHasProjectAssigned(store),
    isRolloutProject: subprojectSelectors.isRolloutProject(store),
    hasPermissionDashboardView:
      subprojectSelectors.hasPermissionDashboardView(store),
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch({ type: 'RESET' });
    dispatch(auth.actions.logOut());
  },
  FilterValueProjectList: (filter) => {
    dispatch(projectListActions.dataFilterValueProjectList(filter));
  },
  FilterLogicSearchValueProjectList: (filter) => {
    dispatch(projectListActions.filterByLogicSearch(filter));
  },
  setAdvanceFilter: (advanceFilter) => {
    dispatch(projectListActions.advanceLogicFiltersSearchSSet(advanceFilter));
  },
  setGrouperSelected: (idGrouper) => {
    dispatch(siteActions.gropuerSelected(idGrouper));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Root);
