import {
  getCustomerTeamsByProject,
  getProjects,
  getRolesByProject,
} from '../services'

import { ID } from '../types'
import { useQuery } from 'react-query'

function useGetProjects() {
  return useQuery('project-access', getProjects)
}

function useGetRolesByProject(id: ID) {
  return useQuery(['roles-access', id], () => getRolesByProject(id), {
    enabled: Boolean(id),
  })
}

function useGetCustomerTeamsByProject(id: ID) {
  return useQuery(
    ['customer-teams-access', id],
    () => getCustomerTeamsByProject(id),
    {
      enabled: Boolean(id),
    }
  )
}

export function useGetAccess(id: ID) {
  const projects = useGetProjects()
  const roles = useGetRolesByProject(id)
  const customerTeams = useGetCustomerTeamsByProject(id)
  return { projects, roles, customerTeams }
}
