import { http } from "./http";

export const importSiteConfiguration = async (file_upload, subProjectId) => {
  const formData = new FormData();
  formData.append("file_upload", file_upload);
  return await http
    .post(`rollout/api/backbone/${subProjectId}/importer/`, formData, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => error);
};
export const downloadTemplateSiteConfiguration = async (
  idSubProject,
  nameSubProject
) => {
  return http
    .get(`rollout/api/backbone/${idSubProject}/download_template/`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `site_configuration_${nameSubProject}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => error);
};

const service = {
  importSiteConfiguration,
  downloadTemplateSiteConfiguration,
};

export default service;
