import { updateObject } from '../utility';
import * as actionTypes from './actionTypes';

const initialState = {
  subprojectItems: [],
  loading: false,
  error: null,
  subprojectSelected: null,
  subprojectSelectedName: null,
  generalConfiguration: {
    is_rollout: false,
    parent_disable_color: null,
    forecast_alert: null,
    fixed_column: false,
    advance_search: false,
    advance_logic_search: false,
    powerbi_config: false,
    is_inventory: false,
    powerbi_url: '',
    bulk_update_date_field: false,
  },
  userConfiguration: {
    allow_sync_smr: false,
    allow_delete_data: false,
    one_delete_data: false,
    one_recovery_data: false,
    dashboard_color_editable: false,
    disabled_line: false,
    force_update_line: false,
    create_line: false,
    export_inventory_template: false,
    export_data_in_csv: false,
    allow_delete_filtered_data: false,
  },
  yearOptions: null,
  activities: [],
  activity_details: [],
  processWeek: null,
  subProcessWeek: null,
  configurationProcessWeek: null,
  filterFields: null,
  grouperFilter: null,
  projectSite: {
    schema: null,
    data: null,
  },
  filtersApplied: {
    yearFilter: '',
  },
  keep_filters: [],
  userHasProjectAssigned: true,
  filter_period: false,
};

// actions dashboard
const fetchSubprojectStart = (state) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const fetchSubprojectsSuccess = (state, action) => {
  const userHasProjectAssigned = action.data.length > 0;

  const equalSize = action.data.length === state.subprojectItems.length;
  const subprojectsSelected = action.data.find(
    (subproject) => subproject.id === +state.subprojectSelected,
  );

  if (state.subprojectSelected && equalSize) {
    if (subprojectsSelected) {
      const {
        id,
        name,
        years,
        general_configuration,
        last_modified,
        user_configuration,
        filter_period,
        keep_filters,
      } = subprojectsSelected;
      return updateObject(state, {
        subprojectItems: action.data,
        subprojectSelected: id,
        subprojectSelectedName: name,
        subprojectSelectedLastModified: last_modified,
        generalConfiguration: general_configuration,
        userConfiguration: user_configuration,
        yearOptions: years,
        loading: false,
        userHasProjectAssigned,
        filter_period,
        keep_filters,
      });
    }
  } else {
    if (action.data.length === 0) {
      return updateObject(state, {
        subprojectItems: action.data,
        loading: false,
        error: null,
        subprojectSelected: null,
        subprojectSelectedName: null,
        generalConfiguration: {
          is_rollout: false,
          parent_disable_color: null,
          forecast_alert: null,
          fixed_column: false,
          advance_search: false,
          advance_logic_search: false,
          powerbi_config: false,
          is_inventory: false,
          powerbi_url: '',
        },
        userHasProjectAssigned,
      });
    } else {
      const {
        id,
        name,
        general_configuration,
        user_configuration,
        years,
        last_modified,
        filter_period,
        keep_filters,
      } = action.data[0];
      return updateObject(state, {
        subprojectItems: action.data,
        subprojectSelected: id,
        subprojectSelectedName: name,
        subprojectSelectedLastModified: last_modified,
        generalConfiguration: general_configuration,
        userConfiguration: user_configuration,
        yearOptions: years,
        loading: false,
        userHasProjectAssigned,
        filter_period,
        keep_filters,
      });
    }
  }
};

const fetchSubprojectsFailed = (state, action) => {
  return updateObject(state, {
    subprojectItems: action.error.message,
    loading: false,
    subprojectSelected: null,
    subprojectSelectedName: null,
    generalConfiguration: {
      is_rollout: false,
      parent_disable_color: null,
      forecast_alert: null,
      fixed_column: false,
      advance_search: false,
      advance_logic_search: false,
      powerbi_config: false,
      is_inventory: false,
      powerbi_url: '',
    },
    error: action.error,
    userHasProjectAssigned: false,
  });
};

const changeSubprojectSelected = (state, action) => {
  const {
    name,
    general_configuration,
    user_configuration,
    years,
    filter_period,
    keep_filters,
  } = state.subprojectItems.find(
    (subproject) => subproject.id === +action.subprojectId,
  );

  return updateObject(state, {
    subprojectSelected: action.subprojectId,
    subprojectSelectedName: name,
    yearOptions: years,
    generalConfiguration: general_configuration,
    userConfiguration: user_configuration,
    filter_period,
    keep_filters,
  });
};

// actions activities
const fetchActivitiesStart = (state) => updateObject(state, { loading: true });

const fetchActivitiesSuccess = (state, action) => {
  return updateObject(state, {
    activities: action.data,
    loading: false,
  });
};

export const fetchActivitiesFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    activities: [],
  });
};

// details activity
const fetchActivityDetailsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    activity_details: action.data,
  });
};

// set filter by year for update dashboard
const setFilterByYear = (state, action) => {
  return updateObject(state, {
    filtersApplied: {
      ...state.filtersApplied,
      yearFilter: action.year,
    },
  });
};

// process week
const fetchProcessWeekSuccess = (state, action) => {
  return updateObject(state, {
    processWeek: action.data,
    loading: false,
  });
};

const fetchSubProcessWeekSuccess = (state, action) => {
  return updateObject(state, {
    subProcessWeek: action.data,
    loading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SUBPROJECTS_START:
      return fetchSubprojectStart(state, action);

    case actionTypes.FETCH_SUBPROJECTS_SUCCESS:
      return fetchSubprojectsSuccess(state, action);

    case actionTypes.FETCH_SUBPROJECTS_FAIL:
      return fetchSubprojectsFailed(state, action);

    case actionTypes.CHANGE_SUBPROJECT:
      return changeSubprojectSelected(state, action);

    // actions activities
    case actionTypes.FETCH_ACTIVITIES_START:
      return fetchActivitiesStart(state, action);

    case actionTypes.FETCH_ACTIVITIES_SUCCESS:
      return fetchActivitiesSuccess(state, action);

    case actionTypes.FETCH_ACTIVITIES_FAIL:
      return fetchActivitiesFailed(state, action);

    // details activity
    case actionTypes.FETCH_DETAIL_ACTIVITIES_START:
      return updateObject(state, { loading: true, error: null });

    case actionTypes.FETCH_DETAIL_ACTIVITIES_SUCCESS:
      return fetchActivityDetailsSuccess(state, action);

    case actionTypes.FETCH_DETAIL_ACTIVITIES_FAIL:
      return updateObject(state, { error: action.error, loading: false });

    // process week
    case actionTypes.FETCH_PROCESS_WEEK_START:
      return updateObject(state, {
        loading: true,
        error: null,
        processWeek: null,
      });

    case actionTypes.FETCH_PROCESS_WEEK_SUCCESS:
      return fetchProcessWeekSuccess(state, action);

    case actionTypes.FETCH_PROCESS_WEEK_FAIL:
      return updateObject(state, { error: action.error, loading: false });

    case actionTypes.FETCH_CONFIGURATION_PROCESS_WEEK_START:
      return updateObject(state, { configurationProcessWeek: null });

    case actionTypes.FETCH_CONFIGURATION_PROCESS_WEEK_SUCCESS:
      return updateObject(state, { configurationProcessWeek: action.data });

    case actionTypes.FETCH_CONFIGURATION_PROCESS_WEEK_FAIL:
      return updateObject(state, { error: action.error });

    // subProcess Week
    case actionTypes.FETCH_SUBPROCESS_WEEK_START:
      return updateObject(state, {
        loading: true,
        error: null,
        subProcessWeek: null,
      });

    case actionTypes.FETCH_SUBPROCESS_WEEK_SUCCESS:
      return fetchSubProcessWeekSuccess(state, action);

    case actionTypes.FETCH_SUBPROCESS_WEEK_FAIL:
      return updateObject(state, { error: action.error, loading: false });

    // filters
    case actionTypes.FILTER_BY_YEAR:
      return setFilterByYear(state, action);

    case actionTypes.FETCH_FILTERS_DASHBOARD_START:
    case actionTypes.FETCH_FILTERS_DASHBOARD_SUCCESS:
      return updateObject(state, {
        filterFields: action.data && action.data,
      });

    case actionTypes.SET_FILTERS_DASHBOARD:
      return updateObject(state, {
        filterFields: action.filters,
      });

    case actionTypes.FETCH_FILTERS_DASHBOARD_FAIL:
      return updateObject(state, { error: action.error });

    // project site
    case actionTypes.FETCH_SCHEMA_PROJECT_SITE_START:
      return updateObject(state, {
        loading: true,
        projectSite: {
          ...state.projectSite,
          schema: null,
        },
      });

    case actionTypes.FETCH_DATA_PROJECT_SITE_START:
      return updateObject(state, {
        loading: true,
        projectSite: {
          ...state.projectSite,
          data: null,
        },
      });

    case actionTypes.FETCH_SCHEMA_PROJECT_SITE_SUCCESS:
      return updateObject(state, {
        loading: false,
        projectSite: {
          ...state.projectSite,
          schema: action.data,
        },
      });

    case actionTypes.FETCH_SCHEMA_PROJECT_SITE_FAIL:
    case actionTypes.FETCH_DATA_PROJECT_SITE_FAIL:
      return updateObject(state, { error: action.error, loading: false });

    case actionTypes.FETCH_DATA_PROJECT_SITE_SUCCESS:
      return updateObject(state, {
        loading: false,
        projectSite: {
          ...state.projectSite,
          data: action.data,
        },
      });

    case actionTypes.FETCH_SUBPROCESS_FILTER_START:
      return updateObject(state, {
        grouperFilter: null,
      });

    case actionTypes.FETCH_SUBPROCESS_FILTER_SUCCESS:
      return updateObject(state, {
        grouperFilter: action.data,
      });

    case actionTypes.FETCH_SUBPROCESS_FILTER_FAIL:
      return updateObject(state, {
        error: action.error,
      });

    case actionTypes.SET_SUBPROCESS_FILTER:
      return updateObject(state, {
        grouperFilter: action.filters,
      });

    default:
      return state;
  }
};

export default reducer;
