import React from 'react';
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  CustomInput,
  FormGroup
} from 'reactstrap';
import logo from '../../../assets/imgs/logo-melius.svg';
import { Bar } from 'react-chartjs-2';

export default function ProjectGraphics({ isModalActive, onShowModal }) {
  return (
    <Modal
      isOpen={isModalActive}
      toggle={onShowModal}
      className=""
      centered
      size="lg"
    >
      <ModalHeader
        style={{ background: 'var(--melius-primary)', color: '#fff' }}
        toggle={onShowModal}
        close={
          <button className="close text-white" onClick={onShowModal}>
            &times;
          </button>
        }
      >
        <img src={logo} alt="logo zina bi" width={110} />
      </ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-end">
          <Col sm={6}>
            <FormGroup inline>
              <CustomInput type="select" id="conditions">
                <option value="">Order by region</option>
                <option value="">Sales by region</option>
                <option value="">Order by country</option>
              </CustomInput>
            </FormGroup>
          </Col>
        </div>
        <Bar data={{}} />
      </ModalBody>
    </Modal>
  );
}
