import { config } from '../../../menu.config';
import { createSelector } from 'reselect';

const getAuthUser = createSelector(
  (state) => state.auth.user,
  (currentUser) => currentUser,
);

const getSubprojectSelected = createSelector(
  (state) => state.subproject.subprojectSelected,
  (subproject) => +subproject,
);

const getSubprojectSelectedName = createSelector(
  (state) => state.subproject.subprojectSelectedName,
  (name) => name,
);

const getSubprojects = createSelector(
  (state) => state.subproject.subprojectItems,
  (subprojects) => subprojects,
);

const isSubprojectLoading = (state) => state.subproject.loading;

const getKeepFilters = createSelector(
  (state) => state.subproject.keep_filters,
  (filters) => filters,
);

const getGeneralConfiguration = createSelector(
  (state) => state.subproject.generalConfiguration,
  (generalConfigurations) => generalConfigurations,
);

const isBulkUpdateDateField = createSelector(
  getGeneralConfiguration,
  ({ bulk_update_date_field }) => bulk_update_date_field,
);

const isInventoryProject = createSelector(
  getGeneralConfiguration,
  ({ is_inventory }) => is_inventory,
);

const isRolloutProject = createSelector(
  getGeneralConfiguration,
  ({ is_rollout }) => is_rollout,
);

const hasPermissionDashboardView = createSelector(
  getGeneralConfiguration,
  ({ dashboard_views }) => dashboard_views,
);

const getParentDisableColor = createSelector(
  getGeneralConfiguration,
  ({ parent_disable_color }) => parent_disable_color,
);

const getForecastAlertColor = createSelector(
  getGeneralConfiguration,
  ({ forecast_alert }) => forecast_alert,
);

const isFixedColumn = createSelector(
  getGeneralConfiguration,
  ({ fixed_column }) => fixed_column,
);

const isFilterAdvanceSearch = createSelector(
  getGeneralConfiguration,
  ({ advance_search }) => advance_search,
);

const isFilterAdvanceLogicSearch = createSelector(
  getGeneralConfiguration,
  ({ advance_logic_search }) => advance_logic_search,
);

const getUserConfiguration = createSelector(
  (state) => state.subproject.userConfiguration,
  (userConfiguration) => userConfiguration,
);

const isAllowDeleteFilteredData = createSelector(
  getUserConfiguration,
  (userConfiguration) => userConfiguration.allow_delete_filtered_data,
);

const getExportCSV = createSelector(
  getUserConfiguration,
  (userConfiguration) => userConfiguration.export_data_in_csv,
);

const isExportInventoryTemplate = createSelector(
  getUserConfiguration,
  (userConfiguration) => userConfiguration.export_inventory_template,
);

const isAllowDisableLines = createSelector(
  getUserConfiguration,
  (userConfig) => userConfig?.disabled_line,
);

const isAllowSyncSMR = createSelector(
  getUserConfiguration,
  (userConfiguration) => userConfiguration.allow_sync_smr,
);

const isExportDisabledLineActive = createSelector(
  getUserConfiguration,
  (userConfig) => userConfig.export_disabled_site,
);

const isLineRecoveryActive = createSelector(
  getUserConfiguration,
  (userConfig) => userConfig.one_recovery_data,
);

const isPowerBIConfigured = createSelector(
  getGeneralConfiguration,
  (config) => config.powerbi_config,
);

const getPowerBIURL = createSelector(
  getGeneralConfiguration,
  (config) => config.powerbi_url,
);

const isLogTableDetail = createSelector(
  getGeneralConfiguration,
  (config) => config.log_table_detail,
);

const getProcessWeek = (state) => state.subproject.processWeek;
const getSubProcessWeek = (state) => state.subproject.subProcessWeek;

const labelProcessWeek = (labels) => (labels ? labels.number_week : []);
const dataProcessWeek = (processWeekData) =>
  processWeekData ? processWeekData.data : [];

// process week
const getProcessWeekData = createSelector(getProcessWeek, dataProcessWeek);

const getProcessWeekLabels = createSelector(getProcessWeek, labelProcessWeek);

// process week details
const getProcessWeekDetailLabels = createSelector(
  getSubProcessWeek,
  labelProcessWeek,
);

const getProcessWeekDetailData = createSelector(
  getSubProcessWeek,
  dataProcessWeek,
);

const validatePermissions = (user) => {
  if (
    user?.groups &&
    !user?.is_superuser &&
    !(
      user?.groups.filter(
        (item) => item?.name === 'ADMIN' || item?.name === 'ADMON',
      ).length > 0
    )
  ) {
    return false;
  }
  return true;
};

const getOptionsMenuAllowed = createSelector(
  getGeneralConfiguration,
  getAuthUser,
  (generalConfig, user) => {
    const DEFAULT_MENU_OPTIONS = {
      icon: '',
      name: '',
      key: '',
      children: [],
    };

    const routes = config.map((pathConfig) => {
      const { key } = pathConfig;
      const { dashboard_consolidated } = generalConfig;
      const { is_staff, is_superuser } = user || {};

      function hasDashboardEnabled({
        dashboard_consolidated,
        powerbi_config,
        dashboard_views,
      }) {
        return dashboard_consolidated || powerbi_config || dashboard_views;
      }

      generalConfig.admin_views = validatePermissions(user);

      if (key === 'dashboard_views' && !hasDashboardEnabled(generalConfig)) {
        return DEFAULT_MENU_OPTIONS;
      }

      if (key === 'admin_views' && !(is_superuser || is_staff)) {
        return DEFAULT_MENU_OPTIONS;
      }

      if (!dashboard_consolidated) {
        const { children: dashboardRoutes } = pathConfig;

        return {
          ...pathConfig,
          children: dashboardRoutes?.filter(
            (route) => route.name !== 'Site-Based Dashboard',
          ),
        };
      }

      return pathConfig;
    });
    return routes;
  },
);

const getLastModified = ({ subproject }) =>
  subproject.subprojectSelectedLastModified;

const userHasProjectAssigned = createSelector(
  (state) => state.subproject.userHasProjectAssigned,
  (hasProject) => hasProject,
);

const hasPeriodFilters = createSelector(
  (state) => state.subproject.filter_period,
  (periodFilter) => periodFilter,
);

export const subprojectSelectors = {
  getAuthUser,
  getSubprojectSelected,
  getSubprojectSelectedName,
  getSubprojects,
  getUserConfiguration,
  getOptionsMenuAllowed,
  isSubprojectLoading,
  getParentDisableColor,
  getForecastAlertColor,
  isFixedColumn,
  getLastModified,
  isFilterAdvanceSearch,
  isFilterAdvanceLogicSearch,
  getGeneralConfiguration,
  getProcessWeekData,
  getProcessWeekLabels,
  getProcessWeekDetailLabels,
  getProcessWeekDetailData,
  isPowerBIConfigured,
  getPowerBIURL,
  isLineRecoveryActive,
  isLogTableDetail,
  isExportDisabledLineActive,
  isAllowSyncSMR,
  isInventoryProject,
  isExportInventoryTemplate,
  userHasProjectAssigned,
  hasPeriodFilters,
  getExportCSV,
  isRolloutProject,
  getKeepFilters,
  hasPermissionDashboardView,
  isAllowDeleteFilteredData,
  isAllowDisableLines,
  isBulkUpdateDateField,
};
