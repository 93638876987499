import styled from 'styled-components'

export const Container = styled.div`
  padding-bottom: 1em;
  font-family: 'Nokia Pure Headline Light';
  color: var(--landing-font-color);

  .nokia-title,
  .nokia-subtitle,
  .nokia-text,
  .form-label {
    color: var(--btn-font-color);
  }

  .nokia-title {
    font-size: 24px;
  }

  .nokia-subtitle {
    font-size: 18px;
  }

  .nokia-text {
    font-size: 14px;
  }

  .required {
    color: red;
  }

  .form-label {
    font-size: 0.9em;
    font-weight: bold;
  }

  .go-back-login {
    color: var(--btn-landing-main-color);
    text-decoration: underline;
    cursor: pointer;
  }

  .login-icon {
    margin-right: 0.3em;
  }

  .btn-external-login {
    background-color: white;
    border: 2px solid #337ab6;
    color: #337ab6;

    &:hover,
    &:active {
      background-color: #e6f4ff;
      color: #337ab6;
    }
  }
`
