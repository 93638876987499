import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import dashboard from "../../shared/redux/dashboard";
import "./extra_menu.scss";
import { subprojectSelectors } from "../../shared/redux/dashboard/selectors";
import SearchSelect from "./search_select";

const ExtraMenu = (props) => {
  const {
    fetchSubprojects,
    subprojects,
    changeProject,
    loading,
    subprojectSelected,
  } = props;

  const previousSubproject = useRef();

  useEffect(() => {
    if (+previousSubproject.current !== subprojectSelected) {
      previousSubproject.current = subprojectSelected;
      fetchSubprojects();
    }
  }, [subprojectSelected, fetchSubprojects]);
  const format = () => {
    return subprojects && typeof subprojects !='string'
      ? subprojects.map((v) => {
          return {
            value: v.id,
            label: v.name,
          };
        })
      : [];
  };

  return (
    <div className="form-group extra-menu">
      <SearchSelect
        loading={loading}
        listItems={format()}
        itemSelected={subprojectSelected}
        onChangeValue={changeProject}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    subprojects: subprojectSelectors.getSubprojects(state),
    subprojectSelected: subprojectSelectors.getSubprojectSelected(state),
    loading: subprojectSelectors.isSubprojectLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSubprojects: () => dispatch(dashboard.fetchSubprojects()),
    changeProject: (id) => {
      dispatch(dashboard.changeSubprojectSelected(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtraMenu);
