import { http } from "./http";

const baseUrlAPI = "/profile/api";

export const getUserManagement = async (queryParams = {}) => {
  return http
    .get(`${baseUrlAPI}/users/`, { params: queryParams })
    .then((response) => response.data);
};
export const getSpecificUser = async (idUser) => {
  return http
    .get(`${baseUrlAPI}/users/${idUser}/`)
    .then((response) => response.data);
};
export const putSpecificUser = async (idUser, data) => {
  return http
    .put(`${baseUrlAPI}/users/${idUser}/`, data)
    .then((response) => response.data);
};
export const getListPermissions = async () => {
  return http
    .get(`${baseUrlAPI}/users/list_permissions/`)
    .then((response) => response.data);
};
export const postNewUser = async (data) => {
  return http
    .post(`${baseUrlAPI}/users/`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });
};
export const getProjectsUser = async (idUser) => {
  return http
    .get(`${baseUrlAPI}/userprojects/${idUser}/approved/`)
    .then((response) => response?.data);
};
export const importUsers = async (file_upload, domain, subProjectId) => {
  const formData = new FormData();
  formData.append("file_upload", file_upload);
  formData.append("domain", domain);
  return await http
    .post(
      `profile/api/users/${subProjectId}/creation_user_importer/`,
      formData,
      {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => error);
};
export const downloadTemplateUsers = async (idSubProject, nameSubProject) => {
  return http
    .get(
      `${baseUrlAPI}/users/${idSubProject}/download_creation_user_template_importer/`,
      {
        responseType: "blob",
      }
    )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `user_configuration_${nameSubProject}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      return response;
    })
    .catch((error) => error);
};
export const downloadTemplateQuadrille = async (
  idSubProject,
  nameSubProject
) => {
  return http
    .get(
      `${baseUrlAPI}/quadrille_leader_project/${idSubProject}/download_creation_user_template_importer/`,
      {
        responseType: "blob",
      }
    )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `quadrille_leader_template_${nameSubProject}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      return response;
    })
    .catch((error) => console.log(error));
};
export const importQuadrille = async (file_upload, domain, subProjectId) => {
  const formData = new FormData();
  formData.append("file_upload", file_upload);
  formData.append("domain", domain);
  return await http
    .post(
      `${baseUrlAPI}/quadrille_leader_project/${subProjectId}/quadrille_leader_importer/`,
      formData,
      {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }
    )
    .then((response) => {
      return response.data;
    });
};
export const getSubprojectAssignToUser = async (userId) => {
  return http
    .get(`${baseUrlAPI}/users/${userId}/projects/`)
    .then((resp) => resp.data);
};
export const assignProjectsToUser = async (userId, values) => {
  return http
    .post(`${baseUrlAPI}/users/${userId}/assignsubproject/`, values)
    .then((resp) => resp.data);
};
export const getRolesSubproject = async (subprojectId) => {
  return http
    .get(`rollout/api/subproject/${subprojectId}/rols/`)
    .then((resp) => resp.data);
};
export const getRoleSavedInSubproject = async (userId, subprojectId) => {
  return http
    .get(`${baseUrlAPI}/userprojects/${userId}/rol/?subproject=${subprojectId}`)
    .then((resp) => resp.data);
};
export const saveRolesSubproject = async (userId, values) => {
  return http
    .post(`${baseUrlAPI}/userprojects/${userId}/rol/`, values)
    .then((resp) => resp.data);
};
export const deleteRoleAssignedToSubproject = async (userId, values) => {
  return http
    .delete(`${baseUrlAPI}/userprojects/${userId}/rol/`, { params: values })
    .then((resp) => resp.data);
};
export const getPermissionsBySubproject = async (userId, subprojectId) => {
  return http
    .get(`${baseUrlAPI}/userprojects/${userId}/`, {
      params: { subproject: subprojectId },
    })
    .then((resp) => resp.data);
};
export const updateUserFields = async (userId, values) => {
  return http
    .put(`${baseUrlAPI}/userprojects/${userId}/`, values)
    .then((resp) => resp.data);
};
const service = {
  getUserManagement,
  getSpecificUser,
  putSpecificUser,
  getListPermissions,
  postNewUser,
  importUsers,
  downloadTemplateUsers,
  downloadTemplateQuadrille,
  importQuadrille,
  getSubprojectAssignToUser,
  assignProjectsToUser,
  getRolesSubproject,
  getRoleSavedInSubproject,
  saveRolesSubproject,
  deleteRoleAssignedToSubproject,
  getPermissionsBySubproject,
  updateUserFields,
  getProjectsUser,
};

export default service;
