export const authTypes = {
  LOGIN: '[zina_flow][auth] LOGIN',
  LOGIN_SUCCESS: '[zina_flow][auth] LOGIN_SUCCESS',
  LOGIN_REFRESH_DATA: '[zina_flow][auth] LOGIN_REFRESH_DATA',
  LOGIN_FAIL: '[zina_flow][auth] LOGIN_FAIL',
  NEW_TOKEN: '[zina_flow][auth] new token',
  REFRESH_TOKEN: '[zina_flow][auth] refresh token',
  EXPIRED_TOKEN: '[zina_flow][auth] expired token',
  LOGOUT: '[zina_flow][auth] LOGOUT',

  GET_ACCESS_START: '[melius][get_access] start ',
  GET_ACCESS_SUCCESS: '[melius][get_access] success',
  GET_ACCESS_FAIL: '[melius][get_access] failed',

  GET_PROJECT_LIST_OPTIONS_START: '[melius][get_access] project list start',
  GET_PROJECT_LIST_OPTIONS_SUCCESS: '[melius][get_access] project list success',
  GET_PROJECT_LIST_OPTIONS_FAIL: '[melius][get_access] project list failed'
};
