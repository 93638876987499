import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

//import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';

import { persistor, store } from './shared/redux/store';

import App from './containers/Root.container';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';

const { NODE_ENV, REACT_APP_GA_TRACKING_ID } = process.env;

window.gtag = function () {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);
};

const app = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

if (NODE_ENV === 'production') {
  if (REACT_APP_GA_TRACKING_ID && REACT_APP_GA_TRACKING_ID !== '') {
    window.gtag('js', new Date());
    window.gtag('config', REACT_APP_GA_TRACKING_ID);
  }
  /*if (REACT_APP_SENTRY_DSN && REACT_APP_SENTRY_DSN !== '') {
    Sentry.init({ dsn: REACT_APP_SENTRY_DSN });
  }*/
  console.warn = () => {};
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister();
} else {
  serviceWorker.unregister();
}
