import { http } from "./http";

export const getID = async (cpoNumber) => {
  return await http
    .get(`/india/obj/cpo/${cpoNumber}/`)
    .then((response) => response.data);
};
export const createOetIn = async (cpo, circle, project) => {
  return await http
    .post("/india/oet/", {
      customer_purchase_order: cpo,
      circle,
      project,
    })
    .then((response) => response.data);
};
export const getOetInDetail = async (oet_id) => {
  return await http
    .get(`/india/oet/${oet_id}/`)
    .then((response) => response.data);
};
export const passToProjectOet = async (oet_id, status) => {
  return await http
    .put(`/india/oet/${oet_id}/`, { status })
    .then((response) => response.data);
};
export const updateOET = async (oet_id, body) => {
  return await http
    .put(`/india/oet/${oet_id}/`, body)
    .then((response) => response.data);
};
export const updateOETItems = async (oet_id, oet) => {
  return await http
    .put(`/india/oet/${oet_id}/`, { ...oet })
    .then((response) => response.data);
};
export const getOETSites = async (oet_id, run_id) => {
  return await http
    .get(`/india/site/`, {
      params: {
        oet: oet_id,
        ...(run_id ? { run: run_id } : {}),
      },
    })
    .then((response) => response.data.results);
};
export const getSSOTIOET = async (oet_id, run_id) => {
  return await http
    .get(
      `/india/site_service_order_template_item/?oet=${oet_id}${
        run_id ? `&run=${run_id}` : ""
      }`
    )
    .then((response) => response.data.results);
};
export const download = async (run_id, oet) => {
  return http
    .get(`/india/export_oet/${run_id}/`, {
      responseType: "blob",
    })
    .then((response) => {
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `OET - ${oet.id} ${
            oet.name ? `- ${oet.name}` : ""
          } - run ${run_id}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      }
    });
};
export const getRunList = async (oetID) => {
  return await http
    .get(`/india/run/`, {
      params: {
        oet: oetID,
      },
    })
    .then((response) => response.data.results);
};
export const createRun = async (oet_id, oet_item) => {
  return await http
    .post(`/india/run/`, {
      oet: oet_id,
      oet_item,
    })
    .then((response) => response.data);
};
export const getRunDetail = async (run_id) => {
  return await http
    .get(`/india/run/${run_id}/`)
    .then((response) => response.data);
};
export const updateRun = async (run_id, values) => {
  return await http
    .patch(`/india/run/${run_id}/`, { ...values })
    .then((response) => response.data);
};

const service = {
  getID,
  createOetIn,
  getOetInDetail,
  passToProjectOet,
  updateOET,
  updateOETItems,
  getOETSites,
  getSSOTIOET,
  download,
  getRunList,
  createRun,
  getRunDetail,
  updateRun,
};
export default service;
