export const config = [
  {
    icon: 'chart-line',
    name: 'Dashboards',
    key: 'dashboard_views',
    children: [
      {
        link: '/',
        name: 'Overview',
      },
      {
        link: '/rollout/tmo/',
        name: 'Site-Based Dashboard',
      },
      {
        link: '/dashboard/gap/',
        name: 'GAP Analysis',
      },
      {
        link: '/kpi/',
        name: 'KPI Info',
      },
    ],
  },
  {
    icon: 'tasks',
    name: 'Projects',
    key: 'rollout_views',
    children: [
      {
        link: '/rollout/project-list/',
        name: 'Datasets',
      },
      {
        link: '/rollout/importer/',
        name: 'Import Data',
      },
      {
        link: '/rollout/export/',
        name: 'Export Data',
      },
    ],
  },
  /* {
    icon: 'donate',
    name: 'CBL',
    key: 'cbl_views',
    children: [
      {
        link: '/',
        name: 'List CBL',
      },
      {
        link: '/',
        name: 'Currency Importer',
      },
    ],
  }, */
  {
    icon: 'user-cog',
    name: 'Users Management',
    key: 'admin_views',
    children: [
      {
        link: '/profile/user-management',
        name: 'Users List',
      },
      {
        link: '/profile/user-management/imports',
        name: 'Import Users',
      },
      {
        link: '/profile/role',
        name: 'Roles List',
      },
      {
        link: '/profile/role/importer',
        name: 'Import Roles',
      },
    ],
  },
  {
    icon: 'sliders-h',
    name: 'Datasets Settings',
    key: 'admin_views',
    children: [
      {
        link: '/rollout/project/configuration/',
        name: 'Project Configuration',
      },
      {
        link: '/rollout/importer/backbone/',
        name: 'Configuration Importer',
      },
      {
        link: '/rollout/configuration/rules/',
        name: 'Configuration Rules',
      },
      {
        link: '/rollout/configuration/rules_import/',
        name: 'Importer Rules',
      },
      {
        link: '/rollout/custom-report/',
        name: 'Custom Report',
      },
      {
        link: '/rollout/configuration/logical-filter/',
        name: 'Stored Logic Searches',
      },
      {
        link: '/rollout/dataset/',
        name: 'Create Dataset',
      },
    ],
  },
  {
    icon: 'file-download',
    name: 'Sheet Setup',
    key: 'admin_views',
    children: [
      {
        link: '/profile/user-management/responsible',
        name: 'Responsible Users',
      },
      {
        link: '/profile/user-management/reports',
        name: 'Reports',
      },
      {
        link: '/profile/user-management/user_project_approval',
        name: 'User Project Approval',
      },
      {
        link: '/rollout/project/list/',
        name: 'List Projects',
      },

      {
        link: '/rollout/project/color-setting/',
        name: 'Groups Color Settings',
      },

      {
        link: '/rollout/import-emails-by-status/',
        name: 'Import Emails by Status',
      },
      {
        link: '/rollout/import-criteria-group/',
        name: 'Importer Criteria Group',
      },
      {
        link: '/rollout/import-template-sync-smr/',
        name: 'Template Sync SMR',
      },
      {
        link: '/rollout/importer/shortcut/',
        name: 'Shortcut Keys',
      },

      {
        link: '/rollout/activity/report/',
        name: 'Activity Reports',
      },
      {
        link: '/rollout/import-period/',
        name: 'Period Importer',
      },
      {
        link: '/integration/import-email-configurations/',
        name: 'Update by email',
      },
      {
        link: '/rollout/configuration/kpi-configuration/',
        name: 'KPI Configuration',
      },
      {
        link: '/rollout/logs_general/',
        name: 'Logs Report',
      },
      {
        link: '/rollout/list_report/',
        name: 'List Report',
      },
      {
        link: '/rollout/monitor_webtool_synch',
        name: 'Webtool Synch Logs',
      },
      {
        link: '/integration/sync-dispatch-logs/',
        name: 'Dispatch Logs',
      },
      {
        link: '/integration/sync-zina/',
        name: 'Sync Zina',
      },
      {
        link: '/integration/claro-sync/',
        name: 'Sync Web Claro',
      },
      {
        link: '/rollout/assign-team-leader/',
        name: 'Team Leader',
      },
    ],
  },
  {
    icon: 'hands-helping',
    name: 'Help',
    key: 'help_view',
    link: '#',
    onClick: () => {
      window.open(
        'https://nokia.sharepoint.com/sites/zina_central/SitePages/ZINA-BI.aspx',
        '_blank',
      );
    },
  },
];
