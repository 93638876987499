import * as actionTypes from './actionTypes';
import { dashboardService } from '../../../shared';

// actions subjects
const subprojectsStart = () => ({
  type: actionTypes.FETCH_SUBPROJECTS_START
});

const subprojectsFail = error => {
  return {
    type: actionTypes.FETCH_SUBPROJECTS_FAIL,
    error
  };
};

const subprojectsSuccess = ({ response, state }) => {
  return {
    type: actionTypes.FETCH_SUBPROJECTS_SUCCESS,
    data: response
  };
};

export const fetchSubprojects = () => {
  return (dispatch, getState) => {
    dispatch(subprojectsStart());
    dashboardService
      .getSubprojects()
      .then(response =>
        dispatch(subprojectsSuccess({ response, state: getState() }))
      )
      .catch(error => {
        dispatch(subprojectsFail(error));
      });
  };
};

export const changeSubprojectSelected = subprojectId => {
  return {
    type: actionTypes.CHANGE_SUBPROJECT,
    subprojectId
  };
};

// actions activities
const fetchActivitiesStart = () => {
  return {
    type: actionTypes.FETCH_ACTIVITIES_START
  };
};

const fetchActivitiesSuccess = data => {
  return {
    type: actionTypes.FETCH_ACTIVITIES_SUCCESS,
    data
  };
};

const fetchActivitiesFailed = error => {
  return {
    type: actionTypes.FETCH_ACTIVITIES_FAIL,
    error
  };
};

export const fetchActivities = (subproject, queryParams) => {
  return dispatch => {
    dispatch(fetchActivitiesStart());
    dashboardService
      .getActivities(subproject, queryParams)
      .then(response => dispatch(fetchActivitiesSuccess(response)))
      .catch(error => dispatch(fetchActivitiesFailed(error)));
  };
};

// actions detail activities
const fetchActivityDetailStart = () => ({
  type: actionTypes.FETCH_DETAIL_ACTIVITIES_START
});

const fetchActivityDetailSuccess = data => {
  return {
    type: actionTypes.FETCH_DETAIL_ACTIVITIES_SUCCESS,
    data
  };
};

const fetchActivityDetailFailed = error => {
  return {
    type: actionTypes.FETCH_DETAIL_ACTIVITIES_FAIL,
    error
  };
};

export const fetchActivitiesDetail = (
  subprojectId,
  grouper,
  queryParams = {}
) => {
  return dispatch => {
    dispatch(fetchActivityDetailStart());
    dashboardService
      .getDetailActivity(subprojectId, grouper, queryParams)
      .then(response => dispatch(fetchActivityDetailSuccess(response)))
      .catch(error => dispatch(fetchActivityDetailFailed(error)));
  };
};

// actions rollout process week
const fetchProcessWeekStart = () => ({
  type: actionTypes.FETCH_PROCESS_WEEK_START
});

const fetchProcessWeekSuccess = data => {
  return {
    type: actionTypes.FETCH_PROCESS_WEEK_SUCCESS,
    data
  };
};

const fetchProcessWeekFailed = error => ({
  type: actionTypes.FETCH_PROCESS_WEEK_FAIL,
  error
});

export const fetchProcessWeek = (subprojectId, queryParams = {}) => {
  return dispatch => {
    dispatch(fetchProcessWeekStart());
    dashboardService
      .getProcessWeek(subprojectId, queryParams)
      .then(response => dispatch(fetchProcessWeekSuccess(response)))
      .catch(error => dispatch(fetchProcessWeekFailed(error)));
  };
};
// SubProcess Week

const fetchSubProcessWeekStart = () => ({
  type: actionTypes.FETCH_SUBPROCESS_WEEK_START
});

const fetchSubProcessWeekSuccess = data => {
  return {
    type: actionTypes.FETCH_SUBPROCESS_WEEK_SUCCESS,
    data
  };
};

const fetchSubProcessWeekFailed = error => ({
  type: actionTypes.FETCH_SUBPROCESS_WEEK_FAIL,
  error
});

export const fetchSubProcessWeek = (subprojectId, queryParams = {}) => {
  return dispatch => {
    dispatch(fetchSubProcessWeekStart());
    dashboardService
      .getSubProcessWeek(subprojectId, queryParams)
      .then(response => dispatch(fetchSubProcessWeekSuccess(response)))
      .catch(error => dispatch(fetchSubProcessWeekFailed(error)));
  };
};

const fetchConfigurationProcessWeekSuccess = data => ({
  type: actionTypes.FETCH_CONFIGURATION_PROCESS_WEEK_SUCCESS,
  data
});

const fetchConfigurationProcessWeekFailed = error => ({
  type: actionTypes.FETCH_CONFIGURATION_PROCESS_WEEK_FAIL,
  error
});

export const fetchConfigurationProcessWeek = subprojectId => {
  return dispatch => {
    dispatch({ type: actionTypes.FETCH_CONFIGURATION_PROCESS_WEEK_START });
    dashboardService
      .getConfigurationProcessWeek(subprojectId)
      .then(response =>
        dispatch(fetchConfigurationProcessWeekSuccess(response))
      )
      .catch(error => fetchConfigurationProcessWeekFailed(error));
  };
};

// actions filters
export const setFilteredYear = year => ({
  type: actionTypes.FILTER_BY_YEAR,
  year
});

const fetchFiltersDashboardStart = () => ({
  type: actionTypes.FETCH_FILTERS_DASHBOARD_START
});

const fetchFiltersDashboardSuccess = data => ({
  type: actionTypes.FETCH_FILTERS_DASHBOARD_SUCCESS,
  data
});

const fetchFiltersDashboardFailed = error => ({
  type: actionTypes.FETCH_FILTERS_DASHBOARD_FAIL,
  error
});

export const setFiltersDashboard = filters => ({
  type: actionTypes.SET_FILTERS_DASHBOARD,
  filters
});
export const fetchFiltersDashboard = subprojectId => {
  return dispatch => {
    dispatch(fetchFiltersDashboardStart());
    dashboardService
      .getFiltersDashboard(subprojectId)
      .then(response => dispatch(fetchFiltersDashboardSuccess(response)))
      .catch(error => dispatch(fetchFiltersDashboardFailed(error)));
  };
};

// dashboard detail project site
const fetchSchemaProjectSiteStart = () => ({
  type: actionTypes.FETCH_SCHEMA_PROJECT_SITE_START
});

const fetchSchemaProjectSiteSuccess = data => {
  return { type: actionTypes.FETCH_SCHEMA_PROJECT_SITE_SUCCESS, data };
};

const fetchSchemaProjectSiteFailed = error => {
  return { type: actionTypes.FETCH_SCHEMA_PROJECT_SITE_FAIL, error };
};

export const fetchSchemaProjectSite = (subprojectId, grouper) => {
  return dispatch => {
    dispatch(fetchSchemaProjectSiteStart());
    return dashboardService
      .getSchemaProjectSite(subprojectId, grouper)
      .then(response => dispatch(fetchSchemaProjectSiteSuccess(response)))
      .catch(error => dispatch(fetchSchemaProjectSiteFailed(error)));
  };
};

const fetchDataProjectSiteStart = () => ({
  type: actionTypes.FETCH_DATA_PROJECT_SITE_START
});

const fetchDataProjectSiteSuccess = data => {
  return { type: actionTypes.FETCH_DATA_PROJECT_SITE_SUCCESS, data };
};

const fetchDataProjectSiteFailed = error => ({
  type: actionTypes.FETCH_DATA_PROJECT_SITE_FAIL,
  error
});

export const fetchDataProjectSite = (subprojectId, grouper) => {
  return dispatch => {
    dispatch(fetchDataProjectSiteStart());
    return dashboardService
      .getDataProjectSite(subprojectId, grouper)
      .then(response => dispatch(fetchDataProjectSiteSuccess(response)))
      .catch(error => dispatch(fetchDataProjectSiteFailed(error)));
  };
};

const fetchSubProcessFilterStart = () => ({
  type: actionTypes.FETCH_SUBPROCESS_FILTER_START
});

const fetchSubProcessFilterSuccess = data => {
  return {
    type: actionTypes.FETCH_SUBPROCESS_FILTER_SUCCESS,
    data
  };
};

const fetchSubProcessFilterFail = error => {
  return {
    type: actionTypes.FETCH_SUBPROCESS_FILTER_FAIL,
    error
  };
};

export const fetchSubProcessFilter = idGrouper => {
  return dispatch => {
    dispatch(fetchSubProcessFilterStart());
    dashboardService
      .getSubProcessFilters(idGrouper)
      .then(response => dispatch(fetchSubProcessFilterSuccess(response)))
      .catch(error => dispatch(fetchSubProcessFilterFail(error)));
  };
};

export const setSubProcessFiltersDashboardDetail = filters => ({
  type: actionTypes.SET_SUBPROCESS_FILTER,
  filters
});
