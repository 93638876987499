import * as actionTypes from './actionTypes';
import { siteService } from '../../../shared';

//Site alert
const fetchAlertSiteStart = () => ({
  type: actionTypes.FETCH_ALERT_SITES_START
});

const fetchAlertSiteSucccess = data => ({
  type: actionTypes.FETCH_ALERT_SITES_SUCCESS,
  data
});

const fetchAlertSiteFail = error => ({
  type: actionTypes.FETCH_ALERT_SITES_FAIL,
  error
});

export const fetchAlertSite = (idSite, subproject) => {
  return dispatch => {
    dispatch(fetchAlertSiteStart());
    siteService
      .getAlertSite(idSite, subproject)
      .then(response => dispatch(fetchAlertSiteSucccess(response)))
      .catch(error => dispatch(fetchAlertSiteFail(error)));
  };
};

//Grouper activities
const fetchGrouperActivitiesStart = () => ({
  type: actionTypes.FETCH_GROUPER_ACTIVITIES_START
});

const fetchGrouperActivitiesSuccess = data => ({
  type: actionTypes.FETCH_GROUPER_ACTIVITIES_SUCCESS,
  data
});

const fetchGrouperActivitiesFail = error => ({
  type: actionTypes.FETCH_GROUPER_ACTIVITIES_FAIL,
  error
});

export const fetchGrouperActivities = idSubproject => {
  return dispatch => {
    dispatch(fetchGrouperActivitiesStart());
    siteService
      .getGrouperActivities(idSubproject)
      .then(response => dispatch(fetchGrouperActivitiesSuccess(response)))
      .catch(error => dispatch(fetchGrouperActivitiesFail(error)));
  };
};

//Form schema
const fetchFormSchemaStart = () => ({
  type: actionTypes.FETCH_FORM_SCHEMA_START
});

const fetchFormSchemaSuccess = data => ({
  type: actionTypes.FETCH_FORM_SCHEMA_SUCCESS,
  data
});

const fetchFormSchemaFaill = error => ({
  type: actionTypes.FETCH_FORM_SCHEMA_FAIL,
  error
});

export const fetchFormSchema = idSubproject => {
  return dispatch => {
    dispatch(fetchFormSchemaStart());
    siteService
      .getFormSchema(idSubproject)
      .then(response => dispatch(fetchFormSchemaSuccess(response)))
      .catch(error => dispatch(fetchFormSchemaFaill(error)));
  };
};

export const gropuerSelected = data => {
  return {
    type: actionTypes.GROUPER_SELECTED,
    data
  };
};

export const creteNewSite = (id, data) => {
  return dispatch => {
    siteService.postCreateSite(id, data);
  };
};

//Form schema Edit
const fetchFormSchemaEditStart = () => ({
  type: actionTypes.FETCH_FORM_SCHEMA_EDIT_START
});

const fetchFormSchemaEditSuccess = data => ({
  type: actionTypes.FETCH_FORM_SCHEMA_EDIT_SUCCESS,
  data
});

const fetchFormSchemaEditFaill = error => ({
  type: actionTypes.FETCH_FORM_SCHEMA_EDIT_FAIL,
  error
});

export const formSchemaEdit = (idSubproject, idSite) => {
  return dispatch => {
    dispatch(fetchFormSchemaEditStart);
    siteService
      .getFormSchemaEdit(idSubproject, idSite)
      .then(response => dispatch(fetchFormSchemaEditSuccess(response)))
      .catch(error => dispatch(fetchFormSchemaEditFaill(error)));
  };
};

export const resetFormSchemaEdit = () => ({
  type: actionTypes.RESET_FORM_SCHEMA_EDIT
});

const fetchActivitiesDocumentsStart = () => ({
  type: actionTypes.FETCH_ACTIVITIES_DOCUMENTS_START
});

const fetchActivitiesDocumentsSuccess = data => ({
  type: actionTypes.FETCH_ACTIVITIES_DOCUMENTS_SUCCESS,
  data
});

const fetchActivitiesDocumentsFail = error => ({
  type: actionTypes.FETCH_ACTIVITIES_DOCUMENTS_FAIL,
  error
});

export const fetchActivitiesDocuments = idActivity => {
  return dispatch => {
    dispatch(fetchActivitiesDocumentsStart);
    siteService
      .getActivitiesDocuments(idActivity)
      .then(response => dispatch(fetchActivitiesDocumentsSuccess(response)))
      .catch(error => dispatch(fetchActivitiesDocumentsFail(error)));
  };
};
