import { http } from "./http";
import { downloaderFiles } from "../utility";
const baseUrl = "/rollout/api/shortcutkey";

export const importShortcutKeyConfiguration = async ({ subproject, file }) => {
  const config = {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  };
  const formData = new FormData();
  formData.append("file_upload", file);
  formData.append("subproject", subproject);

  return http
    .post(`${baseUrl}/importer/`, formData, config)
    .then((response) => response.data)
    .catch(({ response }) => Promise.reject(response.data.errors));
};

export const downloadTemplate = async (
  idSubproject,
  subprojectSelectedName
) => {
  return http
    .get(`${baseUrl}/${idSubproject}/download_template/`, {
      responseType: "blob",
    })
    .then((response) => {
      downloaderFiles(
        response.data,
        `Shortcut_Keys_${subprojectSelectedName}_Configuration.xlsx`
      );
      return response;
    })
    .catch((error) => error);
};

const service = {
  importShortcutKeyConfiguration,
  downloadTemplate,
};
export default service;
