/* eslint-disable jsx-a11y/anchor-is-valid */
export function BackToTop() {
  return (
    <a
      href='#'
      className='back-to-top d-flex align-items-center justify-content-center'
    >
      <i className='bi bi-arrow-up-short'></i>
    </a>
  )
}
