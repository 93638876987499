import { downloaderFiles } from "../utility";
import { http } from "./http";

const baseUrlAPI = "/rollisql/api";

export const downloadTemplateValuesAssigmentImporter = async ({
  subprojectId,
  subprojectName,
  populationQuery,
}) => {
  http
    .get(
      `${baseUrlAPI}/values_assigment/${subprojectId}/download_template_importer/`,
      {
        responseType: "blob",
        params: { population_query: populationQuery },
      }
    )
    .then(({ data }) => {
      const filename = `${subprojectName}_value_assignment_template.xlsx`;
      downloaderFiles(data, filename);
    });
};
export const getPopulationsQuery = async (subprojectId) => {
  return http
    .get(`${baseUrlAPI}/population_query/`, {
      params: { subproject: subprojectId },
    })
    .then(({ data }) => data);
};
export const valueAssignmentImporter = async (data) => {
  return http
    .post(`${baseUrlAPI}/values_assigment/importer/`, data)
    .then(({ data }) => data);
};
export const getValueAssignments = async (queryParams) => {
  return http
    .get(`${baseUrlAPI}/values_assigment/`, {
      params: queryParams,
    })
    .then(({ data }) => data);
};
export const getValueAssignmentDetail = async (valueAssignmentId) => {
  return http
    .get(`${baseUrlAPI}/values_assigment/${valueAssignmentId}/`)
    .then(({ data }) => data);
};
export const createCriteriaPopulationQuery = async (values) => {
  return http
    .post(`${baseUrlAPI}/criteria_population/`, values)
    .then(({ data }) => data);
};
export const getCriteriaPopulations = async (queryParams) => {
  return http
    .get(`${baseUrlAPI}/criteria_population/`, {
      params: queryParams,
    })
    .then(({ data }) => data);
};
export const getCriteriaPopulationDetail = async (criteriaPopulationId) => {
  return http
    .get(`${baseUrlAPI}/criteria_population/${criteriaPopulationId}/`)
    .then(({ data }) => data);
};
export const updateCriteriaPopulation = async (
  criteriaPopulationId,
  values
) => {
  const url = `${baseUrlAPI}/criteria_population/${criteriaPopulationId}/`;
  return http.put(url, values).then(({ data }) => data);
};
export const createRelationshipRule = async (values) => {
  return http
    .post(`${baseUrlAPI}/relationship_subproject/`, values)
    .then(({ data }) => data);
};
export const getRelationshipRules = async (queryParams) => {
  return http
    .get(`${baseUrlAPI}/relationship_subproject/`, {
      params: queryParams,
    })
    .then(({ data }) => data);
};
export const getRelationshipDetail = async (relationshipId) => {
  return http
    .get(`${baseUrlAPI}/relationship_subproject/${relationshipId}`)
    .then(({ data }) => data);
};
export const getRelationshipRuleHintsBySubproject = async (subprojectId) => {
  return http
    .get(
      `${baseUrlAPI}/relationship_subproject/${subprojectId}/hints_subproject/`
    )
    .then(({ data }) => data);
};
export const updateRelationshipRule = async (relationshipId, data) => {
  return http
    .put(`${baseUrlAPI}/relationship_subproject/${relationshipId}/`, data)
    .then(({ data }) => data);
};
export const getFieldsSubproject = async (subproject) => {
  const uri = `/rollout/api/subproject/${subproject}/fields_subproject/`;
  return http.get(uri).then(({ data }) => data);
};
export const getByPopulation = async (subproject) => {
  return http
    .get(
      `${baseUrlAPI}/relationship_subproject/${subproject}/get_by_population/`
    )
    .then(({ data }) => data);
};
export const createValueAssignmentColumn = async (data) => {
  return http
    .post(`${baseUrlAPI}/values_assigment/`, data)
    .then(({ data }) => data);
};
export const updateValuesAssignment = async (valueAssignmentId, data) => {
  return http
    .put(`${baseUrlAPI}/values_assigment/${valueAssignmentId}/`, data)
    .then(({ data }) => data);
};

const service = {
  downloadTemplateValuesAssigmentImporter,
  getPopulationsQuery,
  valueAssignmentImporter,
  getValueAssignments,
  getValueAssignmentDetail,
  createCriteriaPopulationQuery,
  getCriteriaPopulations,
  getCriteriaPopulationDetail,
  updateCriteriaPopulation,
  createRelationshipRule,
  getRelationshipRules,
  getRelationshipDetail,
  getRelationshipRuleHintsBySubproject,
  updateRelationshipRule,
  getFieldsSubproject,
  getByPopulation,
  createValueAssignmentColumn,
  updateValuesAssignment,
};

export default service;
