import {
  fetchSubprojects,
  changeSubprojectSelected,
  fetchActivities,
  fetchActivitiesDetail,
  setFilteredYear,
  fetchProcessWeek,
  fetchSubProcessWeek,
  fetchConfigurationProcessWeek,
  fetchFiltersDashboard,
  setFiltersDashboard,
  fetchSchemaProjectSite,
  fetchDataProjectSite,
  fetchSubProcessFilter,
  setSubProcessFiltersDashboardDetail
} from './actions';
const actionsToExport = {
  changeSubprojectSelected,
  fetchSubprojects,
  fetchActivities,
  fetchActivitiesDetail,
  setFilteredYear,
  fetchProcessWeek,
  fetchSubProcessWeek,
  fetchConfigurationProcessWeek,
  fetchFiltersDashboard,
  setFiltersDashboard,
  fetchSchemaProjectSite,
  fetchDataProjectSite,
  fetchSubProcessFilter,
  setSubProcessFiltersDashboardDetail
};
export default actionsToExport;
