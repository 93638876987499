import { http } from "./http";

const baseUrlAPI = "/rollout/api/activity_responsible";
export const getFormMetaData = async (subprojectId) => {
  return http
    .get(`${baseUrlAPI}/${subprojectId}/form_metadata`, {
      params: { subproject: subprojectId },
    })
    .then(({ data }) => data);
};
export const createUserResponsible = async (values) => {
  return http.post(`${baseUrlAPI}/`, values).then(({ data }) => data);
};
export const getUserResponsibles = async (subprojectId, queryParams = {}) => {
  return http
    .get(`${baseUrlAPI}/`, {
      params: { ...queryParams, subproject: subprojectId },
    })
    .then(({ data }) => data);
};
export const getDetailUserResponsible = async (userResponsibleId) => {
  return http.get(`${baseUrlAPI}/${userResponsibleId}/`).then(({ data }) => data);
};
export const updateUserResponsible = async (userResponsibleId, values) => {
  return http.put(`${baseUrlAPI}/${userResponsibleId}/`, values).then(({ data }) => data);
};
export const deleteUserResponsible = async (userResponsibleId) => {
  return http.delete(`${baseUrlAPI}/${userResponsibleId}/`).then(({ data }) => data);
};

const service = {
  getFormMetaData,
  createUserResponsible,
  getUserResponsibles,
  getDetailUserResponsible,
  updateUserResponsible,
  deleteUserResponsible,
};

export default service;
