import axios from 'axios';
import swal from 'sweetalert';
import services from '../services/auth.service';

const BASE_URL = process.env.REACT_APP_API_ENDPOINT_URL;

export const authttp = axios.create({
  baseURL: BASE_URL,
});

authttp.interceptors.request.use((config) => {
  if (config.url !== '/api/login_token/') {
    const token = localStorage.getItem('_tk_');
    if (token) {
      config.data = { ...config.data, token };
    }
  }
  return config;
});

export const http = axios.create({
  baseURL: BASE_URL,
});

http.interceptors.request.use((config) => {
  const token = localStorage.getItem('_tk_');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

function hasErrorResponseTypeBlob(error) {
  return (
    error.request.responseType === 'blob' &&
    error.response.data instanceof Blob &&
    error.response.data.type &&
    error.response.data.type.toLowerCase().indexOf('json') !== -1
  );
}

http.interceptors.response.use(
  (response) => response,
  (err) => {
    if (hasErrorResponseTypeBlob(err)) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          err.response.data = JSON.parse(reader.result);
          resolve(Promise.reject(err));
        };

        reader.onerror = () => {
          reject(err);
        };

        reader.readAsText(err.response.data);
      });
    }

    if (err.response) {
      switch (err.response.status) {
        case 401: {
          return on401(err);
        }
        case 403: {
          const { detail } = err.response.data;
          if (detail) {
            swal({
              title: 'Warning',
              text: detail,
              icon: 'warning',
              button: false,
            });
          }
          break;
        }
        default: {
          throw err;
        }
      }
    } else {
      swal({
        title: 'Warning',
        text: 'There were problems with the connection.',
        icon: 'warning',
        button: false,
      });
      // store.dispatch(auth.actions.logOut());
    }
  },
);

const on401 = (err) => {
  return services
    .tokenRefresh()
    .then((response) => {
      const { token, refresh_token } = response.data;
      localStorage.setItem('_tk_', token);
      localStorage.setItem('_rf_', refresh_token);
      err.response.config.headers.Authorization = `Bearer ${token}`;
      return http.request(err.response.config);
    })
    .catch(() => {});
};

export const zero = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

zero.interceptors.request.use((config) => {
  if (config.url !== '/api/login_token/') {
    const token = localStorage.getItem('_tk_');
    if (token) {
      config.data = { ...config.data, token };
    }
  }
  return config;
});
