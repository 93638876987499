import { lazy } from "react";
import Wrapper from "../hoc/wrapper";
import ZinaWrapper from "../hoc/zina_fragment";
import ExtraMenu from "./extra_menu/extra_menu";
import CustomBar from "./graphics/custom_bar/custom_bar";
import CustomLine from "./graphics/custom_line/custom_line";
import KPICard from "./kpis/card_kpi/card_kpi";
import KPIList from "./kpis/kpi_list";
// end project list
//Loading
import BigLoading from "./loading/bigLoading";
import SmallLoading from "./loading/smallLoading";
import PowerBI from "./power_bi/power_bi";
import ProjectGraphics from "./project_list/project_graphic/project_graphic";
import UpdateDateRows from "./project_list/update_date_rows/update_date_row";

// gap analysis
const ProjectScope = lazy(() =>
  import("./gap_analysis/project_scope/project_scope")
);
const GapAnalysisCard = lazy(() =>
  import("./gap_analysis/gap_analysis_card/gap_analysis_card")
);
const ListGapAnalysis = lazy(() =>
  import("./gap_analysis/list_gap_analysis/list_gap_analysis")
);
//user_management
const UserList = lazy(() => import("./userMangement/user_list/userList"));
const UserHeader = lazy(() => import("./userMangement/user_list/userHeader"));
const UserForm = lazy(() => import("./userMangement/user_list/userForm"));
const ModalRight = lazy(() => import("./userMangement/user_list/modalRight"));
const Projects = lazy(() => import("./userMangement/edit_user/projects"));
const Profile = lazy(() => import("./userMangement/edit_user/profile"));
const FiltersProfile = lazy(() => import("./userMangement/edit_user/filters"));
const FilterSubproject = lazy(() =>
  import("./userMangement/edit_user/filterSubproject")
);
const SelectFields = lazy(() =>
  import("./userMangement/edit_user/selectFields")
);
const FieldTable = lazy(() => import("./userMangement/edit_user/fieldsTable"));
const ImportManagement = lazy(() =>
  import("./userMangement/import/importManagement")
);
const FiltersHeader = lazy(() =>
  import("./userMangement/reports/filtersReport")
);
const ReportList = lazy(() => import("./userMangement/reports/reportList"));
const FormUserResponsible = lazy(() =>
  import("./user_responsibles/form_user_responsible")
);
//Role
const FormCreateRole = lazy(() => import("./role/roleList/form_create_role"));
const RoleForm = lazy(() => import("./role/roleList/role_form"));
const RoleList = lazy(() => import("./role/roleList/roleList"));
const Template = lazy(() => import("./role/importer/template"));
const ImportRole = lazy(() => import("./role/importer/importerRole"));
const FilterSubprojectRole = lazy(() =>
  import("./role/filerSubproject/filterSubproject")
);
const UserRole = lazy(() => import("./role/edit/users"));
const RoleFilter = lazy(() => import("./role/edit/filters"));
const FieldRoles = lazy(() => import("./role/edit/fields/fields_roles"));
const ApprovalUsers = lazy(() => import("./role/edit/approvalUser"));
const ApprovalUserProject = lazy(() =>
  import("./userMangement/edit_user/approvalUserProject")
);
const RolPermission = lazy(()=> import("./role/edit/rolPermission"));

const ImporterCriteriaGroupForm = lazy(() =>
  import("./importer/importer_criteria_group")
);
const ImporterTemplateSyncSMRForm = lazy(() =>
  import("./importer/importer_template_sync_SMR")
);
const CustomReportForm = lazy(() => import("./importer/custom_report_form"));
const DownloadTemplate = lazy(() =>
  import("./importer/download_template/download_template")
);
const DownloadTemplateSelect = lazy(() =>
  import("./importer/download_template/download_template_Select")
);
const DownloadTemplateCustomReport = lazy(() =>
  import("./importer/download_template/download_template_custom_report")
);

// Integration
const IntegrationImporter = lazy(() =>
  import("./integrations/integrationImporter")
);
const CollapseDispatch = lazy(() =>
  import("./integrations/dispatchLog/collapseDispatch")
);
const TableDispatch = lazy(() =>
  import("./integrations/dispatchLog/tableDispatch")
);
const HeaderDispatch = lazy(() =>
  import("./integrations/dispatchLog/headerDispatch")
);
const ImporterSyncClaro = lazy(() =>
  import("./integrations/syncClaro/importerSyncClaro")
);
const ZyncIntegration = lazy(() =>
  import("./integrations/syncZina/zyncIntegration")
);

// Report
const LogsReport = lazy(() => import("./report/logs_report/logs_report"));
const LogsHeader = lazy(() => import("./report/logs_report/logs_header"));
const TableReport = lazy(() => import("./report/list_report/table_report"));
const ListReportHeader = lazy(() =>
  import("./report/list_report/list_report_header")
);
const ModalReport = lazy(() => import("./report/list_report/modal_report"));
const WebtoolTable = lazy(() => import("./report/webtool_synch/webtool_table"));
const WebtoolHeader = lazy(() =>
  import("./report/webtool_synch/webtool_header")
);

//Logical filter
const TableLogical = lazy(() => import("./logical_filter/table_logical"));
const LogicalHeader = lazy(() => import("./logical_filter/logical_header"));
const LogicalModal = lazy(() => import("./logical_filter/logical_modal"));

// Notifications
const ContentNotifications = lazy(() => import("./notification/notification"));
const NotificationMenuItem = lazy(() =>
  import("./notification/notification_menu_item")
);
// Profile
const ProfileForm = lazy(() => import("./profile/profile_form/profile_form"));
// Configuration Rules
const RelationShipRules = lazy(() =>
  import("./configuration_rules/relationship_sheet_rules")
);
const ValuesAssignmentsColumns = lazy(() =>
  import("./configuration_rules/values_assignments_columns")
);
const CriteriaPopulationQuery = lazy(() =>
  import("./configuration_rules/criteria_population_query")
);
const ValueAssignmentImporter = lazy(() =>
  import("./configuration_rules/value_assignment_importer")
);
//Project Configuration
const ProjectConfiguration = lazy(() =>
  import("./project_configuration/project/project_configuration")
);
const SiteConfiguration = lazy(() =>
  import("./project_configuration/site_configuration/site_configuration")
);
const SiteDescription = lazy(() =>
  import("./project_configuration/site_configuration/site_description")
);
const WorkConfiguration = lazy(() =>
  import("./project_configuration/site_configuration/work_configuration")
);
const ColorSetting = lazy(() =>
  import("./project_configuration/groups_color_setting/colorSetting")
);
//UI
const BaseModalHeader = lazy(() =>
  import("./UI/base_modal_header/base_modal_header")
);
const CustomCollapse = lazy(() => import("./UI/custom_collapse/custom_collapse"))

const SelectMultiple = lazy(() =>
  import("./UI/custom_select_multiple/select_multiple")
);
const CustomSelectMultiple = lazy(() =>
  import("./UI/custom_select_multiple/custom_select_multiple")
);
const ButtonClose = lazy(() => import("./UI/close_modal/close_modal"));
const SelectedFilterOption = lazy(() =>
  import("./UI/button_remove_filter/button_remove_filter")
);
const DatePickerSupportCustomValues = lazy(() =>
  import("./UI/datepicker_support_custom_date/datepicker_support_custom_date")
);
const InputTypeSwitch = lazy(() =>
  import("./UI/input_type_switch/input_type_switch")
);
const ExporterForm = lazy(() =>
  import("./exporter/exporter_form/exporter_form")
);
const ModalApplyFilterExport = lazy(() =>
  import("./exporter/filters/modal_apply_filter/modal_apply_filter")
);
const ModalSchedule = lazy(() =>
import("./exporter/schedule/modalSchedule")
);
const DynamicTable = lazy(() => import("./UI/dynamic_table/dynamic_table"));
const InputColor = lazy(() => import("./UI/input_color/input_color"));

// Landing page
const LandingHeader = lazy(() => import("./landing/header"));
const LandingFooter = lazy(() => import("./landing/footer"));
const LandingContent = lazy(() => import("./landing/landing-content"));

const YouTubeFrame = lazy(() => import("./UI/you_tube/you_tube"));

// Tour
const Tour = lazy(() => import("./tour/base/base"));

//kpi
const KpiImporter = lazy(() => import("./kpis/kpi_importer"));

//error card
const ErrorCard = lazy(() => import("./client_error/errorCard"));

// project list
const MeliusTableGrid = lazy(() =>
  import("./project_list/melius-table-grid/TableDataGrid")
);
const FiltersTable = lazy(() =>
  import("./project_list/melius-table-grid/filters_table/filters_table")
);
const MeliusGrid = lazy(() => import("./project_list/zina_grid/melius_grid"));
const ZinaGridBase = lazy(() => import("./project_list/zina_grid/zina-grid"));
const Paginator = lazy(() => import("./project_list/zina_grid/paginator"));
const InputFormGrouperActivity = lazy(() =>
  import(
    "./project_list/sites/input_form_grouper_activity/input_form_grouper_activity"
  )
);
const GrouperActivities = lazy(() =>
  import("./project_list/sites/grouper_activities/grouper_activities")
);
const ActivityDescriptors = lazy(() =>
  import("./project_list/sites/activity_descriptors/activity_descriptors")
);
const ItemActivityDescriptor = lazy(() =>
  import(
    "./project_list/sites/activity_descriptors/item_activity_descriptor/item_activity_descriptor"
  )
);
const ImporterForm = lazy(() =>
  import("./importer/importer_form/importer_form")
);
const EmailImporterForm = lazy(() =>
  import("./importer/email_importer_by_status")
);
const PeriodImporterForm = lazy(() =>
  import("./importer/importer_period_form")
);

const ModalNotificationSite = lazy(() =>
  import("./project_list/sites/modal_notification_site/modal_notification_site")
);
const ModalAdvancedSearch = lazy(() =>
  import("./project_list/modal_advanced_search/modal_advanced_search")
);
const MenuTable = lazy(() => import("./project_list/menu_table/menu_table"));
const ModalDocumentActivity = lazy(() =>
  import("./project_list/modal_document_activity/modal_document_activity")
);
const DisplayTotalRowsZinaGrid = lazy(() =>
  import("./project_list/zina_grid/display_total_rows_zinagrid")
);
const QueryBuilder = lazy(() =>
  import("./project_list/query_builder/logic_filter")
);

const CardActivities = lazy(() => import("./card_activities/card_activities"));
const CardActivity = lazy(() =>
  import("./card_activities/card_activity/card_activity")
);
const ModalFilterDetail = lazy(() =>
  import("./dashboard_show_filters/modal_filter/modal_filterDetail")
);
const CustomAlert = lazy(() => import("./custom_alert/modal"));
const DashboardShowFilters = lazy(() =>
  import("./dashboard_show_filters/dashboard_show_filters")
);
const DateRangeComponent = lazy(() =>
  import("./dashboard_show_filters/data_range_filter/data_range_filter")
);

// assign team leader
const ListTeamLeader = lazy(() =>
  import("./assign_team_leader/list_assignTeam.js")
);
const AssignHeader = lazy(() =>
  import("./assign_team_leader/assignTeam_header")
);
const AssignModal = lazy(() => import("./assign_team_leader/assignModal"));

// List Projects
const ListTableRegistered = lazy(() =>
  import("./registered_project/listTableRegistered")
);
const HeaderRegistered = lazy(() =>
  import("./registered_project/headerRegistered")
);
const ModalRegistered = lazy(() =>
  import("./registered_project/modalRegistered")
);

//Activity report

const ActivityTable = lazy(()=> import("./report/activity_report/activityTable"));
const ActivityImport = lazy(()=> import("./report/activity_report/activityImport"));
const ActivityModal = lazy(()=> import("./report/activity_report/activityModal"));
const ActivityLinkTemplate = lazy(()=> import("./report/activity_report/activityLinkTemplates"));
const ActivityDocumentTemplate = lazy(()=> import("./report/activity_report/activitydocumentTemplate.js"));
//TMO
const CollapseJk = lazy(() => import("./tmo/collapse/collapse"));
const TableTmo = lazy(() => import("./tmo/table/tableTmo"));
const CardTmo = lazy(() => import("./tmo/card/cardTmo"));
const TinyCardTMO = lazy(()=> import("./tmo/tinyCard/tinyCard"));

// StepperComponent
const StepperComponent = lazy(() => import("./stepper/stepperComponent"));

// dataset
const DatasetCreation = lazy(() => import("./dataset/datasetCreation"));
const DatasetSetup = lazy (() => import("./dataset/datasetSetUp"));
const DatasetRolesSetup = lazy (() => import("./dataset/datasetRoleSetup"));
const DatasetReady = lazy(() => import("./dataset/datasetReady"));

export {
  Tour,
  //loading
  BigLoading,
  SmallLoading,
  //landing
  LandingHeader,
  LandingFooter,
  LandingContent,
  YouTubeFrame,
  // other components
  ExtraMenu,
  BaseModalHeader,
  ButtonClose,
  SelectedFilterOption,
  CardActivity,
  CardActivities,
  CustomBar,
  CustomLine,
  ProjectScope,
  ListGapAnalysis,
  KPICard,
  KPIList,
  GapAnalysisCard,
  CustomAlert,
  ProjectConfiguration,
  InputColor,
  DynamicTable,
  SiteConfiguration,
  SiteDescription,
  ColorSetting,
  WorkConfiguration,
  MeliusTableGrid,
  MeliusGrid,
  ZinaGridBase,
  DisplayTotalRowsZinaGrid,
  FiltersTable,
  Paginator,
  DashboardShowFilters,
  Wrapper,
  ZinaWrapper,
  ImporterForm,
  EmailImporterForm,
  DownloadTemplate,
  DownloadTemplateSelect,
  DownloadTemplateCustomReport,
  PeriodImporterForm,
  InputFormGrouperActivity,
  GrouperActivities,
  ModalNotificationSite,
  ExporterForm,
  ActivityDescriptors,
  ModalApplyFilterExport,
  ModalSchedule,
  MenuTable,
  SelectMultiple,
  CustomSelectMultiple,
  CustomCollapse,
  ModalAdvancedSearch,
  InputTypeSwitch,
  ModalFilterDetail,
  ContentNotifications,
  NotificationMenuItem,
  ProfileForm,
  UserList,
  UserHeader,
  UserForm,
  ModalRight,
  Projects,
  Profile,
  FiltersProfile,
  FilterSubproject,
  SelectFields,
  FieldTable,
  ImportManagement,
  FiltersHeader,
  ReportList,
  DateRangeComponent,
  ModalDocumentActivity,
  DatePickerSupportCustomValues,
  ItemActivityDescriptor,
  FormCreateRole,
  RoleForm,
  RoleList,
  RoleFilter,
  UserRole,
  ApprovalUsers,
  RolPermission,
  Template,
  ImportRole,
  FilterSubprojectRole,
  RelationShipRules,
  CriteriaPopulationQuery,
  ValuesAssignmentsColumns,
  ValueAssignmentImporter,
  PowerBI,
  FormUserResponsible,
  QueryBuilder,
  ImporterCriteriaGroupForm,
  ImporterTemplateSyncSMRForm,
  IntegrationImporter,
  CustomReportForm,
  LogsHeader,
  LogsReport,
  TableReport,
  ListReportHeader,
  ModalReport,
  WebtoolTable,
  WebtoolHeader,
  ProjectGraphics,
  FieldRoles,
  CollapseDispatch,
  TableDispatch,
  HeaderDispatch,
  TableLogical,
  LogicalHeader,
  LogicalModal,
  ZyncIntegration,
  ImporterSyncClaro,
  KpiImporter,
  ErrorCard,
  ListTeamLeader,
  AssignHeader,
  AssignModal,
  ListTableRegistered,
  HeaderRegistered,
  ModalRegistered,
  ApprovalUserProject,
  CollapseJk,
  TableTmo,
  CardTmo,
  TinyCardTMO,
  UpdateDateRows,
  ActivityTable,
  ActivityImport,
  ActivityModal,
  ActivityLinkTemplate,
  ActivityDocumentTemplate,
  StepperComponent,
  DatasetCreation,
  DatasetSetup,
  DatasetRolesSetup,
  DatasetReady,
};
