import React from "react";
import { Route, Redirect } from "react-router-dom";

const getUser = () => localStorage.getItem("user");

const isRolloutProject = (isRollout, isDashboardActive) => {
  const {
    location: { pathname },
  } = window;
  const isLocationDashboard = pathname.match(/dashboard/) || pathname === "/";
  return isLocationDashboard && isRollout && !isDashboardActive;
};

export function PrivateRoute(props) {
  const {
    component: Component,
    extraProps,
    condition,
    pathname,
    keyPermissions = "",
    ...routerProps
  } = props;
  const { isRolloutProject: isRollout, hasPermissionDashboardView } =
    extraProps;

  const render = (props) => {
    const op = Object.keys(extraProps)
      .map((key) => {
        if (!Object.keys(props).includes(key)) {
          return { [key]: extraProps[key] };
        }
        return null;
      })
      .filter((obj) => obj)
      .reduce((obj, item) => ({ ...obj, ...item }), {});

    const newProps = {
      ...props,
      ...{ extraProps: op },
    };

    if (getUser()) {
      if (!extraProps.userHasProjectAssigned) {
        const {
          location: { pathname },
        } = window;

        if (pathname !== "/") {
          return <Redirect to="/" />;
        }
        //return <Redirect to="/not-found/user-without-assigned-project/" />;
      }

      if (isRolloutProject(isRollout, hasPermissionDashboardView)) {
        return <Redirect to="/rollout/project-list/" />;
      }
      const userData = extraProps && extraProps.user;
      let permissionURL = "";
      const superUser =
        extraProps && extraProps.user && extraProps.user.is_superuser;
      permissionURL =
        userData &&
        userData.groups.filter((v) => v.name === "ADMIN" || v.name === "ADMON");

      if (keyPermissions === "admin_views") {
        if (permissionURL && permissionURL.length > 0 && superUser) {
          return <Component {...newProps} />;
        } else {
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }
      }

      return <Component {...newProps} />;
    } else {
      return (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      );
    }
  };

  return <Route {...routerProps} render={render} />;
}
